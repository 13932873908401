import React, { Component } from 'react'
import style from './WhoWeAre.module.scss';
import {NavLink} from 'react-router-dom';
import {FaMusic,FaPeopleArrows} from 'react-icons/fa';
import { GiClothes} from 'react-icons/gi';
import { AiFillLike} from 'react-icons/ai';
import { IoIosPeople} from 'react-icons/io';
import { BsPersonFillCheck} from 'react-icons/bs';
import thePic from '../../../src/images/photo1.jpg'
import { MdWork,MdSportsSoccer,MdDraw,MdClose,MdEqualizer,MdOutlineGppGood} from 'react-icons/md';
class WhoWeAre extends Component {
    state={
        details:[
               {id:1,icon: <IoIosPeople size={70} color='#8d2855'/>, title:'FAMILY', to:"/volunteer", image:'https://images.unsplash.com/photo-1547496613-4e19af6736dc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',subTitle:'The family performs several important functions for any community, it provides emotional and practical support for its members. NU Africa Pamoja Foundation upholds family values to all our members. We are a big family with respect to each one of us.'},
               {id:2,icon: <AiFillLike size={70} color='#8d2855'/>, title:'DOPENESS',to:"/volunteer", image:'https://images.pexels.com/photos/6647020/pexels-photo-6647020.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',subTitle:'We believe is the best version of oneself, greatness, eminence and hard work are vital in NU Africa Pamoja. We bring the best out of everyone at no expense of anyone else. We purpose for the best standard in all of our activities.'},
               {id:3,icon: <MdOutlineGppGood size={70} color='#8d2855'/>, title:'INTEGRITY',to:"/volunteer", image:'https://images.pexels.com/photos/5647560/pexels-photo-5647560.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',subTitle:'Our lives are centered around our deepest values. They determine how we conduct ourselves and engage with others. We are deliberate and passionate in upholding the highest standards of integrity in our engagements. We advocate for compliance with rules, regulations and principles of fairness.'},
               {id:4,icon: <BsPersonFillCheck size={70} color='#8d2855'/>, title:'CHARACTER',to:"/volunteer", image:'https://images.unsplash.com/photo-1571417814270-5cfb10916e02?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',subTitle:'Talent is a gift but character is a choice. Individual can raise above the limits of their character by cultivating good character with a moral compass. NU Africa believes in having the courage to build character, compassion, loyalty, honesty, integrity, selflessness and sacrifice in a world that is devoid of character.'},
               {id:4,icon: <MdEqualizer size={70} color='#8d2855'/>, title:'EQUALITY',to:"/volunteer", image:'https://images.unsplash.com/photo-1571417814270-5cfb10916e02?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',subTitle:'We believe no one should be excluded from the opportunity to develop into the best version of themselves, and to engage constructively in the within their communities. This is a tenet of the inherent dignity of all people. The youth today face the defining challenge of inequality which limits their potential. We address inequality by advocating for fairness, tolerance and inclusion of all persons regardless of the differences that exist amongst us.'},
        ]
   }
   componentDidMount() {
    window.scrollTo(0, 0)
  }
    render() {
        const detailsList=this.state.details.map(item=>{
            return(
                <div className={style.causedivzoom} key={item.id}>
                <div className={style.ICDiv}>{item.icon}</div>
                <h1>{item.title}</h1>
                <p>{item.subTitle}</p>
                </div>
            )
        })
        return (
            <div  className={style.container}>
            <div className={style.statusBar}></div>
            <div className={style.imgDiv}>
            <img src='./photo25.jpg'/>
            <h1>Who We Are</h1>
            </div>
            <div  className={style.container2}>
            <div className={style.aboutDiv1}>

<div className={style.aboutDiv2B}>
</div>
                <div className={style.aboutDiv3}>
              <p className={style.aboutP1}>Welcome To NUAfrica Pamoja Foundation</p>
            <p className={style.aboutP3}>NUAfrica Pamoja Foundation is an organization empowering Africa through Arts, performing Arts, fashion, sports, music, film, photography, governance and leadership. We aim to transform Communities by inspiring and empowering them to be the best versions of themselves, through enhancing and showcasing their talents by providing platforms through which they can engage with each other.
</p>
<p className={style.T1}>Our Vision</p>
<p className={style.aboutP4}>To engage and bring together the youth to effect change in the society through promotion of art(s), sports, fashion, music, film, photography, governance and leadership</p>
<p className={style.T1}>Our Mission</p>
<p className={style.aboutP4}>To transform communities by inspiring and facilitating the youth in Africa to achieve their fullest potential and contribute to peace, unity, growth and development.</p>
            
</div>
                </div>
                <div className={style.headDiv}><p className={style.ourTit}>OUR PRINCIPLES</p></div>
            <div className={style.causesItemsCont}>
                 {detailsList}
                </div>
                </div>
            </div>
        )
    }
}

export default WhoWeAre
