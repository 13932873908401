import React, { Component } from 'react'
import style from './Volunteer.module.scss';
import PartnerWelcome from './PartnerWelcome';//FundWelcome
import FundWelcome from './FundWelcome';
import DoneNow from './DoneNow';
import VolunteerReel from './VolunteerReel';
import VolunteerWelcome from './VolunteerWelcome';
import HomeDonors from './HomeDonors'
 class Volunteer extends Component {
   state={
     openModal:false
   }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
    render() {
        return (
            <div className={style.container}>
             <div className={style.statusBar}></div>
                <div className={style.imgDiv}>
                <img src={'./photo26.jpeg'}/>
                <h1>Donate Now</h1>
                </div>
                <div className={style.causesDetCont}>
                    <h4>Be part of the solution</h4>
                    <p>Together we can transform Communties by inspiring and empowering the youth to be the best versions of themselves. Donate today for education support, social entrepreneurship, sports for development, nurturing Talent and Governance and leadership.</p>
                </div>
              <div className={style.container2}>
              <DoneNow/>
            </div>
                <div className={style.container1}>
              <VolunteerWelcome/>
            </div>
            <div className={style.container2}>
              <FundWelcome/>
            </div>
            <div className={style.container3}>
              <PartnerWelcome/>
            </div>
            <div className={style.container4}>
           
            </div>
           
            </div>
        )
    }
}

export default Volunteer
