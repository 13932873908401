import React, { Component } from 'react'
import stringSimilarity from 'string-similarity'

var items=[ 
/*{value: 'pear'},
{value: 'orange'},
{value: 'grape'},
{value: 'banana'}*/]
const items2 = [
 /* {value: 'apple'},
  {value: 'pear'},
  {value: 'orange'},
  {value: 'grape'},
  {value: 'banana'},*/
  { bracelets:"1" },
   {africanUntensils:"2"},
   {africanWeekender:"7"},
  { africanprintShirts:"12"},
   { africanDresses:"13"},
   { africanHoodies:"14"},
   { africanShoes:"15"},
   { belts:"3"},
    {paintings:"4"},
    {earings:"5"}
]
var anarray2=[]
class Search2 extends Component {
    state={
        typedWord:'',
        theArrrray:[]
    }
    componentDidMount(){
        var similarity = stringSimilarity.compareTwoStrings('healeddddddddddd', 'sealeddddddddd');
        console.log('similarity', similarity)
         /* for (const{key, value} in items) {
            console.log(value); // Will display contents of the object inside the array
        }*/
       /* for (var i = 0; i < items.length; i++) {
            //result[items[i].key] = items[i].value;
            console.log(items[i].value)
          }*/
          this.getArray()
          console.log('component called')
        
    }
    handleChange=(event)=>{
        //var ararara=this.state.theArrrray
        var theName=event.target.value
        this.setState({
            typedWord:event.target.value,
            theArrrray:anarray2.filter(({value}) => value.includes(theName))
         })
        
        /*if (theName==='') {
            this.setState({
                typedWord:event.target.value,
                theArrrray:anarray2
             })
        }else{
            this.setState({
                typedWord:event.target.value,
                theArrrray:anarray2.filter(({value}) => value.includes(theName))
             })
        }*/
    
    }
    getArray=async()=>{
        var anarray=[]
       
      await  items2.forEach((item)=>{
            console.log(item)
            console.log(Object.keys(item));
            console.log(Object.values(item));
            anarray={
                value: Object.keys(item).toString(), id: Object.values(item).toString(),
              }
              anarray2.push(anarray)
          })
          this.setState({
            theArrrray:anarray2
          })
          console.log(items);
    }
    render() {
        console.log('arr', this.state.theArrrray)
         const theItems=this.state.theArrrray.map((item)=>{
             return(
             <p>{item.value}</p>
             )
         })
       { /*let theItems=<Downshift
        onChange={selection =>
          alert(selection ? `You selected ${selection.value}` : 'Selection Cleared')
        }
        itemToString={item => (item ? item.value : '')}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          getRootProps,
        }) => (
          <div>
            <label {...getLabelProps()}>Enter a fruit</label>
            <div
              style={{display: 'inline-block'}}
              {...getRootProps({}, {suppressRefError: true})}
            >
              <input {...getInputProps()} />
            </div>
            <ul {...getMenuProps()}>
              {isOpen
                ? items
                    .filter(item => !inputValue || item.value.includes(inputValue))
                    .map((item, index) => (
                      <li
                        {...getItemProps({
                          key: item.value,
                          index,
                          item,
                          style: {
                            backgroundColor:
                              highlightedIndex === index ? 'lightgray' : 'white',
                            fontWeight: selectedItem === item ? 'bold' : 'normal',
                          },
                        })}
                      >
                        {item.value}
                      </li>
                    ))
                : null}
            </ul>
          </div>
        )}
      </Downshift>*/}
        return (
            <div>
                 <input  type="text" placeholder='Enter Search' onChange={(event)=>this.handleChange(event)} />
                {theItems}
            </div>
        )
    }
}

export default Search2
