import React, { Component } from 'react'
import style from './Causes.module.scss';
import {FaFacebookF,FaTwitter,} from 'react-icons/fa';
import {AiFillInstagram} from 'react-icons/ai';
import Footer from "../Footer/Footer";
import {Link, NavLink} from 'react-router-dom'
import {FaArrowLeft} from 'react-icons/fa';
var myBlog={id:1, title:'BRING MUSIC TO LIFE', time:'12TH AUG 2020', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
image:'https://images.pexels.com/photos/6662/creative-smartphone-notebook-typography.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'}
const blogs=[
    {id:1, title:'BRING MUSIC TO LIFE', time:'12TH AUG 2020', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/6662/creative-smartphone-notebook-typography.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
    {id:2, title:'MUSIC LIFE AND LIFESTYLE', time:'12TH AUG 2020',details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
     image:'https://images.pexels.com/photos/3944104/pexels-photo-3944104.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
    {id:3, title:'THE ART OF MUSIC', time:'12TH AUG 2020',details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/880989/pexels-photo-880989.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
    {id:4, title:'THE MUSIC BUSINESS', time:'12TH AUG 2020',details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/164716/pexels-photo-164716.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
    {id:5, title:'FINANCIAL JOURNEY', time:'12TH AUG 2020',details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/314563/pexels-photo-314563.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
    {id:6, title:'THE MIND OF ENTERPRENUER', time:'12TH AUG 2020',details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/4011762/pexels-photo-4011762.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'}
  
],
details=[
    {id:1, title:'Education Support', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur', 
    image:'https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/causes%2FNuap.jpg?alt=media&token=406e97c4-af06-409e-9931-a7d556af134b'},
    {id:2, title:'Economic Enpowerment', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/1181355/pexels-photo-1181355.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'},
    {id:3, title:'Sports Promotion', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/3886055/pexels-photo-3886055.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'},
    {id:5, title:'Nurturing Talent', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/1432238/pexels-photo-1432238.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'},
    {id:6, title:'Governance and Leadership', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/5083399/pexels-photo-5083399.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'},
    ]
const theCats=[
    {id:1, title:'FINANCIAL DISCIPLINE', time:'12TH AUG 2020', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/6662/creative-smartphone-notebook-typography.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'}
]
const theImage='https://images.pexels.com/photos/6662/creative-smartphone-notebook-typography.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
class Causes extends Component {
    state={
        blog:[]
        /*blog:{
            id:1, title:'BRING MUSIC TO LIFE', time:'12TH AUG 2020', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            image:'https://images.pexels.com/photos/6662/creative-smartphone-notebook-typography.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'  
        }*/
    }
    componentDidMount(){
        window.scrollTo(0, 0)
        this.setState({
            blog:this.props.location.state
        })
       }
    
       changePost=(item)=>{
        this.setState({
            blog:item
        })
       }
    render() {
        const singleBlog=this.state.blog
        const blogsList=
                <div className={style.listCont}> 
                <div className={style.imgCont} style={{backgroundImage:"url(" + singleBlog.image + ")"}}>
                 
                </div>
            <h1>{singleBlog.title}</h1>
                <p>{singleBlog.details}</p>
               <div className={style.contBtm}>
                   <h3>{singleBlog.time}</h3>
                   <div className={style.contShare}>
                       <h4>SHARE:</h4>
                       <FaFacebookF className={style.iconFa}/>
                       <FaTwitter className={style.iconTw}/>
                   </div>
               </div>
               </div>
            
           
        const newPostsList=details.slice(0,4).map(item=>{
            return(
                <div className={style.newPostCont} onClick={()=>this.changePost(item)}> 
                {/*<div className={style.imgCont2} style={{backgroundImage:"url(" + theImage + ")"}}>*/}
                <div className={style.imgCont2} style={{backgroundImage:"url(" + item.image + ")"}}>
                </div>
                <div className={style.newPostCont2}>
                    <h2>{item.title}</h2>
                    <p>SEE MORE...</p>
                </div>
            </div>
            )
        })
        const categories=theCats.slice(0,4).map(item=>{
            return(
                <NavLink to={{pathname:'/blogscat',state:{'from':'category', 'info':item.title}}} style={{textDecoration:'none', color:'#000'}}>
                <div className={style.newPostContCat}> 
               <h2 >{item.title}</h2>
            </div>
            </NavLink>
            )
        })
        return (
           
            <div className={style.container}>
              <div className={style.contNav}> 

              </div>
              <div>
             { /*<h1 className={style.contTitle}>BLOGS</h1>*/}
              <div className={style.contHeader}>
                  <FaArrowLeft className={style.backIcon} onClick={()=>this.goBack()}/>
                  <h2 >CAUSES</h2>
              </div> 
              <div className={style.contBody}> 
                
              <div className={style.contLeft}> 
                {blogsList}
               </div>
               <div className={style.contRight}> 
               <div  className={style.contRight1}>
               <h1>OTHER CAUSES</h1>
                {newPostsList}
             </div>
             {/*<div className={style.contRight2}> 
               <h1>CATEGORY</h1>
                {categories}
        </div>*/}
             <div className={style.contRight3}> 
               <h1>FOLLOW US</h1>
                <div>
                <FaFacebookF className={style.followIcons}/>
                <FaTwitter className={style.followIcons}/>
                <AiFillInstagram className={style.followIcons}/>
                </div>
             </div>
               </div>
               </div>
            
            
            </div>
            </div>
        )
    }
}

export default Causes
