import React, { Component } from 'react'
import style from './OrderOnTransit.module.scss';
var image="https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/HomeAds%2FAccessories%2Fbrace.jpg?alt=media&token=52346652-50b2-43c4-8923-7dc98e538ef0"

class OrderOnTransit extends Component {
    state={
        theItems:[
           {id:1, title:'African Bracelete ', price:500, quantity:1, total:500},
           {id:2, title:'Bearded Earings', price:1500,quantity:1, total:1500},
           {id:3, title:'African Necklace', price:2000,quantity:1, total:2000},
           {id:4, title:'Kikoy Dress', price:2500,quantity:1, total:2500},
           {id:5, title:'Kitenge Trousers', price:3000,quantity:1, total:3000}
           ]
    }
    render() {
        const itemsList=this.state.theItems.map((item)=>{
            let orderId = 'Order No : '+'1TY254U36FGD'
            let orderId2 = 'Id : '+'1TY254U36FGD'
            return(
                     <div className={style.container2}>
                     <div className={style.itemsList1}>
                     <div className={style.imageDiv}>
                     <img  src={image} alt="NU-Africa"></img>
                    </div>
                    <div className={style.postDetails}>
                    <div className={style.postDetails1}>
                        <p>The product Title</p>
                        <h5>DETAILS</h5>
                    </div>
                      <p className={style.postDetailsP1} id={style.postDetailsP11}>{orderId}</p>
                      <p className={style.postDetailsP1} id={style.postDetailsP12}>{orderId2}</p>
                    <p className={style.postDetailsP2}>KES 1,250</p>
                    <div className={style.statusDiv}>
                    <h5>STATUS: </h5>
                    <p className={style.postDetailsP3}>ON TRANSIT</p>
                    </div>
                    <div className={style.postDetailsP4A}>
                    <p className={style.postDetailsP4B}>Ordered : 24 AUG 2020</p>
                    <p className={style.postDetailsP4C}>Delivery On : 20 SEP 2020</p>
                    </div>
                    
                    </div>
                     </div>
                     <div className={style.contBottom}>
                       <div className={style.contBottom1}>
                       <p className={style.postDetailsP4B}>Ordered : 24 AUG 2020</p>
                       </div>
                       <div className={style.contBottom2}>
                       <p className={style.postDetailsP4C}>Delivery On : 20 SEP 2020</p>
                        </div>
                        <div className={style.contBottom3}>
                        <p className={style.postDetailsP4D}>Details</p>
                        </div>
                     </div>
                     </div>
                     
            )
        })
        return (
            <div className={style.container}>
                <h3 className={style.headingTitle}>Orders On Transit</h3>
                <div className={style.contOT}>
                 <div className={style.contOTA}>
                   {itemsList}
                   </div>   </div> 
            </div>
        )
    }
}

export default OrderOnTransit
