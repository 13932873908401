import React, { Component } from 'react'
import style from './AccountProfile.module.scss';
import {NavLink} from 'react-router-dom'

class AccountProfile extends Component {
    render() {
        return (
            <div className={style.container}>
                <h3 className={style.headingTitle}>Account Overview</h3>
                <div className={style.container2}>
                    <div className={style.container3}>
                     <h2>Account Details</h2>
                    <p className={style.detName}>Username: John Doe</p>
                    <p className={style.detEmail}>Email:    johndoe@gmail.com</p>
                    <p className={style.detLoc}>Location: Thome Opposite Ruaraka Academy Roysambu, Nairobi, Kenya</p>
                    <p className={style.detPhone}>Phone No: 0725321123</p>
                    </div>
                    <div className={style.container4}>
                    <h2>Shipping Details</h2>
                    <p className={style.detName}>Customer: John Doe</p>
                    <p className={style.detEmail}>Email:    johndoe@gmail.com</p>
                    <p className={style.detLoc}>Drop Off: Thome Opposite Ruaraka Academy Roysambu, Nairobi, Kenya</p>
                    <p className={style.detPhone}>Customer No: 0725321123</p>
                    </div>
                </div>
                <NavLink to='/editaccount'  className={style.editAcc}>
                <h4>EDIT ACCOUNT DETAILS</h4>
                </NavLink>
            </div>
        )
    }
}

export default AccountProfile
