import React, { Component } from 'react'
import style from './SupportUs.module.scss';
import {NavLink} from 'react-router-dom'
var image='https://images.pexels.com/photos/259269/pexels-photo-259269.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'

class SupportUs extends Component {
    state={
        details:[
           {id:1, title:'DONATE', to:"/volunteer", subTitle:'Together we can transform Communties by inspiring and empowering the youth to be the best versions of themselves.', image:'https://images.unsplash.com/photo-1547496613-4e19af6736dc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80'},
           {id:2, title:'VOLUNTEER',to:"/volunteer", subTitle:'Volunteers are welcome to register and work with us in any of these departments, depending on their area of expertise.', image:'https://images.pexels.com/photos/6647020/pexels-photo-6647020.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
           {id:3, title:'PARTNER',to:"/volunteer", subTitle:' You can be a partner within our organisation on various programmes and projects.', image:'https://images.pexels.com/photos/5647560/pexels-photo-5647560.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
           {id:4, title:'MEMBERSHIP',to:"/volunteer", subTitle:'NUAfrica Pamoja Foundation will soon have membership opportunities. Follow us on our social media, subscribe to our news latters doe updates.', image:'https://images.unsplash.com/photo-1571417814270-5cfb10916e02?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80'}]
   }
    render() {
        const detailsList=this.state.details.map(item=>{
            return(
                <div className={style.imghoverzoom} key={item.id}>
                <NavLink to={item.to} style={{textDecoration:'none', color:'#000'}}>
                 <img style={{}} src={item.image}/>
                <h1>{item.title}</h1>
               <p className={style.listP1}>{item.subTitle}</p>
               <p className={style.listP2}>Read More</p>
                </NavLink>
                </div>
            )
        })
        return (
            <div className={style.container}>
                 <div className={style.imgDiv}>
                <img src={image}/>
                <h1>LETS UNITE AFRICA</h1>
                </div>
           
            <div className={style.container2}>
            <div className={style.donorItemsCont}>
                 {detailsList}
                </div>
            </div>
            </div>
        )
    }
}

export default SupportUs
