import React, { Component } from 'react'
import style from './CartPay.module.scss'

 class CartPay extends Component {
     state={
        phoneNo:'CALL FOR HELP'
     }
    showPhoneNo=()=>{
        this.setState({
            phoneNo:'PHONE NO  0724800900'
        })  
     }
    render() {
        let subTotal='12,796'
        let theTotal='12,796'
        return (
            <div className={style.container}>
                <h1>NUAP <break style={{color:'#FF7C59'}}>PAY</break> </h1>
                <div className={style.mpesaDiv}>
                        <img  src={'./mpesa.png'} alt="NU-Africa"></img>
                         </div>
                <div className={style.container1}>
                <div className={style.contLeft}>
                <div  className={style.contLeft1}><h5>YOUR ORDER SUMMARY</h5>
                <div className={style.calcDiv1}>
                             <div className={style.calcDiv2}>
                             <p className={style.subTotP}>ORDER TOTAL:</p>
                             <p className={style.discP}>Payment discount:</p>
                             <p className={style.totalP}>TOTAL TO PAY:</p>
                             </div>
                             <div className={style.calcDiv3}>
                            <p className={style.subTotP}>Ksh {subTotal}</p>
                            <p className={style.discP}>Ksh N/A</p>
                            <p className={style.totalP2}>Ksh {theTotal}</p>
                             </div>

                         </div></div>
                </div>
                <div className={style.contRight}>
                    <div className={style.contRight4}>
                    <h5>ENTER YOUR MPESA PHONE NUMBER BELOW</h5>
                    <h6>*Before you proceed, please confirm you have enough money in your M-PESA Account.</h6>
                   
                 
                   <div className={style.contRight5}>
                       <div className={style.phoneDiv}>
                       <h2>+254</h2>
                       <input placeholder='e.g 0711123456'/>
                       </div>
                       <h3>PAY NOW {theTotal} Ksh</h3> </div> </div>
                       <div className={style.contLeft2}>
                   <h2>NEED HELP?</h2>
                  <p>Contact Customer Care for help</p>
                  <div onClick={()=>this.showPhoneNo()}>
                 <h4>{this.state.phoneNo}</h4>
                  </div>
                  
                   </div>
                </div>
                </div>
            </div>
        )
    }
}

export default CartPay
