import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createStore } from 'redux'
import rootReducer from './redux/postReducer'
import {PersistGate} from 'redux-persist/integration/react' 
import {persistStore, persistReducer} from 'redux-persist'
import { Provider } from 'react-redux'
import storage from 'redux-persist/lib/storage'
import { unregister } from './registerServiceWorker';

import * as firebase from 'firebase' 
var config = {
  apiKey: "AIzaSyAlinVv-lnIjdytoS6VzZBSPahnfZV1cG8",
  authDomain: "nuapfoundation.firebaseapp.com",
  databaseURL: "https://nuapfoundation.firebaseio.com",
  projectId: "nuapfoundation",
  storageBucket: "nuapfoundation.appspot.com",
  messagingSenderId: "383398213345",
  appId: "1:383398213345:web:ef9321d31c68ccdb88a9a0",
  measurementId: "G-RP611BGZL0"
};
// Initialize Firebase
firebase.initializeApp(config);
//firebase.analytics();

const persistConfig={
  key:'root',
  storage
}

const persistedReducer=persistReducer(persistConfig, rootReducer)


const store = createStore(persistedReducer);
const persistor=persistStore(store)
const rootElement=document.getElementById('root');

ReactDOM.render(<Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
  <App />
  </PersistGate>
  </Provider>,
  rootElement );


//registerServiceWorker();
unregister()

/*ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();*/
