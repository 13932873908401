import React, { Component } from 'react'
import style from './PartnerWelcome.module.scss';
import { NavLink } from 'react-router-dom'

class PartnerWelcome extends Component {
    state={
        openModal:true
      }
      closeModal=(event)=>{
       
        this.setState({openModal:false})

      }
      openModal=(event)=>{
        this.setState({openModal:true})
      }
      doNothing=(event)=>{
        event.stopPropagation();
      }
    render() {
        return (
            <div className={style.welcomeCont}>
                <div className={style.mainContainer}>
                    <div className={style.leftSideBar}>
                        <img src={'./photo34.jpg'} />
                    </div>
                    <div className={style.rightSideBar}>
                        <div className={style.container}>
                            <h1 >BECOME A MEMBER</h1>
                            <p className={style.enpowerP2}>Membership to NUAfrica Pamoja Foundation will soon open. This is an opportunity for you to benefit from and have access to our resources, training and development programmes, facilities, expertise and networks. If you are interested in membership under any of our programmes, please contact us to request more information and enrol for consideration.</p>
                            <p className={style.enpowerP3} onClick={()=>this.setState({openModal:true})}>Be a member</p>
                        </div>
                    </div>
                </div>
                {this.state.openModal?<div className={style.modal} onClick={(event)=>this.closeModal(event)}>
            <div className={style.modalItems} onClick={(event)=>this.doNothing(event)}>
              <p className={style.modalP1}>Believe, Connect Create</p>
              <p className={style.modalP2}>Become a member of NUAfrica</p>
              <p className={style.modalP3}>Benefits of becoming a member</p>
              <ul>
                <li >Medical Insurance</li>
                <li>NUAPP full version (coming soon)</li>
                <li>Access to NUAfricas pool of networks</li>
                <li>50% off on NUAfrica goods and services</li>
                <li>Legal aid; Contract reviews, Split sheets, Matters around art</li>
                <li>Psychosocial support</li>
                <li>Free workshop and master classes</li>
                <li>Once a month free venue access plus support (NUHub)</li>
              </ul>
              <p className={style.modalP4}>For 20,000/= annualy become a member</p>
              <NavLink to='/memberForm' className={style.donateDiv}><p className={style.modalP5}>JOIN NOW</p></NavLink>
            
            </div></div>:null}
            </div>
        )
    }
}
export default PartnerWelcome
