import React, { Component } from 'react'
import style from './Reel.module.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FaAngleLeft,FaAngleRight } from 'react-icons/fa';
import {NavLink} from 'react-router-dom';
const theReel=[
  {id:1, color:'red', title:'NU-AFRICA SANAA', to:"/sanaa",  subTitle:'Now uniting Africa through Arts and Performing Arts', image:'./photo5.jpg'},
  {id:2, color:'red',title:'NU-AFRICA MICHEZO',title2:'All About Sports',  subTitle:'Now uniting Africa through Sports development', image:'./photo11.jpg'},
  {id:3, color:'red',title:'NU-AFRICA MISHONO',to:"/mishono",  title2:'Fashion African Way', subTitle:'Now uniting Africa through Fashion and culture', image:'./photo1.jpg'},
  {id:4, color:'red',title:'NU-AFRICA MIZIKI',to:"/miziki", title2:'Care About People',  subTitle:'Now uniting Africa through Music and Entertainment', image:'./photo8.jpg'},
  {id:5, color:'red',title:'FILAMU NA PICHA', to:"/filamu", title2:'Care About People',  subTitle:'Now uniting Africa through Film & Photography', image:'./photo9.jpg'},
  {id:6, color:'red',title:'NU-AFRICA POWER', to:"/power", title2:'Care About People',  subTitle:'Empowering Africa through Governance and Leadership', image:'./photo7.jpg'}]
class Reel extends Component {
  state={
    theAdsArray:[]
  }
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
      }
      componentDidMount(){
        
        console.log('niko hapa reel')
      }

     /* fetchAdsInfo=async()=>{
       
        try {
          const theRef = firebase.database().ref('/reel/');
          var adsArray=[]
          var adsArray2=[]
         await theRef.once('value', (snapshot) => {
           snapshot.forEach((data) => {
            const id = data.key
            const title = data.val().title
            const details= data.val().details
            const image = data.val().image
            const color = data.val().color
            adsArray={
              title: title, details:details, image:image, id:id,color:color
            }
             
            adsArray2.push(adsArray)
            
           })
          })
          this.setState({
            theAdsArray:adsArray2
           })
          console.log('adsArray', this.state.adsArray)
        } catch (error) {
          
        }
      }*/

      next() {
        this.slider.slickNext();
      }
      previous() {
        this.slider.slickPrev();
      }
      scrollToPos=()=>{
      window.scrollTo(0, 550)
      }
    
    render() {
         let donateStyle=''
         let reelTextStyle=''
         let titleStyle=''
        const settings = { 
            /*infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            className:"slides",
            fade: true,
            autoplay: true,
            pauseOnHover: false,
      speed: 700,
      autoplaySpeed: 5000,
      cssEase: "linear"*/
      infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          className:"slides",
          autoplay: true,
          pauseOnHover: false,
          speed: 400,
          autoplaySpeed: 5000,
          cssEase: "linear",
      
          };
        return (
            <div className={style.boduTitleMainCont}>
                <Slider ref={c => (this.slider = c)} {...settings}>
                  
                {theReel.map((item)=>{
                  if(item.color!='black'){
                    donateStyle={
                      backgroundColor:'#174905'
                    }
                    reelTextStyle={
                      color:'#ffffff'
                    }
                    titleStyle={
                      color:'#fff'
                    }
                  }else{
                    donateStyle={
                     backgroundColor:'#174905'
                    }
                    reelTextStyle={
                      color:'#fff'
                    }
                    titleStyle={
                      color:'#fff'
                    }
                  }
                 return(
                       <div  className={style.titleDivCont} key={item.id}>
                       <div className={style.titleCont}>
                      <div className={style.titleCont2}>
                       {item.title?<h4 className={style.titleH4} id={style.titleH4} style={titleStyle}>{item.title}</h4>:null}
                       <p className={style.detailsP} style={reelTextStyle}>{item.subTitle} </p>
                       <NavLink to='/department' className={style.seeP} >
                       <p>SEE MORE</p>
                       </NavLink>
                       </div></div>
                      <div className={style.aroowsDiv}>
                      <FaAngleLeft className={style.backFang}  onClick={()=>this.previous()}/>
                      <FaAngleRight className={style.frontFang}  onClick={()=>this.next()}/>
                      </div>
                     <img className={style.theImage} src={item.image}/>
                     
                    
                     </div>

                 )})}

                </Slider>
                
            </div>
        )
    }
}
export default Reel
