import React, { Component } from 'react'
import style from './shop2.module.scss';
import {MdLocationOn,MdHistory,MdDashboard,MdStar,MdEmail} from "react-icons/md";
import {FaPhoneAlt} from "react-icons/fa";
import {IoLogoWhatsapp} from 'react-icons/io';
import firebase from 'firebase';
import {NavLink} from 'react-router-dom'
export class shop extends Component {
    state={
        showModal:false,
       /* details:[
            {id:1,name:'Branded Water Bottle',price:'1,250.00',cat:'Bottles', image:'https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/HomeAds%2FBags%2Fclucth.jpg?alt=media&token=df697c68-ba19-40de-b99f-3123e7cc3de1'},
            {id:2,name:'Branded Notebook',price:'1,499.00',cat:'Stationery', image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzI-dGSMK7b0YgFRs-JKANLBwF1mZpcLdFFNCI8k63ssURxKBLj4Se_JT3IIDe8g4G2v0&usqp=CAU'},
            {id:3,name:'Branded Water Bottle',price:'1,250.00',cat:'Bottles', image:'https://cdn.shopify.com/s/files/1/1691/0593/products/Custom-School-Blue-Stainless-Steel-750ml-Water-Bottle-Left-Image.jpg?v=1660747387&width=1946'},
            {id:4,name:'Branded Notebook',price:'1,499.00',cat:'Stationery', image:'https://www.wavesbranding.co.ke/files/2020/01/BRANDED-NOTEBOOK-BANDA.jpg'},
            {id:5,name:'Branded Water Bottle',price:'1,250.00',cat:'Bottles', image:'https://cdn.shopify.com/s/files/1/3103/5874/products/Softtouch-Bottles---Red_400x.jpg?v=1672840585'},
            {id:6,name:'Branded Notebook',price:'1,499.00',cat:'Stationery', image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzI-dGSMK7b0YgFRs-JKANLBwF1mZpcLdFFNCI8k63ssURxKBLj4Se_JT3IIDe8g4G2v0&usqp=CAU'},
            {id:7,name:'Branded Water Bottle',price:'1,250.00',cat:'Bottles', image:'https://cdn.shopify.com/s/files/1/1691/0593/products/Custom-School-Blue-Stainless-Steel-750ml-Water-Bottle-Left-Image.jpg?v=1660747387&width=1946'},
            {id:8,name:'Branded Notebook',price:'1,499.00',cat:'Stationery', image:'https://www.wavesbranding.co.ke/files/2020/01/BRANDED-NOTEBOOK-BANDA.jpg'},
            {id:9,name:'Branded Water Bottle',price:'1,250.00',cat:'Bottles', image:'https://cdn.shopify.com/s/files/1/3103/5874/products/Softtouch-Bottles---Red_400x.jpg?v=1672840585'},
            {id:10,name:'Branded Notebook',price:'1,499.00',cat:'Stationery', image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzI-dGSMK7b0YgFRs-JKANLBwF1mZpcLdFFNCI8k63ssURxKBLj4Se_JT3IIDe8g4G2v0&usqp=CAU'},
            {id:11,name:'Branded Water Bottle',price:'1,250.00',cat:'Bottles', image:'https://cdn.shopify.com/s/files/1/1691/0593/products/Custom-School-Blue-Stainless-Steel-750ml-Water-Bottle-Left-Image.jpg?v=1660747387&width=1946'},
            {id:12,name:'Branded Notebook',price:'1,499.00',cat:'Stationery', image:'https://www.wavesbranding.co.ke/files/2020/01/BRANDED-NOTEBOOK-BANDA.jpg'},
                ]*/
   }
   componentDidMount() {
       window.scrollTo(0, 0)
       this.fetchProducts()
     }
     fetchProducts=async()=>{
        try {
          const theRef = firebase.database().ref('/theProducts/');
          var prodArray=[]
          var prodArray2=[],i=0
         await theRef.once('value', (snapshot) => {
             console.log('snapshot',snapshot)
            snapshot.forEach((data) => {
                const image = data.val().image
                const price = data.val().price
                const type = data.val().title
                const cat = data.val().cat
                const title = data.val().title
                const no = data.key
                console.log('th noooooooooooooo',no)
                prodArray={
                  image: image, price: price, id:no,type:type,cat:cat,title:title
                }
                 
                prodArray2.push(prodArray)
                i++
                if(i===snapshot.numChildren()){
                    this.setState({details:prodArray2})
                    console.log('prodArray2',prodArray2)
                }
            })
         })
        } catch (error) {
          
        }
        }
     showModal=(result)=>{
        this.setState({showModal:result})
    }
    doNothing=(event)=>{
        event.preventDefault()
        event.stopPropagation()
     }
    render() {
        const detailsList=this.state.details?.map(item=>{
            return(
                <NavLink  to={{
                    pathname: "/productdetails",
                    state: item.id // your data array of objects
                  }}className={style.causedivzoom} key={item.id}>
                <img src={item.image}/>
            <div className={style.detDiv}>
            <p className={style.prodTitle}>{item.title}</p>
            <div>
                <MdStar className={style.starIc}/>
                <MdStar className={style.starIc}/>
                <MdStar className={style.starIc}/>
                <MdStar className={style.starIc}/>
                <MdStar className={style.starIc}/>
            </div>
            <p className={style.prodPrice}>{'KES '+item.price}</p>
            <div className={style.detDiv2}>
                <MdDashboard size='14' color='#858585'/>
                <p>{item.cat}</p>
            </div>
            </div>
            <p className={style.orderP} onClick={()=>this.setState({showModal:true})}>ORDER</p>
                </NavLink>
            )
        })
        return (
            <>
             <div className={style.statusBar}></div>
           <div className={style.mainCont}>
           
            <div className={style.causesItemsCont}>
                 {detailsList}
                </div> 
            {this.state.showModal?<div className={style.modal} onClick={()=>this.showModal(false)}>
                <div className={style.modalCont} onClick={(event)=>this.doNothing(event)}>
                <h5>Purchase/Enquire</h5>
                <p className={style.book}>For purchasing or making enquiries of this product please reach us through the below contacts</p>
                <div className={style.contDiv}><FaPhoneAlt className='ulicon' size={14} color='#000'/><p>+254 705 059 188</p></div>
                <div className={style.contDiv}><MdEmail className='ulicon' size={16} color='#000'/><p>info@nuafricapamoja.org</p></div>
                <div className={style.contDiv}><IoLogoWhatsapp className='ulicon' size={16} color='#000'/><p>+254 705 059 188</p></div>
                <p id={style.close} onClick={()=>this.showModal(false)}>Close</p>
                </div>
                </div>:null}</div></>
               
        )
    }
}

export default shop
