import React, { Component } from 'react'
import style from './Search3.module.scss';
class Search3 extends Component {
    render() {
        return (
            <div  className={style.container}>
                
            </div>
        )
    }
}

export default Search3
