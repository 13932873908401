import React, { Component } from 'react'
import styles from './Filamu.module.scss';
import Members from './SanaaMembers';
import firebase from 'firebase';
import {NavLink} from 'react-router-dom'

 class Filamu extends Component {
     state={
         infoArray:[]
     }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.fetchInfo()
      }
      fetchInfo=async()=>{
        var array=[]
        var array2=[]
      try {
       const theRef = firebase.database().ref('/nuapdep/filamu');
       await theRef.once('value', (snapshot) => {
         snapshot.forEach((data) => {
             const info = data.val()
             console.log('info',info)
             array={
                info:info
             }
             array2.push(array)
         })
     })
       this.setState({
        infoArray:array2
        })
       console.log('array2', array2)
     } catch (error) {
       
     }
   }
    render() {
        const infoList=this.state.infoArray.map(item=>{
             return(
                <div className={styles.infoList}>
                <p>{item.info}</p>
                </div>
                    
             )
         })
        return (
            <div className={styles.container}>
                <div className={styles.container1}>
                    <div className={styles.container1Det}>
                <p className={styles.title}>NU AFRICA FILAMU NA PICHA</p>
                <p className={styles.details}>Now uniting Africa through Film and Photography</p>
                <div style={{display:"flex"}}>
                <div className={styles.contDonate}>
                      <NavLink to='/supportus' className={styles.contDonateP} ><p>How to Help</p></NavLink>
                      
                       </div>
                       <div className={styles.contactUs}>
                       <NavLink to='/contacts' className={styles.contDonateP} ><p>Contact Us</p></NavLink>
                       </div>
                </div>
                
                       </div>
                </div>
                <div className={styles.container2}>
                 <h2>ABOUT NUAFRICA FILAMU NA PICHA</h2>
                  {infoList}
                 <h3>Life is like photography; you need the negatives to develop</h3>
                </div>
                <div className={styles.container3}>
                <p id={styles.jI}>Join the organization’s growing team and inspire the youth we work with.</p>
                <NavLink to='/supportus' style={{textDecoration:'none'}}><p id={styles.gI}>GET INVOLVED</p></NavLink>
                </div>
                
            </div>
        )
    }
}

export default Filamu
