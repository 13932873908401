import {FETCH_POSTS, FETCH_USERS,CART_ITEMS,ACCOUNT_ITEM} from './types'

export const saveUsers=(savedusers)=>({
    type: FETCH_USERS,
    savedusers: savedusers
})
export const savePosts=(savedposts)=>({
    type: FETCH_POSTS,
    savedposts: savedposts
})
export const saveCartItems=(savedCartItems)=>({
    type: CART_ITEMS,
    savedCartItems: savedCartItems
})
export const saveItemAccount=(savedAcountItem)=>({
    type: ACCOUNT_ITEM,
    savedAcountItem: savedAcountItem
})

