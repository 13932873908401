import React, { Component } from 'react'
import style from './HomeDonors.module.scss';
import {NavLink} from 'react-router-dom'
class HomeDonors extends Component {
    state={
         details:[
            {id:1, title:'Loise Wambui', subTitle:'Volunteers Coordinator', type:'Program Manager', image:'https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/volunteers%2Fvolunteer.jpeg?alt=media&token=e1c36b0a-0b98-43fb-81e0-b56bd8f945b1'},
            {id:2, title:'Jane Doe', subTitle:'Social Worker',type:'Donor', image:'https://cdn.pixabay.com/photo/2017/02/16/23/10/smile-2072907_960_720.jpg'},
            {id:3, title:'John Paul', subTitle:'Teacher',type:'Volunteer', image:'https://cdn.pixabay.com/photo/2016/06/05/01/41/african-american-1436661_960_720.jpg'},
            {id:4, title:'Kim Benard', subTitle:'Farmer',type:'Donor', image:'https://images.pexels.com/photos/4242520/pexels-photo-4242520.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'}]
    }
    render() {
        const detailsList=this.state.details.map(item=>{
            return(
                <div className={style.imghoverzoom} key={item.id}>
                 <img style={{}} src={item.image}/>
                <h1>{item.title}</h1>
                <h4>{item.subTitle}</h4>
                <p>{item.type}</p>
                </div>
            )
        })
        return (
            <div className={style.bodySection4}>
                <div className={style.donorsVolCont}>
                    <h1>Our Partners</h1>
                    
                </div>
                <div className={style.donorItemsCont}>
                 {detailsList}
                </div>
               {this.props.message? <div className={style.beAdonor}>
                    <h3>GET INVOLVED</h3>
                    <NavLink to='/supportus' style={{textDecoration:'none'}} ><p>Support Us</p></NavLink>
                </div>:null}
            </div>
        )
    }
}

export default HomeDonors
