import React, { Component } from 'react'
import styles from './Education.module.scss';
import firebase from 'firebase';
import {FaFacebookF,FaRunning,} from 'react-icons/fa';
import {MdClass,MdPeople,MdLocalPlay} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
let theImage='https://images.pexels.com/photos/690779/pexels-photo-690779.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
let details=[
  {id:1, title:'Education Support', path:'/education', details:'The Education Support program provides schools, teachers and students with the tools and support they need to succeed in the 21st Century.', 
  image:'https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/causes%2FNuap.jpg?alt=media&token=406e97c4-af06-409e-9931-a7d556af134b'},
  {id:2, title:'Social Entrepreneurship', path:'/social', details:'The world currently has the largest young population ever, The UN World Population estimates that in 2020 there will be 1.2 billion young people worldwide, with 21% of whom live in Africa. And these numbers will only keep growing by 2030.',
  image:'./photo17.jpg'},
  {id:3, title:'Sports For Development', path:'/sports', details:'Sports for development collaborates with governmental and non-governmental partner organizations on promoting development perspectives for youth through sporting activities within schools and their communities',
  image:'./photo20.jpg'},
  {id:5, title:'Nurturing Talent', path:'/talent', details:'Nu-Africa identifies and nurtures talent in perfoming arts, arts, sports, fashion, music, film and photography. Nurturing talent contributes to the Nu-Africa Pamoja Foundation vision to grow a pool of productive youth who will become the leaders of tomorrow.',
  image:'photo19.jpg'},
  {id:6, title:'Governance and Leadership', path:'/gorvenance', details:'Good governance and leadership is the pathway to sustainable development in Africa, and critical for the achievement of results. No community can develop beyond the level of its leadership. Underdevelopment and corruption can adduce to the problem of leadership and governance. Africa needs committed leaders who will govern with integrity and influence it human and natural resources toward the actualization of sustainable national development.',
  image:'./photo18.jpg'},
  ]
 class Talent extends Component {
     state={
         infoArray:[]
     }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.fetchInfo()
      }
      fetchInfo=async()=>{
        var array=[]
        var array2=[]
      try {
       const theRef = firebase.database().ref('/nuapdep/miziki');
       await theRef.once('value', (snapshot) => {
         snapshot.forEach((data) => {
             const info = data.val()
             console.log('info',info)
             array={
                info:info
             }
             array2.push(array)
         })
     })
       this.setState({
        infoArray:array2
        })
       console.log('array2', array2)
     } catch (error) {
       
     }
   }
    render() {
        const infoList=this.state.infoArray.map(item=>{
             return(
                <div className={styles.infoList}>
                <p>{item.info}</p>
                </div>
                    
             )
         })
         const newPostsList=details.slice(0,4).map(item=>{
            return(
                <NavLink className={styles.newPostCont}  to={{pathname:item.path, state:item}}> 
                {/*<div className={style.imgCont2} style={{backgroundImage:"url(" + theImage + ")"}}>*/}
                <div className={styles.imgCont2} style={{backgroundImage:"url(" + item.image + ")"}}>
                </div>
                <div className={styles.newPostCont2}>
                    <h2>{item.title}</h2>
                    <p>SEE MORE...</p>
                </div>
            </NavLink>
            )
        })
        return (
            <div className={styles.container}>
                 <div className={styles.container1} style={{backgroundImage:"url(" + './photo19.jpg' + ")"}}>
                <div className={styles.container1B}>
                    <div className={styles.container1Det}>
                <p className={styles.title}>NURTURING TALENT</p>
                <p className={styles.details}>Donate to Nurturing Talent</p>
                <p className={styles.details2}>We support our members in carrying out relevant youth-centered art, film and photography projects that address contemporary issues advocating for societal change and as well as entertain.</p>
                <div className={styles.contDonateMain}>
                <NavLink to='/donate2' className={styles.contDonate}> 
                <p className={styles.contDonateP}>DONATE NOW</p>
                </NavLink>
                <NavLink to='/contacts' className={styles.contDonate1}> 
                <p className={styles.contDonateP}>ENROLL</p>
                </NavLink>
                </div>
                
                       </div> </div>
                </div>
                <div className={styles.container21}>
                <div className={styles.container2}>
                 <h2>HOW THE PROGRAMME WORKS</h2>
                 <p>NUAfrica Pamoja Foundation seeks to promote African content in film, music and performing Arts. NUAHub is based in Nairobi, Kenya. The center aims to develop the local music industry and provide opportunites in the areas of music, music production, music technology, film and photography.</p>
                 <p>NUAfricaHub provides a fully equiped music studio and creative space for gifted youths to engage with eachother though music, film and photography. </p>
                 <p>NUAHub has a network of industry professionals around the world in various sectors of Arts, performing arts, sports, fashions, music film and photography. </p>
                 <p>The Hub will nurture talented youth with a passion for the arts industry.The center will provide space for musicians, dancers, artists and various creatives to network and showcase their talent. The center will also serve artists, producers and other practising professional who need to improve their skills.</p>
                 <p>Training sessions will form workshops where industry professionals will be invited to address talented and interested artist and youth from around Africa.</p>
                 <p>Youths with interest into arts can access professional equipment advance their interests. Nurturing Talent equips the youth to be better creatives that earn from they talents </p>
                 <p>The programme will provide mentorship, guidance and career enhancement skills to inspire the youth to choose the correct carreer path and excel in it. </p>
                 
                
                </div>
                <div className={styles.container2B}>
                <div  className={styles.contRight1}>
               <h1>OTHER CAUSES</h1>
                {newPostsList}
               </div>
                </div>
                </div>
                <div className={styles.container3A}>
                <div className={styles.container3}>
                <div className={styles.div1}>
                 <h3 className={styles.det1}>467m USD</h3>
                 <h4 className={styles.det2}>Projected over the top tv and video revenue in sub saharan Africa 2021</h4>
                 
                   </div>
                <div className={styles.div2}>
                <h3 className={styles.det1}>5.42bn USD</h3>
                <h4 className={styles.det2}>Over the top tv and video revenue in sub saharan Africa 2019</h4>
                
                </div>
                <div className={styles.div3}>
                 <h3 className={styles.det1}>13-30 Yrs</h3>
                 <h4 className={styles.det2}>Youths constitutes Africa's heaviest users of social media</h4>
                </div>
                </div>
                <p className={styles.infoP}>info: statista.com, researchgate.net</p>
                </div>
                <div className={styles.container50}>
                <h2>HOW WE HELP PROVIDE EDUCATION SUPPORT</h2>
                <div className={styles.container5}>
                    <div className={styles.container6}>
                      <div className={styles.supportDiv}>
                          <MdClass className={styles.supportIc}/>
                          <div>
                            <h3>Music and film studio</h3>
                            <p>A fully equipped studio for creatives to make content that unites Africa.</p>
                          </div>
                      </div>
                      <div className={styles.supportDiv}>
                          <FaRunning className={styles.supportIc}/>
                          <div>
                            <h3>NUAfrica TV</h3>
                            <p>NUAfrica makes conduct on YouTube and other social media as a tool of Now Uniting Africa.</p>
                          </div>
                      </div>
                    </div>
                    <div className={styles.container7}>
                 <h4>HOW WE HELP PROVIDE EDUCATION SUPPORT</h4>
                 <p>NUAfricaHub provides a full equiped music studio and creative space for gifted youths to engage with eachother though music, film and photography. </p>
                 <p>The hub provides space for musicians, dancers, artists and various creatives to network and sharpen their skills through arts.
                 Youths with interest into arts can access professional equipment advance their interests. Nurturing Talent equips the youth to be better creatives that earn from they talents </p>   
                 <p></p>
                 <p></p>     
                    </div>
                    </div>
                </div>
                <div className={styles.container8}>
                <h4>Have Questions About NU-Africa and How We Work?</h4>
                 <p>Donating is an important decision. So when you’re passionate about a cause and want to make a difference, we invite you to partner with us. NUAfrica is 100% committed to financial integrity and stewardship. If you have any questions about NUAfrica or exactly how your donation will be used, please don’t hesitate to contact us.</p>      
                </div>
                
            </div>
        )
    }
}
export default Talent
