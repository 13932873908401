import React, { Component } from 'react'
import styles from './Donate2.module.scss';
import {IoMdCheckmark} from "react-icons/io";
import {NavLink} from 'react-router-dom'
let donations=[
    {id:2, amount:10},
    {id:3, amount:20},
    {id:4, amount:50},
    {id:5, amount:100},
    {id:6, amount:200}
]

export class Donate extends Component {
    state={
        paymentType:'One time',
        exchange:'',
        donationAmount:'',
        donAmount:'',
        paymentMethod:'VISA',
        currency:'KES '
    }
    paymentType=(type)=>{
      this.setState({paymentType:type})
    }
    handleExchangeChange(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({exchange: event.target.value});
      }
      handleCurrency(event) {
        event.preventDefault();
        event.stopPropagation();
        console.log('currency', event.target.value)
        this.setState({currency: event.target.value});
      }
      handlePriceChange(event) {
          //console.log
        event.preventDefault();
        event.stopPropagation();
        var priceInfo=event.target.value
        this.setState({donationAmount:priceInfo});
      }
      paymentAmount=(amount)=>{
       this.setState({donAmount:amount,donationAmount:''})
      }
      donat2=()=>{
        this.setState({donAmount:''})
       }
       paymentMethod=(method)=>{
        this.setState({paymentMethod:method})
       }
    render() {
        let payStyle1=[]
        let payStyle2=[]
        let donStyle=[]
        let showRecurring=''
        if (this.state.paymentType==='One time') {
            showRecurring=''
            payStyle1={
                backgroundColor:'#eb3e32'
            }
            payStyle2={
                backgroundColor:'#eee'
            }
        } else if (this.state.paymentType==='Recurring'){
            showRecurring=<div className={styles.contDiv}>
            <p className={styles.condP}>Donation Type</p>
            <div className={styles.selectCont}>
            <select  className={styles.selectDiv} value={this.state.value} onChange={(event)=>this.handleExchangeChange(event)}>
              <option  selected value="Educate Children">Weekly</option>
              <option value="Nu-Africa Michezo">Daily</option>
              <option value="Nu-Africa Michezo">Monthly</option>
              <option value="Nu-Africa Mishono">Annualy</option>
            </select>
            </div>
            </div>
            payStyle1={
                backgroundColor:'#eee'
            }
            payStyle2={
                backgroundColor:'#eb3e32'
            }
        }
        const donationsList=donations.map(item=>{
            let amount = 0
            if (this.state.currency==='KES ') {
                amount=(item.amount*100).toLocaleString()
            } else {
                amount=this.state.currency+item.amount
            }
            if (item.amount===this.state.donAmount) {
                donStyle={
                    backgroundColor:'#eb3e32'
                }
            } else {
                donStyle={
                    backgroundColor:'#eee'
                }
            }
            return(
                <div className={styles.donList}>
                <div className={styles.donListSelector} style={donStyle} onClick={()=>this.paymentAmount(item.amount)}>
                         <IoMdCheckmark className={styles.donListIc}/> 
                         </div>
                         <p>{amount}</p>
                </div>
                   
            )
        })
        return (
            <>
             <div className={styles.statusBar}></div>
            <div className={styles.container}>
                
                <div className={styles.container1}>
               <div className={styles.container2}>
               <p className={styles.dP1}>Donate</p>
               <p className={styles.dP2}>Your donation will help meet immediate and pressing needs of those who require it most. You will help nurture talent and make dreams come true. Please make your gift today. Any amount will help. We sincerely appreciate your generosity.</p>
               
                <img  className={styles.theImage2} src={'./mpesa.png'} alt="NU-Africa"></img>
                 <p className={styles.dP2A}>Step 1: Go to M-PESA Menu </p>
                 <p className={styles.dP2A}>Step 2: Go to Lipa na M-PESA</p>
                 <p className={styles.dP2A}>Step 3: Go to Buy Goods and Services</p>
                 <p className={styles.dP2A}>Step 4: Enter <span style={{borderWidth:1,borderColor:'#fff',backgroundColor:'#eb3e32',padding:2}}>TILL NO 5539205</span></p>
                 <p className={styles.dP2A}>Step 5: Enter Amount e.g 10000</p>
                 <p className={styles.dP2A}>Step 6: Enter your M-PESA pin</p>
                 <p className={styles.dP2A}>Step 7: Press ACCEPT</p>
               
                <p className={styles.dP2b}>CHEQUES &amp; BANK TRANSFERS</p>
                <NavLink to='/contacts' className={styles.dP4B}>Contact us for details</NavLink>
                { /*<p className={styles.dP2A}>Please contact us for banking details</p>
               <p className={styles.dP2A}>DIAMOND TRUST BANK</p>
                 <p className={styles.dP2b}>BANK DETAILS</p>
                <p className={styles.dP2A}>DIAMOND TRUST BANK</p>
                <p className={styles.dP3}>ACC NO</p>
                <p className={styles.dP4}>0660625002</p>
                <div> <img  className={styles.theImage} src={'./mpesa.png'} alt="NU-Africa"></img>
                <img  className={styles.theImage2} src={'./dtb.png'} alt="NU-Africa"></img></div>*/}
               </div>
               </div>
            </div></>
        )
    }
}

export default Donate
