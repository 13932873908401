import React, { Component } from 'react'
import style from './AboutUs.module.scss';
import {NavLink} from 'react-router-dom'
var image='https://images.pexels.com/photos/4155091/pexels-photo-4155091.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
let imageFamliy ="https://images.pexels.com/photos/4260096/pexels-photo-4260096.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let imageDopeness ="https://images.pexels.com/photos/209728/pexels-photo-209728.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let imageRules ="https://images.pexels.com/photos/4027658/pexels-photo-4027658.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let imageCharacter ="https://images.pexels.com/photos/4116569/pexels-photo-4116569.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
let equality ="https://images.pexels.com/photos/6185621/pexels-photo-6185621.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"


class AboutUs extends Component {
    render() {
        return (
            <div className={style.mainContainer}>
                 <div className={style.imgDiv}>
                <img src={image}/>
                <h1>WHO WE ARE</h1>
                </div>
                 <div className={style.container1}>
                <h2 className={style.title}></h2>
                <p  className={style.subTitle}>NUAfrica Pamoja Foundation is an organization empowering Africa through Arts, performing Arts, fashion, sports, music, film, photography, governance and leadership. We aim to transform Communities by inspiring and empowering them to be the best versions of themselves, through enhancing and showcasing their talents by providing platforms through which they can engage with each other.</p>
                
                <h2>Mission</h2>
                <p>To engage and bring together the youth to effect change in the society through promotion of art(s), sports, fashion, music, film, photography, governance and leadership</p>

                <h2>Vision</h2>
                <p>To transform communities by inspiring and facilitating the youth in Africa to achieve their fullest potential and contribute to peace, unity, growth and development. </p>


              <div  className={style.princDiv}>
                 <h3 className={style.subTitle2}>Principles</h3>
            <div className={style.container}>
                 <div className={style.sellCont}>
                <div className={style.sellCont1}>
                    <div className={style.sellImg}>
                    <img  src={imageFamliy} alt="Hurumart" />
                    </div>
                </div>
                <div className={style.sellCont2}>
                <h3>Family</h3>
                <p>The family performs several important functions for any community, it provides emotional and practical support for its members.</p>
                <p>NU Africa Pamoja Foundation upholds family values to all our members. We are a big family with respect to each one of us.</p>
            
                </div>
                </div>

                <div className={style.sellCont}>
                <div className={style.sellCont1}   id={style.sellContA}>
                    <div className={style.sellImg}>
                    <img  src={imageDopeness} alt="Hurumart" />
                    </div>
                </div>
                <div className={style.sellCont2}   id={style.sellContB}>
                <h3>Dopeness</h3>
                <p>We believe is the best version of oneself, greatness, eminence and hard work are vital in NU Africa Pamoja. </p>
                <p>We bring the best out of everyone at no expense of anyone else. We purpose for the best standard in all of our activities.</p>
                
                </div>
                </div>

                <div className={style.sellCont}>
                <div className={style.sellCont1}>
                    <div className={style.sellImg}>
                    <img  src={imageRules} alt="Hurumart" />
                    </div>
                </div>
                <div className={style.sellCont2}>
                <h3>Integrity</h3>
                <p>Our lives are centered around our deepest values. They determine how we conduct ourselves and engage with others. We are deliberate and passionate in upholding the highest standards of integrity in our engagements. </p>
                 <p>We advocate for compliance with rules, regulations and principles of fairness.</p>
                </div>
                </div>

                <div className={style.sellCont}>
                <div className={style.sellCont1}  id={style.sellContA}>
                    <div className={style.sellImg}>
                    <img  src={imageCharacter} alt="Hurumart" />
                    </div>
                </div>
                <div className={style.sellCont2}   id={style.sellContB}>
                <h3>Character</h3>
                <p>Talent is a gift but character is a choice. Individual can raise above the limits of their character by cultivating good character with a moral compass.  NU Africa believes in having the courage to build character, compassion, loyalty, honesty, integrity, selflessness and sacrifice in a world that is devoid of character.</p>
                
                </div>
                </div>
                  <div className={style.sellCont}>
                <div className={style.sellCont1}>
                    <div className={style.sellImg}>
                    <img  src={equality} alt="Hurumart" />
                    </div>
                </div>
             <div className={style.sellCont2}>
                <h3>Equality</h3>
                <p>We believe no one should be excluded from the opportunity to develop into the best version of themselves, and to engage constructively in the within their communities. This is a tenet of the inherent dignity of all people. The youth today face the defining challenge of inequality which limits their potential.</p>
                <p>We address inequality by advocating for fairness, tolerance and inclusion of all persons regardless of the differences that exist amongst us</p>
                </div>
                </div>
            </div></div>
            </div>
            </div>
        )
    }
}
export default AboutUs
















/*import React, { Component } from 'react'
import style from './AboutUs.module.scss';

 class AboutUs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
          
  
            <div className={style.container}>
            <div className={style.container2}>  
            <h1>ABOUT US</h1>
            <h2>NU AFRICA PAMOJA FOUNDATION</h2>
            <p>NU Africa Pamoja Foundation is organization empowering Africa through Arts, performing Arts, fashion, sports, music, film and photography.</p>
            <p>We aim to transform Communities by inspiring and empowering to help them be the best versions of themselves, through enhancing and showing casing their talents by providing platforms to which they can engage with each other.</p> 
            <h3>PRINCIPLES.</h3>
            <h4>Family</h4>
            <p>The family performs several important functions for any community, it provides emotional and practical support for its members. NU Africa Pamoja Foundation upholds family values to all our members. We are a big family with respect to each one of us.</p>
            <h4>Dopeness</h4>
            <p>We believe is the best version of oneself, greatness, eminence and hard work are vital in NU Africa Pamoja. We bring the best out of everyone at no expense of anyone else. We purpose for the best standard in all of our activities.</p>
            <h4>Rules</h4>
            <p>We advocate the following of rule, regulations or principles fairness without favoritism or discrimination we believe in equality.</p>
            <h4>Character</h4>
            <p>Talent is a gift but character is a choice, everyone can have talent at the same level but everyone cannot have character at the same level. Any individual cannot raise above the limits of their character. NU Africa trusts in courage, compassion, loyalty, honesty, integrity, selflessness and sacrifice.</p>
            <h4>God</h4>
            <p>NU Africa Pamoja foundation believes in the supreme being, the creator and ruler of the Universe and source of all moral authority. We embrace everyone from various religious beliefs. We believe that if any individual respects everyone just like his or her own family, becomes great and helps other become the best versions of themselves, follows the rules fairly while maintaining a good character, God shall reward such a person.</p>
            </div>
            </div>
          
        )
    }
}

export default AboutUs*/
