import React, { Component } from 'react'
import style from './GetInvolved.module.scss';
import {NavLink} from 'react-router-dom';
import { RiPauseMiniLine} from 'react-icons/ri';
import { FaPeopleCarry,FaHandshake,FaPeopleArrows} from 'react-icons/fa';
import { BiDonateHeart} from 'react-icons/bi';
import { IoIosPeople} from 'react-icons/io';
class GetInvolved extends Component {
    state={
         details:[
                {id:1,icon: <BiDonateHeart size={70} color='#8d2855'/>, title:'DONATE', to:"/volunteer", subTitle:'Together we can transform Communties by inspiring and empowering the youth to be the best versions of themselves.', image:'https://images.unsplash.com/photo-1547496613-4e19af6736dc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80'},
                {id:2,icon: <FaPeopleCarry size={70} color='#8d2855'/>, title:'VOLUNTEER',to:"/volunteer", subTitle:'Volunteers are welcome to register and work with us in any of these departments, depending on their area of expertise.', image:'https://images.pexels.com/photos/6647020/pexels-photo-6647020.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
                {id:3,icon: <FaHandshake size={70} color='#8d2855'/>, title:'PARTNER',to:"/volunteer", subTitle:' You can be a partner within our organisation on various programmes and projects.', image:'https://images.pexels.com/photos/5647560/pexels-photo-5647560.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
                {id:4,icon: <IoIosPeople size={70} color='#8d2855'/>, title:'MEMBERSHIP',to:"/volunteer", subTitle:'NUAfrica Pamoja Foundation will soon have membership opportunities. Follow us on our social media, subscribe to our news latters doe updates.', image:'https://images.unsplash.com/photo-1571417814270-5cfb10916e02?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80'}]
    }
    render() {
        const detailsList=this.state.details.map(item=>{
            return(
               /*<NavLink to={{pathname:'/causes', state:item}}className={style.causedivzoom} key={item.id}>*/
                <NavLink to='volunteer' className={style.causedivzoom} key={item.id}>
                {/*<img src={item.image}/>*/}
                <div className={style.ICDiv}>{item.icon}</div>
                <h1>{item.title}</h1>
                <p>{item.subTitle}</p>
                 <div className={style.donateDiv3}>
                       <p>How To Help?</p>
                       </div>
                </NavLink>
            )
        })
        return (
            <div  className={style.container01}>
            <div className={style.container}>
                <div className={style.causesDetCont}>
                    <h1>Get Involved</h1>
                    <p>Support Us To Continue Touching Lives</p>
                </div>
                <div className={style.causesItemsCont}>
                 {detailsList}
                </div>
            </div>
            </div>
        )
    }
}

export default GetInvolved
