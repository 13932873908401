import React, { Component } from 'react'
import style from './CartFinish.module.scss'
import {NavLink} from 'react-router-dom';
import {IoMdCheckmark,IoMdCheckmarkCircle} from "react-icons/io";
import SnackBar from '../Helper/SnackBar'
var image="https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/HomeAds%2FAccessories%2Fbrace.jpg?alt=media&token=52346652-50b2-43c4-8923-7dc98e538ef0"
var selectColorActive={
    backgroundColor:"#FF7C59"
}
var selectColorInActive={
    backgroundColor:"#eee"
}
 class CartFinish extends Component {
     state={
         phoneNo:'CALL FOR HELP',
         delivery:'',
         snackBar:false
     }
     componentDidMount(){
        console.log(this.props.location.state.itemsArray)
     }
     showPhoneNo=()=>{
        this.setState({
            phoneNo:'PHONE NO  0724800900'
        })  
     }
     pickUpStation=()=>{
      alert('Current the only accepeted mode of payment is via M-PESA')
     }
     homeDelivery=()=>{
        this.setState({
        delivery:'home'
      })  
    }
    timeOut=()=>{
        this.setState({ snackBar: true });
        setTimeout(() => {
            this.setState({ snackBar: false });
          }, 5000);
    }
    render() {
       // console.log(this.props.location.state.itemsArray)
        let itemsArray=this.props.location.state.itemsArray
        let subTotal=this.props.location.state.subTotal
        let theVAT=this.props.location.state.theVAT
        let theTotal=this.props.location.state.theTotal
        let deliveryFee=this.props.location.state.deliveryFee
        let grandTotal=this.props.location.state.grandTotal
        let selectColorActive=[]
        let selectColorInActive=[]
        let reviewStyle=''
        let adInfoStyle=''
       /* if (this.state.delivery==='home') {
            selectColorActive={
                backgroundColor:"#FF7C59",
                color:"#FF7C59"
            }
            selectColorInActive={
                backgroundColor:"#eee",
                color:"#eee"
            }
        }else if(this.state.delivery==='station'){
            selectColorActive={
                backgroundColor:"#eee",
                color:"#eee"
            }
            selectColorInActive={
                backgroundColor:"#FF7C59",
                color:"#FF7C59"
            }
        }*/
        

        const itemsList=itemsArray.map((item)=>{
            var thePrice=item.total.toLocaleString()
            return(
                <div className={style.productsList}>
                  <div className={style.imgDiv}>
                     <img  src={image} alt="NU-Africa"></img>
                 </div> 
                 <div className={style.proDetDiv}>
                  <p className={style.proTitleP}>{item.title}</p>
                 <p className={style.proTitleP2}>Ksh {thePrice}</p>
                  <p className={style.proTitleP3}>Qty: {item.quantity}</p>
                  </div>           
                </div>
            )
        })
        return (
            <div className={style.mainConainer}>
                <div  className={style.nav}>

                </div>
                <div className={style.container}>
                  <div className={style.contLeft}>
                   <h2 className={style.orderSum}>ORDER SUMMARY</h2>
                   <div className={style.contLeft1}>
                   <h3>ORDER | {itemsArray.length} ITEMS</h3> 
                       <div className={style.itemsCont}>
                          {itemsList}
                          <div className={style.calcDiv1}>
                             <div className={style.calcDiv2}>
                             <p className={style.subTotP}>Subtotal:</p>
                             <p className={style.vatP}>VAT:</p>
                             <p className={style.totalP}>Total:</p>
                             </div>
                             <div className={style.calcDiv3}>
                            <p className={style.subTotP}>Ksh {subTotal}</p>
                            <p className={style.vatP}>Ksh {theVAT}</p>
                            <p className={style.totalP}>Ksh {theTotal}</p>
                             </div>

                         </div>
                       </div>
                   </div>
                   <div className={style.contLeft2}>
                   <h2>NEED HELP?</h2>
                  <p>Contact Customer Care for help</p>
                  <div onClick={()=>this.showPhoneNo()}>
                 <h4>{this.state.phoneNo}</h4>
                  </div>
                   </div>
                  </div>
                  <div className={style.contRight}>
                  <h2>CHECKOUT DETAILS</h2>
                  <div className={style.contRight1}>
                  <div className={style.contRight2}>
                   <div className={style.contRight3A}>
                   <h3>1. ADDRESS DETAILS</h3> <h5>EDIT DETAILS</h5>
                   </div>
                   <div>
                       <p>Name: Customer Name</p>
                       <p>Location: Roasters, Nairobi Kenya</p>
                       <p>Phone No: 0788520369</p>
                   </div>
                  
                    <h3 className={style.deliverTitle}>2. DELIVERY METHOD</h3>
                    <div className={style.contRight3}>
                        <div className={style.selectorDiv} >
                        <IoMdCheckmark style={{color:'#fff', fontSize:25}}/> 
                        </div>
                        <h3>PickUp Station</h3>
                    </div>
                    <p className={style.contRight3P}>Ready for pick up between Thursday 12 Nov and Friday 13 Nov with cheaper shipping fees</p>
                    <div className={style.contLocationDiv}>
                      <p>County: Nairobi</p>
                      <p>Area: Roasters, Nairobi Kenya</p>
                      <p>Shipping Fee: Ksh {deliveryFee} </p>
                    </div>

                    <h3 className={style.deliverTitle}>3. PAYMENT METHOD</h3>
                    <h3 className={style.paymentTitle}>Choose method of payment for your order</h3>
                    <div className={style.contRight3}>
                        <div className={style.selectorDiv2} style={selectColorInActive} onClick={()=>this.pickUpStation()}>
                        <IoMdCheckmark style={{color:'#fff', fontSize:25}}/> 
                        </div>
                        <div className={style.mpesaDiv}>
                        <img  src={'./mpesa.png'} alt="NU-Africa"></img>
                         </div>
                        <h3>Pay with M-PESA</h3>
                    </div>
                    <div className={style.contRight4}>
                    <p>Pay now fast and securely</p>
                    <p>Before you proceed, please confirm you have enough money in your M-PESA Account.</p>
                    </div>
                    <div className={style.contRight3}>
                        <div className={style.selectorDiv2} style={{backgroundColor:'#eee'}} onClick={()=>this.pickUpStation()}>
                        <IoMdCheckmark style={{color:'#fff', fontSize:25}}/> 
                        </div>
                        <div className={style.visaDiv}>
                        <img  src={'./visa.png'} alt="NU-Africa"></img>
                         </div>
                        <h3>Pay with VISA/MASTERCARD</h3>
                    </div>
                    <div className={style.contRight4}>
                    <p>Pay now fast and securely</p>
                    <p>Before you proceed, please confirm you have enough money in your account.</p>
                    </div>
                   
                    <div className={style.calcDiv1B}>
                             <div className={style.calcDiv2}>
                             <p className={style.subTotP2}>Subtotal:</p>
                             <p className={style.vatP}>VAT:</p>
                             <p className={style.delP}>Delivery Fee:</p>
                             <p className={style.totalP}>Total:</p>
                             </div>
                             <div className={style.calcDiv3}>
                            <p className={style.subTotP}>Ksh {subTotal}</p>
                            <p className={style.vatP}>Ksh {theVAT}</p>
                            <p className={style.delP}>Ksh {deliveryFee}</p>
                            <p className={style.totalP}>Ksh {grandTotal}</p>
                             </div>

                         </div>
                    <NavLink to={{pathname:'/cartpay'}} className={style.contProceed}>
                    <h6>PROCEED TO NEXT STEP</h6>
                    </NavLink>
                  </div>
                  </div>
                      </div>
                </div>
                <div className={style.editProfile}>

        </div>
        <div onClick={()=>this.timeOut()}>Notify !</div>
        {this.state.snackBar?<SnackBar message="Added to cart Success"/>:null}
            </div>
        )
    }
}

export default CartFinish
