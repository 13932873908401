import React, { Component } from 'react'
import style from './Media.module.scss';
import {NavLink} from 'react-router-dom';
import firebase from 'firebase';
import ReactPlayer from 'react-player'
import {FaPlay} from 'react-icons/fa';
var theOrigiStyle1={backgroundColor:'#8d2855',color:'#fff',paddingTop:'7px',paddingBottom:'7px'}
var theOrigiStyle2={border:'2px solid #8d2855',color:'#8d2855'}

export class Media extends Component {
    state={
        details:[
           {id:1, title:'Education Support', path:'/education', details:'The Education Support program provides schools, teachers and students with the tools and support they need to succeed in the 21st Century.', 
           image:'https://images.pexels.com/photos/243989/pexels-photo-243989.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
           {id:2, title:'Social Entrepreneurship', path:'/social', details:'The world currently has the largest young population ever, The UN World Population estimates that in 2020 there will be 1.2 billion young people worldwide, with 21% of whom live in Africa. And these numbers will only keep growing by 2030.',
           image:'https://images.pexels.com/photos/2311713/pexels-photo-2311713.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
           {id:3, title:'Sports For Development', path:'/sports', details:'Sports for development collaborates with governmental and non-governmental partner organizations on promoting development perspectives for youth through sporting activities within schools and their communities',
           image:'https://images.pexels.com/photos/2067677/pexels-photo-2067677.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
           {id:4, title:'Nurturing Talent', path:'/talent', details:'Nu-Africa identifies and nurtures talent in perfoming arts, arts, sports, fashion, music, film and photography. Nurturing talent contributes to the Nu-Africa Pamoja Foundation vision to grow a pool of productive youth who will become the leaders of tomorrow.',
           image:'https://images.pexels.com/photos/1809390/pexels-photo-1809390.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
           {id:5, title:'Governance and Leadership', path:'/gorvenance', details:'Good governance and leadership is the pathway to sustainable development in Africa, and critical for the achievement of results. No community can develop beyond the level of its leadership. Underdevelopment and corruption can adduce to the problem of leadership and governance. Africa needs committed leaders who will govern with integrity and influence it human and natural resources toward the actualization of sustainable national development.',
           image:'https://images.pexels.com/photos/819335/pexels-photo-819335.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'},
           {id:6, title:'Nurturing Talent', path:'/talent', details:'Nu-Africa identifies and nurtures talent in perfoming arts, arts, sports, fashion, music, film and photography. Nurturing talent contributes to the Nu-Africa Pamoja Foundation vision to grow a pool of productive youth who will become the leaders of tomorrow.',
           image:'https://images.pexels.com/photos/949274/pexels-photo-949274.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
           {id:7, title:'Governance and Leadership', path:'/gorvenance', details:'Good governance and leadership is the pathway to sustainable development in Africa, and critical for the achievement of results. No community can develop beyond the level of its leadership. Underdevelopment and corruption can adduce to the problem of leadership and governance. Africa needs committed leaders who will govern with integrity and influence it human and natural resources toward the actualization of sustainable national development.',
           image:'https://images.pexels.com/photos/3656773/pexels-photo-3656773.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
           ],
           itemPosts:[], 
           theStyle1:{backgroundColor:'#8d2855',color:'#fff',paddingTop:'7px',paddingBottom:'7px'},
           theStyle2:{border:'2px solid #8d2855'},
           theStyle3:{border:'2px solid #8d2855'},
           theStyle4:{border:'2px solid #8d2855'},
           theStyle5:{border:'2px solid #8d2855'},
           container1:true,container2:false,container3:false,container4:false,container5:false,container4videoArray:[],
           currentId:''
   }
   componentDidMount() {
    window.scrollTo(0, 0)
    this.getData()
  }
  
   getData=async()=>{
    console.log('photossssssssssssssssssssssss')
       var itemsArr1=[],itemsArr2=[]
    const theRef = firebase.database().ref('/media/photo');
    await theRef.once('value', (snapshot) => {
        snapshot.forEach((data) => {
            const photo = data.val().photo
            itemsArr1={
                photo:photo
            }
             console.log('photo',photo)
            itemsArr2.push(itemsArr1)
            this.setState({itemPosts:itemsArr2})

        })
   })
}
showPhotos=()=>{
    this.setState({theStyle1:theOrigiStyle1,theStyle2:theOrigiStyle2,theStyle3:theOrigiStyle2,theStyle4:theOrigiStyle2,theStyle5:theOrigiStyle2,container1:true,container2:false,container3:false,container4:false,container5:false})
}

showVideos=()=>{
    this.fetchMusicVideos('music')
    this.setState({theStyle1:theOrigiStyle2,theStyle2:theOrigiStyle1,theStyle3:theOrigiStyle2,theStyle4:theOrigiStyle2,theStyle5:theOrigiStyle2,container1:false,container2:true,container3:false,container4:false,container5:false})
}
showDocs=()=>{
    this.fetchMusicVideos('doc')
    this.setState({theStyle1:theOrigiStyle2,theStyle2:theOrigiStyle2,theStyle3:theOrigiStyle1,theStyle4:theOrigiStyle2,theStyle5:theOrigiStyle2,container1:false,container2:false,container3:true,container4:false,container5:false})
}

showPods=()=>{
    this.fetchMusicVideos('pod')
    this.setState({theStyle1:theOrigiStyle2,theStyle2:theOrigiStyle2,theStyle3:theOrigiStyle2,theStyle4:theOrigiStyle1,theStyle5:theOrigiStyle2,container1:false,container2:false,container3:false,container4:true,container5:false})
}
showEvents=()=>{
    this.fetchMusicVideos('events')
    this.setState({theStyle1:theOrigiStyle2,theStyle2:theOrigiStyle2,theStyle3:theOrigiStyle2,theStyle4:theOrigiStyle2,theStyle5:theOrigiStyle1,container1:false,container2:false,container3:false,container4:false,container5:true})
}
fetchMusicVideos=async(subject)=>{
    var theRef=''
    try {
     theRef = firebase.database().ref('/media/'+subject);
      var array1=[]
      var array2=[]
     await theRef.once('value', (snapshot) => {
 
        snapshot.forEach((data) => {
            const name = data.val().name
            const title = data.val().title
            const video= data.val().video
            const thumbnail= data.val().thumbnail
            const id = data.key
            console.log("thumbnail", thumbnail)
            array1={
                name: name, video: video, id:id, title:title, thumbnail:thumbnail
            }
            array2.push(array1)
        })
       
       this.setState({
        videoArray:array2
       })
      })
      console.log('items', array2)
    } catch (error) {
      
    }
  }
  startvideo=(id)=>{
    console.log("id", id)
    this.setState({
        currentId:id
    })
    
  }
    render(){
        var videoList=''
        if (this.state.videoArray) {
         videoList=this.state.videoArray.map(item=>{
            let playerToShow=''
            if (item.video===this.state.currentId) {
                playerToShow=<ReactPlayer ref={(video) => { this.video = video }}
                className={style.listVidCont}
                width='100%'
                height='100%'
                playing={true}
                onPlay={console.log('ready to play')}
                controls url={item.video}
                />
            } else {
                playerToShow=<div className={style.reactPlayerDiv2} style={{backgroundImage:"url(" + item.thumbnail + ")"}}>
                <FaPlay className={style.playIC} onClick={()=>this.startvideo(item.video)}/>
             </div> 
            }
            return(
                <div  className={style.reactPlayerDiv}>
                    <div  className={style.reactPlayerDiv3} >
                    {playerToShow}
                    </div>
               
                <h5 className={style.artName}>{item.name}</h5> 
                <h5 className={style.artTitle}>{item.title}</h5>
                </div>
               
            )})}
        
        const detailsList=this.state.itemPosts.map(item=>{
            return(
                <div className={style.causedivzoom} key={item.id}>
                <img src={item.photo}/>
                </div>
            )
        })
        var theContToShow=''
        if (this.state.container1===true) {
            theContToShow=<div className={style.causesItemsCont}>{detailsList}</div>
        }
        if (this.state.container2===true) {
            theContToShow=<div className={style.causesItemsCont2}>{videoList}</div>
        }
        if (this.state.container3===true) {
            theContToShow=<div className={style.causesItemsCont2}>{videoList}</div>
        }
        if (this.state.container4===true) {
            theContToShow=<div className={style.causesItemsCont2}>{videoList}</div>
        }
        if (this.state.container5===true) {
            theContToShow=<div className={style.causesItemsCont2}>{videoList}</div>
        }
        
        return (
            <div className={style.container}>
                 <div className={style.statusBar}></div>
                <div className={style.headDiv}>
                <h2 className={style.headP1} style={this.state.theStyle1} onClick={()=>this.showPhotos()}>PHOTOS</h2>
                <h2 className={style.headP1}  style={this.state.theStyle2} onClick={()=>this.showVideos()}>MUSIC</h2>
                <h2 className={style.headP1} style={this.state.theStyle3} onClick={()=>this.showDocs()}>DOCUMENTARIES</h2>
                <h2 className={style.headP1}  style={this.state.theStyle4} onClick={()=>this.showPods()}>PODCASTS</h2>
                <h2 className={style.headP1}  style={this.state.theStyle5} onClick={()=>this.showEvents()}>EVENTS</h2>
                </div>
               
                {theContToShow}
            </div>
        )
    }
}

export default Media
