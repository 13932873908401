import React, { Component } from 'react'
import styles from './Education.module.scss';
import firebase from 'firebase';
import {FaFacebookF,FaRunning,FaSchool} from 'react-icons/fa';
import {MdClass,MdPeople,MdLocalPlay,MdGroupWork} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
let theImage='https://images.pexels.com/photos/1181607/pexels-photo-1181607.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
let details=[
  {id:1, title:'Education Support', path:'/education', details:'The Education Support program provides schools, teachers and students with the tools and support they need to succeed in the 21st Century.', 
  image:'https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/causes%2FNuap.jpg?alt=media&token=406e97c4-af06-409e-9931-a7d556af134b'},
  {id:2, title:'Social Entrepreneurship', path:'/social', details:'The world currently has the largest young population ever, The UN World Population estimates that in 2020 there will be 1.2 billion young people worldwide, with 21% of whom live in Africa. And these numbers will only keep growing by 2030.',
  image:'./photo17.jpg'},
  {id:3, title:'Sports For Development', path:'/sports', details:'Sports for development collaborates with governmental and non-governmental partner organizations on promoting development perspectives for youth through sporting activities within schools and their communities',
  image:'./photo20.jpg'},
  {id:5, title:'Nurturing Talent', path:'/talent', details:'Nu-Africa identifies and nurtures talent in perfoming arts, arts, sports, fashion, music, film and photography. Nurturing talent contributes to the Nu-Africa Pamoja Foundation vision to grow a pool of productive youth who will become the leaders of tomorrow.',
  image:'photo19.jpg'},
  {id:6, title:'Governance and Leadership', path:'/gorvenance', details:'Good governance and leadership is the pathway to sustainable development in Africa, and critical for the achievement of results. No community can develop beyond the level of its leadership. Underdevelopment and corruption can adduce to the problem of leadership and governance. Africa needs committed leaders who will govern with integrity and influence it human and natural resources toward the actualization of sustainable national development.',
  image:'./photo18.jpg'},
  ]
 class Gorvenance extends Component {
     state={
         infoArray:[]
     }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.fetchInfo()
      }
      fetchInfo=async()=>{
        var array=[]
        var array2=[]
      try {
       const theRef = firebase.database().ref('/nuapdep/miziki');
       await theRef.once('value', (snapshot) => {
         snapshot.forEach((data) => {
             const info = data.val()
             console.log('info',info)
             array={
                info:info
             }
             array2.push(array)
         })
     })
       this.setState({
        infoArray:array2
        })
       console.log('array2', array2)
     } catch (error) {
       
     }
   }
    render() {
        const infoList=this.state.infoArray.map(item=>{
             return(
                <div className={styles.infoList}>
                <p>{item.info}</p>
                </div>
                    
             )
         })
         const newPostsList=details.slice(0,4).map(item=>{
            return(
                <NavLink className={styles.newPostCont}  to={{pathname:item.path, state:item}}> 
                {/*<div className={style.imgCont2} style={{backgroundImage:"url(" + theImage + ")"}}>*/}
                <div className={styles.imgCont2} style={{backgroundImage:"url(" + item.image + ")"}}>
                </div>
                <div className={styles.newPostCont2}>
                    <h2>{item.title}</h2>
                    <p>SEE MORE...</p>
                </div>
            </NavLink>
            )
        })
        return (
            <div className={styles.container}>
               <div className={styles.container1} style={{backgroundImage:"url(" + './photo18.jpg' + ")"}}>
                <div className={styles.container1B}>
                    <div className={styles.container1Det}>
                <p className={styles.title}>GOVERNANCE AND LEADERSHIP</p>
                <p className={styles.details}>Donate to Governance and Leadership</p>
                <p className={styles.details2}>Good governance and leadership is the pathway to sustainable development in Africa, and critical for the achievement of results</p>
                <div className={styles.contDonateMain}>
                <NavLink to='/donate2' className={styles.contDonate}> 
                <p className={styles.contDonateP}>DONATE NOW</p>
                </NavLink>
                 <NavLink to='/contacts' className={styles.contDonate1}> 
                <p className={styles.contDonateP}>ENROLL</p>
                </NavLink>
                </div>
                
                       </div> </div>
                </div>
                <div className={styles.container21}>
                <div className={styles.container2}>
                 <h2>HOW THE PROGRAMME WORKS</h2>
                 <p>We equip the youth and foster critical thinking and questioning of those in power. The youth must be empowered take charge of their future and actively take a positive role in shaping the future of the African continent.</p>
                 <p>The youth have a duty to demand accountability and ethical conduct from the political leadership, reject permeance of corruption and violence in electoral processes and take leadership roles. </p>
                 <p>Through our POWER programme, we prompt the youth to interrogate their role in creating Africa’s future and encourage them to take personal and individual steps towards creating the Africa they envision.</p>
                 <p>We reach the youth even in their early teens with the aim of establishing the foundation for accountable, moral, ethical and community focused leadership for the future.</p>
                 <p>We partner with other like-minded organisations and persons to reach, educate and engage the youth through talks, seminars, social activities, sports, Arts and through social media platforms. </p>
                </div>
                <div className={styles.container2B}>
                <div  className={styles.contRight1}>
               <h1>OTHER CAUSES</h1>
                {newPostsList}
               </div>
                </div>
                </div>
                <div className={styles.container3A}>
                <div className={styles.container3}>
                <div className={styles.div1}>
                 <h3 className={styles.det1}>Only 14.8%</h3>
                 <h4 className={styles.det2}>MINISTERIAL SEATS</h4>
                 <h5 className={styles.det3}>are occupied by women</h5>
                   </div>
                <div className={styles.div2}>
                <h3 className={styles.det1}>Upto 60%</h3>
                 <h4 className={styles.det2}>OF AFRICAN POPULATION</h4>
                 <h5 className={styles.det3}>is aged below 35 Yrs</h5>
                </div>
                <div className={styles.div3}>
                <h3 className={styles.det1}>Only 1.5%</h3>
                 <h4 className={styles.det2}>OF AFRICA PARLIAMENTARIANS</h4>
                 <h5 className={styles.det3}>are under 30 Yrs</h5>
                </div>
                </div>
                <p className={styles.infoP}>info: undp.org, minds-Africa.org</p>
                </div>
                <div className={styles.container50}>
                <h2>HOW WE PROVIDE GOVERNANCE AND LEADERSHIP </h2>
                <div className={styles.container5}>
                    <div className={styles.container6}>
                      <div className={styles.supportDiv}>
                          <MdClass className={styles.supportIc}/>
                          <div>
                            <h3>Think Tanks</h3>
                            <p>Youth Participation on goverance and leadership, law making processes, Political issues</p>
                          </div>
                      </div>
                      <div className={styles.supportDiv}>
                          <MdGroupWork className={styles.supportIc2}/>
                          <div>
                            <h3>Seminars and Workshops</h3>
                            <p>The youth must be empowered take charge of their future</p>
                          </div>
                      </div>
                      <div className={styles.supportDiv}>
                          <FaSchool className={styles.supportIc}/>
                          <div>
                            <h3>School Programs</h3>
                            <p>Programmes implemented to enlighten, encourage and facilitate active participation in electoral processes</p>
                          </div>
                      </div>
                    </div>
                    <div className={styles.container7}>
                 <h4>HOW WE HELP PROVIDE EDUCATION SUPPORT</h4>
                 <p>We equip the youth and foster critical thinking and questioning of those in power. The youth must be empowered take charge of their future and actively take a positive role in shaping the future of the African continent.</p>
                 <p>We believe that the youth are an enormous resource that need to be harnessed to drive change in Africa. The core of our mission is love. We encourage the youth to love others despite existence cultural, religious, racial, gender or other differences, and in this way build strong communities. </p>
                 <p>Programmes implemented to enlighten, encourage and facilitate active participation in electoral processes, leadership and governance by the youth.</p>        
                    </div>
                    </div>
                </div>
                <div className={styles.container8}>
                <h4>Have Questions About NU-Africa and How We Work?</h4>
                 <p>Donating is an important decision. So when you’re passionate about a cause and want to make a difference, we invite you to partner with us. NUAfrica is 100% committed to financial integrity and stewardship. If you have any questions about NUAfrica or exactly how your donation will be used, please don’t hesitate to contact us.</p>      
                </div>
                
            </div>
        )
    }
}

export default Gorvenance
