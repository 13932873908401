import React, { Component } from 'react'
import './Footer2.scss';
import { FaMapMarkerAlt,FaPhone,FaStore,FaHome,FaStar,FaAudioDescription } from 'react-icons/fa';
import { ImBubbles2 } from "react-icons/im";
import { IoMdMail } from "react-icons/io";
import {NavLink} from 'react-router-dom';

class Footer extends Component {
    state={
        selectedItem:''
        
    }
    listItemsClick=(name)=>{
        this.setState({
            selectedItem:name
        })
     //alert(name)
    }
    render() {
        const itemSelected=this.state.selectedItem;
        let homeTheme,chatTheme,favTheme,adsTheme,accountTheme;
       
        
        if(itemSelected===''||itemSelected==='home'){
         homeTheme= <div className='homeContBottonNav'>
         <FaHome style={{color:"red"}} className='bottomNavIcon'/>
         <p style={{color:"red"}}>Home</p>
       </div>
        }else{
            homeTheme= <div className='homeContBottonNav'>
            <FaHome  className='bottomNavIcon'/>
            <p >Home</p>
          </div>
        }
           /* if(itemSelected==='chats'){ chatTheme= <div className='chatContBottonNav'>
            <ImBubbles2 style={{color:"red"}} className='bottomNavIcon'/> <p style={{color:"red", size:10}}>Search</p> </div>}else{
            chatTheme= <div className='chatContBottonNav'><ImBubbles2  className='bottomNavIcon' /><p >Search</p></div> }*/
            if(itemSelected==='favourites'){ favTheme= <div className='favContBottonNav'>
            <FaStar style={{color:"red"}} className='bottomNavIcon'/> <p style={{color:"red"}}>Favourites</p> </div>}else{
            favTheme= <div className='favContBottonNav'><FaStar  className='bottomNavIcon' /><p >Favourites</p></div> }
            if(itemSelected==='orders'){ adsTheme= <div className='adsContBottonNav'>
            <FaAudioDescription style={{color:"red"}} className='bottomNavIcon'/> <p style={{color:"red"}}>Orders</p> </div>}else{
            adsTheme= <div className='adsContBottonNav'><FaAudioDescription  className='bottomNavIcon' /><p >Orders</p></div> }
            if(itemSelected==='account'){ accountTheme= <div className='accountContBottonNav'>
            <FaStore style={{color:"red"}} className='bottomNavIcon'/> <p style={{color:"red"}}>Account</p> </div>}else{
            accountTheme= <div className='accountContBottonNav'><FaStore  className='bottomNavIcon' /><p >Account</p></div> }
        return (
            <div className='mainCont'>
            <div className='shopFooterDiv'>
                <div className='shopFooterDivs'>
                 <h1 className='divCentreHead1' style={{margin:0, fontSize:25, fontWeight:'700', color:"#fff"}}>About Us</h1>
                 <p className='divCentreHead' style={{marginTop:15, fontSize:18, lineHeight:1.5,color:"#fff"}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incid- idunt ut labore et dolore magna aliqua.</p>
                 <div className='aboutUsDiv' style={{display:'flex', flexDirection:'row', marginTop:30}}>
                     <FaMapMarkerAlt size={15} color="#fff"/>
                     <h5 style={{marginTop:-2, marginLeft:10,color:"#fff", fontSize:16}}>Kahawa Wendani, Nairobi Kenya</h5>
                 </div>
                 <div className='aboutUsDiv' style={{display:'flex', flexDirection:'row', marginTop:20}}>
                     <FaPhone size={15} color="#fff"/>
                     <h5 style={{marginTop:-2, marginLeft:10,color:"#fff",fontSize:16}}>Phone: +254 729 546 987</h5>
                 </div>
                 <div className='aboutUsDiv' style={{display:'flex', flexDirection:'row',marginTop:20}}>
                     <IoMdMail size={15} color="#fff"/>
                     <h5 style={{marginTop:-2, marginLeft:10,color:"#fff",fontSize:16}}>Email: nuafricapamoja@gmail.com</h5>
                 </div>
                </div>
                <div className='shopFooterDivs'>
                <h1 className='divCentreHead1' style={{margin:0, fontSize:25, fontWeight:'700', color:"#fff", marginLeft:20}}>Quick Links</h1>
                    <ul className='ulFooter'>
                        <li><NavLink to='/' className='FooterLi'>Home</NavLink></li>
                        <li><NavLink to='/aboutUs' className='FooterLi'>About Us</NavLink></li>
                        <li><NavLink to='/contacts' className='FooterLi'>Contacts</NavLink></li>
                        <li><NavLink to='/terms' className='FooterLi'>Terms and Conditions</NavLink></li>
                        <li><NavLink to='/privacy' className='FooterLi'>Privacy Policy</NavLink></li>
                    </ul>
                      
                 </div>
                <div className='shopFooterDivs'>
                <h1 className='contactUs' >Contact Us</h1>
               
                <input className='contactsInput' placeholder='Name' type='text' id='lastName'></input>
                <input className='contactsInput' placeholder='E-mail' type='text' id='lastName'></input>
                <input className='contactsInput' placeholder='Message' type='text' id='lastName'></input>
                <div className='footerSendDiv'>
                       <p   style={{fontSize:20, color:'#fff', fontWeight:'700', margin:0}}>Send</p>
                       </div>
                
                 </div>
                
            </div>
            <div className='lineDiv'>
            <p style={{margin:0, fontSize:20, fontWeight:'500', color:"#fff", marginTop:30}}>Copyright &copy;2022 Nu-Africa Pamoja Foundation</p>
            </div>
            <ul className='bottomNavUl'>
           {/* itemSelected!='home'?alert('not home'):alert('it is home')*/}
               <li onClick={()=>this.listItemsClick('home')}>
               <NavLink to='/' style={{textDecoration:'none', color:'#000'}}>
                {homeTheme}</NavLink>
               </li>
               {/*<NavLink to='/chats' style={{textDecoration:'none', color:'#000',flex:1, display:"flex" }}>
                   <li onClick={()=>this.listItemsClick('chats')}>
                 {chatTheme}
               </li></NavLink>*/}
               <NavLink to={{pathname:'/myaccount', state:'savedItems'}} style={{textDecoration:'none', color:'#000',flex:1, display:"flex" }}><li onClick={()=>this.listItemsClick('favourites')}>
                {favTheme}
               </li></NavLink>
               <NavLink to={{pathname:'/myaccount', state:'delivered'}} style={{textDecoration:'none', color:'#000',flex:1, display:"flex" }}><li onClick={()=>this.listItemsClick('orders')}>
                {adsTheme}
               </li></NavLink>
               <NavLink to={{pathname:'/myaccount', state:'profileInfo'}}  style={{textDecoration:'none', color:'#000',flex:1, display:"flex" }}><li onClick={()=>this.listItemsClick('account')}>
                {accountTheme}
               </li></NavLink>
               
           </ul>
            </div>
        )
    }
}

export default Footer
