import React, { Component } from 'react'

 class Register extends Component {
    render() {
        return (
            <div style={{width:'100%', height:600}}>
                Register
            </div>
        )
    }
}

export default Register
