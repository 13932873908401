import React, { Component } from 'react'
import './Footer.scss';
import { FaMapMarkerAlt,FaPhone } from 'react-icons/fa';
import { IoMdMail } from "react-icons/io";
import {NavLink} from 'react-router-dom';
import firebase from 'firebase';
import SnackBar  from './../Helper/SnackBar'

class Footer extends Component {
    state={
        name:'',
        email:'',
        message:'',
        showSnackBar:false,
        value:'Others',
        snackMeso:'All fields must be filled'
    }
    inputChange(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({[event.target.id]:event.target.value});
      }
      sendMessage=async()=>{
        var time = Date.now();
        const theRef = firebase.database().ref('/notifications/contacts');
        //const theRef2 = firebase.database().ref('/notifications/reachus');
          console.log('name', this.state.name)
         
          console.log('message', this.state.message)
          if (this.state.name===''||this.state.email===''||this.state.message==='') {
            this.setState({ showSnackBar: true,snackMeso:'All fields must be filled' })
            await setTimeout(
               () => this.setState({ showSnackBar: false }), 
               3000
             )
            return
          }else  if (this.state.name!=''&&this.state.email!=''&&this.state.message!='') {
            this.setState({ showSnackBar: true,snackMeso:'Message uploaded successfully'})
            var theMeso={
                name:this.state.name,
                email:this.state.email,
                message:this.state.message,
                subject:'Contact Us',
                time:time,
                status:'send'
            }
            console.log('theMeso', theMeso)
            theRef.push(theMeso)
            await setTimeout(
               () => this.setState({ showSnackBar: false }), 
               3000
             )
          }
      }
    render() {
        return (
            <div style={{backgroundColor:'#301a22', paddingBottom:50}}>
            <div className='footerDiv'>
                <div className='footerDivs'>
                 <h1 className='divCentreHead1' style={{margin:0, fontSize:25, fontWeight:'700', color:"#fff"}}>About Us</h1>
                 <p className='divCentreHead' style={{marginTop:15, fontSize:16, lineHeight:1.5,color:"#fff"}}>NU Africa Pamoja Foundation is an organization empowering Africa through Arts, performing Arts, fashion, sports, music, film and photography.</p>
                 <div className='aboutUsDivB' style={{display:'flex', flexDirection:'row', marginTop:10}}>
                     <FaMapMarkerAlt size={15} color="#fff"/>
                     <h5 style={{marginTop:-2, marginLeft:10,color:"#fff", fontSize:14}}>Nairobi, Kenya</h5>
                 </div>
                 <div className='aboutUsDivB' style={{display:'flex', flexDirection:'row', marginTop:10}}>
                     <FaPhone size={15} color="#fff"/>
                     <h5 style={{marginTop:-2, marginLeft:10,color:"#fff",fontSize:14}}>Phone: 0705 059 188 / 0794 301 186</h5>
                 </div>
                 <div className='aboutUsDivB' style={{display:'flex', flexDirection:'row',marginTop:10}}>
                     <IoMdMail size={15} color="#fff"/>
                     <h5 style={{marginTop:-2, marginLeft:10,color:"#fff",fontSize:14}}>Email: info@nuafricapamoja.org</h5>
                 </div>
                </div>
                <div className='footerDivs'>
                <h1 className='divCentreHead1' style={{margin:0, fontSize:25, fontWeight:'700', color:"#fff", marginLeft:20}}>Quick Links</h1>
                    <ul className='ulFooter'>
                        <li><NavLink to='/' className='FooterLi'>Home</NavLink></li>
                        <li><NavLink to='/aboutUs' className='FooterLi'>About Us</NavLink></li>
                        <li><NavLink to='/contacts' className='FooterLi'>Contacts</NavLink></li>
                        <li><NavLink to='/terms' className='FooterLi'>Terms and Conditions</NavLink></li>
                        <li><NavLink to='/privacy' className='FooterLi'>Privacy Policy</NavLink></li>
                      {/*<li><NavLink to='/dashboard' className='FooterLi'>Dashboard</NavLink></li>*/}
                    </ul>
                      
                 </div>
                <div className='footerDivs'>
                <h1 className='contactUs' >Contact Us</h1>
               
                <input className='contactsInput' placeholder='Name' type='text' id='name' style={{color:'#fff'}} onChange={(event)=>this.inputChange(event)}></input>
                <input className='contactsInput' placeholder='E-mail' type='text' id='email' style={{color:'#fff'}} onChange={(event)=>this.inputChange(event)}></input>
                <input className='contactsInput' placeholder='Message' type='text' id='message' style={{color:'#fff'}} onChange={(event)=>this.inputChange(event)}></input>
                <div className='footerSendDiv' onClick={()=>this.sendMessage()}>
                       <p   style={{fontSize:20, color:'#fff', fontWeight:'700', margin:0}}>Send</p>
                       </div>
                
                 </div>
                
            </div>
            <div className='lineDiv'>
            <p style={{margin:0, fontSize:18, fontWeight:'500', color:"#fff", marginTop:30,textAlign:'center'}}> &copy; 2023 Nu-Africa Pamoja Foundation</p>
            <p style={{fontSize:14, fontWeight:'400', color:"#fff", marginTop:5,textAlign:'center'}}>Designed By Clemo 0724998745</p>
            </div>
            {this.state.showSnackBar?<SnackBar message={this.state.snackMeso}/>:null}
            </div>
        )
    }
}

export default Footer
