import React, { Component } from 'react'
import styles from './Education.module.scss';
import firebase from 'firebase';
import {FaFacebookF,FaRunning,FaNetworkWired} from 'react-icons/fa';
import {MdClass,MdPeople,MdLocalPlay,MdBusinessCenter} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
let theImage='https://images.pexels.com/photos/3762806/pexels-photo-3762806.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
let details=[
  {id:1, title:'Education Support', path:'/education', details:'The Education Support program provides schools, teachers and students with the tools and support they need to succeed in the 21st Century.', 
  image:'https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/causes%2FNuap.jpg?alt=media&token=406e97c4-af06-409e-9931-a7d556af134b'},
  {id:2, title:'Social Entrepreneurship', path:'/social', details:'The world currently has the largest young population ever, The UN World Population estimates that in 2020 there will be 1.2 billion young people worldwide, with 21% of whom live in Africa. And these numbers will only keep growing by 2030.',
  image:'./photo17.jpg'},
  {id:3, title:'Sports For Development', path:'/sports', details:'Sports for development collaborates with governmental and non-governmental partner organizations on promoting development perspectives for youth through sporting activities within schools and their communities',
  image:'./photo20.jpg'},
  {id:5, title:'Nurturing Talent', path:'/talent', details:'Nu-Africa identifies and nurtures talent in perfoming arts, arts, sports, fashion, music, film and photography. Nurturing talent contributes to the Nu-Africa Pamoja Foundation vision to grow a pool of productive youth who will become the leaders of tomorrow.',
  image:'photo19.jpg'},
  {id:6, title:'Governance and Leadership', path:'/gorvenance', details:'Good governance and leadership is the pathway to sustainable development in Africa, and critical for the achievement of results. No community can develop beyond the level of its leadership. Underdevelopment and corruption can adduce to the problem of leadership and governance. Africa needs committed leaders who will govern with integrity and influence it human and natural resources toward the actualization of sustainable national development.',
  image:'./photo18.jpg'},
  ]
 class Social extends Component {
     state={
         infoArray:[]
     }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.fetchInfo()
      }
      fetchInfo=async()=>{
        var array=[]
        var array2=[]
      try {
       const theRef = firebase.database().ref('/nuapdep/miziki');
       await theRef.once('value', (snapshot) => {
         snapshot.forEach((data) => {
             const info = data.val()
             console.log('info',info)
             array={
                info:info
             }
             array2.push(array)
         })
     })
       this.setState({
        infoArray:array2
        })
       console.log('array2', array2)
     } catch (error) {
       
     }
   }
    render() {
        const infoList=this.state.infoArray.map(item=>{
             return(
                <div className={styles.infoList}>
                <p>{item.info}</p>
                </div>
                    
             )
         })
         const newPostsList=details.slice(0,4).map(item=>{
            return(
                <NavLink className={styles.newPostCont}  to={{pathname:item.path, state:item}}> 
                {/*<div className={style.imgCont2} style={{backgroundImage:"url(" + theImage + ")"}}>*/}
                <div className={styles.imgCont2} style={{backgroundImage:"url(" + item.image + ")"}}>
                </div>
                <div className={styles.newPostCont2}>
                    <h2>{item.title}</h2>
                    <p>SEE MORE...</p>
                </div>
            </NavLink>
            )
        })
        return (
            <div className={styles.container}>
                 <div className={styles.container1} style={{backgroundImage:"url(" + './photo17.jpg' + ")"}}>
                <div className={styles.container1B}>
                    <div className={styles.container1Det}>
                <p className={styles.title}>Social Entrepreneurship</p>
                <p className={styles.details}>Donate to Social Entrepreneurship</p>
                <p className={styles.details2}>NU-Africa Pamoja foundation equips the youth to develop skills in business innovation and risk taking approach to create scalable solutions on new ideas for wide-scale social and environment impact. </p>
                <div className={styles.contDonateMain}>
             
                <NavLink to='/donate2' className={styles.contDonate}> 
                <p className={styles.contDonateP}>DONATE NOW</p>
                </NavLink>
                <NavLink to='/contacts' className={styles.contDonate1}> 
                <p className={styles.contDonateP}>ENROLL</p>
                </NavLink>
                       
                </div>
                
                       </div> </div>
                </div>
                <div className={styles.container21}>
                <div className={styles.container2}>
                 <h2>HOW THE PROGRAMME WORKS</h2>
                 <p>The world currently has the largest young population ever, The UN World Population estimates indicate that in 2020 there were 1.2 billion young people worldwide, with 21% of whom live in Africa. These numbers will only keep growing by 2030.</p>
                 <p>Africa continues to face some pretty overwhelming problems, and conventional methods simply aren’t sufficient. Unemployment is a key contributor towards hopelessness among the youth. A cost-effective and self- governed community approach provide a pathway, tools and belief for marginalized youth to shape a vision for their future and create new solutions in forms of social enterprises with benefits to the communities.</p>
                 <p>NU-Africa Pamoja foundation thanks its substantial donors, partners and companies who partner with us to champion social ventures that empower the youth to face risk- taking social entrepreneurship. This is through creation of self-organized and accountable learning environment, where disadvantaged youth and achieve their potential for positive change. </p>
                 <p>NU-Africa Pamoja foundation equips the youth to develop skills in business innovation and risk taking approach to create scalable solutions on new ideas for wide-scale social and environment impact. </p>
                 <p>Art, performing arts, sports, fashion, music, film and photography are a key catalyst towards social entrepreneurship, a large portion of our community is marginalized because of lack of support and inadequate skills conducive to wellbeing and creativity. </p>
                 
                </div>
                <div className={styles.container2B}>
                <div  className={styles.contRight1}>
               <h1>OTHER CAUSES</h1>
                {newPostsList}
               </div>
                </div>
                </div> <div className={styles.container3A}>
                {/*<p className={styles.infoP}>Youth unemployment rate in Africa</p>*/}
                <div className={styles.container3}>
                <div className={styles.div1}>
                 <h3 className={styles.det1}>Only 10.9%</h3>
                 <h4 className={styles.det2}>of youth of working age are unemployed as of Dec 2017</h4>
        
                   </div>
                <div className={styles.div2}>
                <h3 className={styles.det1}>Only 10.8%</h3>
                 <h4 className={styles.det2}>of youth of working age are unemployed as of Dec 2018</h4>
                 
                </div>
                <div className={styles.div3}>
                <h3 className={styles.det1}>Only 10.7%</h3>
                 <h4 className={styles.det2}>of youth of working age are unemployed as of Dec 2019</h4>
                 
                </div>
                </div> <p className={styles.infoP}>info: International Labour Organization</p>
                </div>
                <div className={styles.container50}>
                <h2>HOW WE HELP PROVIDE SOCIAL ENTREPRENUERSHIP</h2>
                <div className={styles.container5}>
                    <div className={styles.container6}>
                      <div className={styles.supportDiv}>
                          <FaNetworkWired className={styles.supportIc}/>
                          <div>
                            <h3>Workshops, Training and Seminors</h3>
                            <p>NUAfrica in partnership with our sponsors provides workshops, tranings and Seminors</p>
                          </div>
                      </div>
                      <div className={styles.supportDiv}>
                          <MdClass className={styles.supportIc}/>
                          <div>
                            <h3>Masterclasses on Selecetive Areas</h3>
                            <p>Provide masterclasses for gifted youth to improve their skills within the various areas.</p>
                          </div>
                      </div>
                      <div className={styles.supportDiv}>
                          <MdBusinessCenter className={styles.supportIc}/>
                          <div>
                            <h3>Business Marketing &amp; Networking</h3>
                            <p>
                            NUAhub provide a center for the youth to engage with each other on various ways
                            </p>
                          </div>
                      </div>
                    </div>
                    <div className={styles.container7}>
                 <h4>HOW WE HELP PROVIDE SOCIAL ENTREPRENUERSHIP</h4>
                 <p>NUAfrica in partnership with our sponsors provides workshops, tranings and Seminors for the youth at the NUAhub and virtually on social media. The youth get to learn how to turn their enterprinourship skills into profitable business and improve their life and communuty around them.</p>
                 <p>Arts, performing arts, sports, fashion, music film and photography are vital areas within the youth to express themselves and make a living from their passion. Various successful individuals and organisation provide masterclasses for gifted youth to improve their skills within the above areas.</p>
                 <p>NUAhub provide a center for the youth to engage with each other on various ways, show case the various items from Arts, sports, fashion, music, film and photography. The hub provides a open market for the youth to showcase, buy and sell various items to our vast network and online community. During this pandemic various businesses by the youth have had to close down randering them jobless, depressed and prone to drug and substance abuse.</p>        
                    </div>
                    </div>
                </div>
                <div className={styles.container8}>
                <h4>Have Questions About NU-Africa and How We Work?</h4>
                 <p>Donating is an important decision. So when you’re passionate about a cause and want to make a difference, we invite you to partner with us. NUAfrica is 100% committed to financial integrity and stewardship. If you have any questions about NUAfrica or exactly how your donation will be used, please don’t hesitate to contact us.</p>      
                </div>
                
            </div>
        )
    }
}

export default Social
