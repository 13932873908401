import React, { Component } from 'react'
import style from './HomeCauses.module.scss';
import {NavLink} from 'react-router-dom';

class HomeCauses extends Component {
    state={
         details:[
            {id:1, title:'Education Support', path:'/education', details:'The Education Support program provides schools, teachers and students with the tools and support they need to succeed in the 21st Century.', 
            image:'https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/causes%2FNuap.jpg?alt=media&token=406e97c4-af06-409e-9931-a7d556af134b'},
            {id:2, title:'Social Entrepreneurship', path:'/social', details:'The world currently has the largest young population ever, The UN World Population estimates that in 2020 there will be 1.2 billion young people worldwide, with 21% of whom live in Africa. And these numbers will only keep growing by 2030.',
            image:'./photo17.jpg'},
            {id:3, title:'Sports For Development', path:'/sports', details:'Sports for development collaborates with governmental and non-governmental partner organizations on promoting development perspectives for youth through sporting activities within schools and their communities',
            image:'./photo20.jpg'},
            {id:5, title:'Nurturing Talent', path:'/talent', details:'Nu-Africa identifies and nurtures talent in perfoming arts, arts, sports, fashion, music, film and photography. Nurturing talent contributes to the Nu-Africa Pamoja Foundation vision to grow a pool of productive youth who will become the leaders of tomorrow.',
            image:'photo19.jpg'},
            {id:6, title:'Governance and Leadership', path:'/gorvenance', details:'Good governance and leadership is the pathway to sustainable development in Africa, and critical for the achievement of results. No community can develop beyond the level of its leadership. Underdevelopment and corruption can adduce to the problem of leadership and governance. Africa needs committed leaders who will govern with integrity and influence it human and natural resources toward the actualization of sustainable national development.',
            image:'./photo18.jpg'},
            ]
    }
    render() {
        const detailsList=this.state.details.map(item=>{
            return(
               /*<NavLink to={{pathname:'/causes', state:item}}className={style.causedivzoom} key={item.id}>*/
                <NavLink to={{pathname:item.path, state:item}}className={style.causedivzoom} key={item.id}>
                <img src={item.image}/>
                <h1>{item.title}</h1>
                <p>{item.details}</p>
                 <div className={style.donateDiv3}>
                       <p>How To Help?</p>
                       </div>
                </NavLink>
            )
        })
        return (
            <div  className={style.container01}>
            <div className={style.container}>
                <div className={style.causesDetCont}>
                    <h1>Our Causes</h1>
                    <p>We aim to transform Communities</p>
                </div>
                <div className={style.causesItemsCont}>
                 {detailsList}
                </div>
            </div>
            </div>
        )
    }
}

export default HomeCauses
