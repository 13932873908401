import React, { Component } from 'react'
import style from './Sell.module.scss';
import firebase from 'firebase';
import { MdClose, MdKeyboardArrowDown,MdKeyboardArrowLeft } from "react-icons/md";
let photoFiles=[]
var photoFiles2=[]
var newPosts2={}
var filesArray=[]
var theStuff=[]
var snackMessage =''


class Sell extends Component {
    state={
        imageArray2:[],
        thePrimaryImage:'', 
        imageArray2:[],
        catArray:[],
        catModal:false,
        subCatModal:false,
        catTitle:'',
        subCatTitle:'',
        showSubCat:false
    }
    componentDidMount(){
       
    }
    clickCat=async()=>{
        this.setState({catArray:[]})
        const theRef = firebase.database().ref('/cat/');
        var catArray=[]
        var catArray2=[]
       await theRef.once('value', (snapshot) => {
          snapshot.forEach((data) => {
              const id = data.key
              catArray={
                name: id
              }
              catArray2.push(catArray)
              this.setState({catArray:catArray2})
          })
    })}
    clickSubCat=async()=>{
        this.setState({catArray:[]})
        const theRef = firebase.database().ref('/subCat/clothing');
        var catArray=[]
        var catArray2=[]
       await theRef.once('value', (snapshot) => {
          snapshot.forEach((data) => {
              const id = data.key
              catArray={
                name: id
              }
              catArray2.push(catArray)
              this.setState({catArray:catArray2})
          })
    })}
    handleInputChange=async(event, type) =>{
        this.event(event)
        var theId=event.target.id;
        var theValue=event.target.value;
        let {prInfoState} = this.state;
    
      }
      handleChangeFinal =async(event) =>{
        this.setState({thePhotosToUpload:event.target.files, imagesSelectedNo:event.target.files.length})
        console.log(event.target.files)
       
        if (event.target.files) {
            photoFiles=Array.from(event.target.files);
            
            console.log(photoFiles)
            console.log(event.target.files[0])
        
        await  photoFiles.map((file) => {
            
            
                console.log(file)
                console.log(URL.createObjectURL(file))
                 var theUrl=URL.createObjectURL(file)
                newPosts2={
                    theFile:file, theUrl:URL.createObjectURL(file), name:file.name
                }
                if (!filesArray.includes(file.name)){
                    filesArray.push(file.name)
                    photoFiles2.push(newPosts2)
                }
              });
             
             
             
           if (photoFiles2.length>5) {
            snackMessage ='Maximum number of photos per AD is five';
            this.setState({ showSnackBar: true })
            this.timerHandle = setTimeout(
           () => this.setState({ showSnackBar: false }), 
           3000)
           }
           photoFiles2=photoFiles2.slice(-5)
           filesArray=filesArray.slice(-5)
           await this.setState({ imageArray2 : photoFiles2 })
           theStuff=this.state.imageArray2
           console.log('localStorage',theStuff)
           
            }
    }
    thePrimaryImage=async(thefile,id)=>{
        this.setState({thePrimaryImage:thefile})
        console.log('index',index)
        var index = this.state.imageArray2.findIndex(x => x.theUrl ===id);
        Array.prototype.move = function(from,to){
            this.splice(to,0,this.splice(from,1)[0]);
            return this;
          };
        var newArray2=  this.state.imageArray2.move(index,0)

        await   this.setState({  imageArray2 : newArray2})
        theStuff=this.state.imageArray2
        photoFiles2=newArray2
        console.log('position', this.state.theIndex)
        
      }
      deleteImage=async(thefile,id,name)=>{
          if (name==='fromDb') {
              alert('this is from database')
              return
          }else{
          if (thefile===this.state.thePrimaryImage) {
            this.setState({thePrimaryImage:''})
          }
        //var arr=  this.state.imageArray2
        const filteredItems = this.state.imageArray2.filter(item => item.theUrl !== id)
        const filteredFilesArray = filesArray.filter(item => item !== name)
        filesArray=filteredFilesArray
        console.log('filesArray',filteredFilesArray)
        console.log('photoFiles2',filteredItems)
       // const filteredItems2 = this.state.imageArray.filter(item => item !== id)
        await   this.setState({ imageArray2 : filteredItems})
        theStuff=this.state.imageArray2
        photoFiles2=filteredItems}
      }
      getCat=async(event,name)=>{
        event.preventDefault();
        event.stopPropagation();
          this.setState({ catTitle : name, catModal:false,subCatModal:false})
          if (name==='Clothing') {
            this.setState({showSubCat:true})
          }else{
            this.setState({showSubCat:false})
          }

      }
      getSubCat=async(event,name)=>{
        event.preventDefault();
        event.stopPropagation();
        this.setState({ subCatTitle : name, catModal:false,subCatModal:false})

    }
      openCatModal=async(event)=>{
        event.preventDefault();
        event.stopPropagation();
        this.clickCat()
        this.setState({ catModal:true,subCatModal:false,subCatTitle : ''})
    }
    openSubModal=async(event)=>{
        event.preventDefault();
        event.stopPropagation();
        this.clickSubCat()
        this.setState({ catModal:false,subCatModal:true})
     }
     closeModal=async(event)=>{
        event.preventDefault();
        event.stopPropagation();
       // this.setState({ catModal:false,subCatModal:false})
     }

    render() {
        let showPrimary=''
        let photosList=''
        let localPhotos=this.state.imageArray2.slice(-5);
        let theCatList=this.state.catArray
        if(localPhotos){
            photosList=localPhotos.map((item, index)=>{
               if(item===localPhotos[0]){
                   showPrimary=
                   <div className={style.photoInfoDiv}>
                   <div id={style.photoDel} onClick={()=>this.deleteImage(item.theFile,item.theUrl,item.name)}>
                   <h5>Delete</h5>
                   </div>
                   <h4>Primary</h4>
                   </div>
               }else{
                   showPrimary= <div className={style.photoInfoDiv}>
                   <div id={style.photoPro} onClick={()=>this.thePrimaryImage(item.theFile,item.theUrl)}>
                   <h5>Set Primary</h5>
                   </div>
                   <div id={style.photoDel} onClick={()=>this.deleteImage(item.theFile,item.theUrl,item.name)}>
                   <h5>Delete</h5>
                   </div>
                   </div>
               }
              return(
              <div className={style.photosListDiv}> {/*{style={{backgroundImage: `url(${item.image})`}}*/}
                    <div className={style.homeImgDiv}>
                <img className={style.homeProImg} src={item.theUrl} alt="Hurumart"/>
                </div>
               
                     {showPrimary}
                       </div>
              )
             })
           }else{
               console.log('empty array')
           }
           const catList=theCatList.map((item, index)=>{
               return(
               <p className={style.catP} onClick={(event)=>this.getCat(event, item.name)}>{item.name}</p>
               )
           })
           const subCatList=theCatList.map((item, index)=>{
            return(
            <p className={style.catP} onClick={(event)=>this.getSubCat(event, item.name)}>{item.name}</p>
            )
        })
        return (
            <div className={style.container} onClick={(event)=>this.closeModal(event)}>
                <div className={style.div1}>
                <h2 className={style.tit1}>Fill in the details below</h2>
                <p className={style.title1}>Category</p>
               <div className={style.inputDiv1} onClick={(event)=>this.openCatModal(event,'cat')}>
               <input className={style.theInputCont} type='text' id='category' placeholder='Enter Category'  value={this.state.catTitle|| ''} disabled   onChange={(event)=>this.handleInputChange(event,'text')}/>
                <MdKeyboardArrowDown/>
               </div>

             {this.state.catModal? <div className={style.modalDiv}>{catList}</div>:null}
                 {this.state.showSubCat?<div>
               <p className={style.title1}>Subategory</p>
               <div className={style.inputDiv1} onClick={(event)=>this.openSubModal(event,'subCat')}>
               <input className={style.theInputCont} type='text' id='category' placeholder='Enter Subcategory' value={this.state.subCatTitle|| ''}  onChange={(event)=>this.handleInputChange(event,'text')}/>
                <MdKeyboardArrowDown/>
               </div>
               {this.state.subCatModal? <div className={style.modalDiv}>{subCatList}</div>:null}
                </div>:null}

               <p className={style.title1}>Title</p>
               <div className={style.inputDiv}>
               <input className={style.theInputCont} type='text' id='title' placeholder='Enter Title'   onChange={(event)=>this.handleInputChange(event,'text')}/>
               </div>

               <p className={style.title1}>Description</p>
               <div className={style.inputDiv}>
               <input className={style.theInputCont} type='text' id='desc' placeholder='Enter Description'   onChange={(event)=>this.handleInputChange(event,'text')}/>
               </div>
              
               <p className={style.title1}>Price</p>
               <div className={style.inputDiv}>
               <input className={style.theInputCont} type='number' id='price' placeholder='Enter Price'   onChange={(event)=>this.handleInputChange(event,'text')}/>
               </div>

               <div className={style.dropFilesDiv}>
                 <h4>Click below to add photos</h4>
                 <div className={style.addImagesDiv}>
                 <input
          id="input"
          style={{display:'none'}}
          type="file"
          accept="image/*"
          multiple
          onChange={(event)=>this.handleChangeFinal(event)}
        />
        <label htmlFor='input' >Add Photos</label>

                 </div>
                   <div className={style.secImageCont2}>
                   {photosList}
                   </div>
                  </div>
               </div>
            </div>
        )
    }
}

export default Sell
