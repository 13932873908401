import React, { Component } from 'react'
import style from './MyAccount.module.scss';
import { FaWpforms } from "react-icons/fa";
import { BsPerson,BsBriefcase } from "react-icons/bs";
import { MdFavoriteBorder,MdHistory } from "react-icons/md";
import { AiOutlineCar } from "react-icons/ai";
import { VscSignOut } from "react-icons/vsc";
import ShopNav from './ShopNav'
import Footer from '../Footer/Footer2'
import DeliveredOrders from './MyAccountScreens/DeliveredOrders'
import OrderOnTransit from './MyAccountScreens/OrderOnTransit'
import SavedItems from './MyAccountScreens/SavedItems'
import RecentlyViewed from './MyAccountScreens/RecentlyViewed'
import AccountProfile from './MyAccountScreens/AccountProfile'
import { saveItemAccount } from '../../redux/postActions';
import { connect } from 'react-redux';
class MyAccount extends Component {
    state={
        divToShow:this.props.savedAcountItem
    }
    contToShow=(name)=>{
        console.log("name", name)
     this.setState({
        divToShow:name
     })
     this.props.saveItemAccount(name)
    }
    render() {
        //console.log('savedAcountItem', this.props.savedAcountItem)
        let toShow=this.props.savedAcountItem
        let theDivToShow=DeliveredOrders
        if (toShow==="profileInfo") { theDivToShow=<AccountProfile/>}
        if (toShow==="onTransit") { theDivToShow=<OrderOnTransit/>}
        if (toShow==="delivered") { theDivToShow=<DeliveredOrders/>}
        if (toShow==="savedItems") { theDivToShow=<SavedItems/>}
        if (toShow==="recentlyViewed") { theDivToShow=<RecentlyViewed/>}
        return (
            <div>
            <ShopNav/>
            <div className={style.container}>
              <div className={style.container1}>
               <div className={style.contMenu} onClick={()=>this.contToShow('profileInfo')}>
               <BsPerson className={style.contIcon1}/>
               <p>My Account</p>
               </div>
               <div className={style.contMenu1} onClick={()=>this.contToShow('onTransit')}>
               <AiOutlineCar className={style.contIcon2}/>
               <p>Orders On Transit</p>
               </div>
               <div className={style.contMenu1} onClick={()=>this.contToShow('delivered')}>
               <FaWpforms className={style.contIcon3}/>
               <p>Delivered Orders</p>
               </div>
               <div className={style.contMenu1} onClick={()=>this.contToShow('savedItems')}>
               <MdFavoriteBorder className={style.contIcon4}/>
               <p>Saved Items</p>
               </div>
               <div className={style.contMenu1} onClick={()=>this.contToShow('recentlyViewed')}>
               <MdHistory className={style.contIcon5}/>
               <p>Recently Viewed</p>
               </div>
               <div className={style.contMenuLogOut}>
               <VscSignOut className={style.contIcon6}/>
               <p>LOG OUT</p>
               </div>
              </div>
              <div className={style.container2}>
                  {theDivToShow}
              </div>
            </div>
            <Footer/>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        savedAcountItem: state.savedAcountItem,
     
    }
  }
  const mapDispatchToProps = dispatch => {
    return {
        saveItemAccount:(savedAcountItem)=>dispatch(saveItemAccount(savedAcountItem)),
    }
    }

export default connect(mapStateToProps,mapDispatchToProps) (MyAccount) 
