import React, { Component } from 'react';
import styles from './depCards.module.scss';
import {Link, NavLink} from 'react-router-dom';
import firebase from 'firebase'
let details=[
  {id:1, title:'Education Support',  details:'The Education Support program provides schools, teachers and students with the tools and support they need to succeed in the 21st Century.', image:'https://images.pexels.com/photos/2305192/pexels-photo-2305192.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
  {id:1, title:'Education Support',  details:'The Education Support program provides schools, teachers and students with the tools and support they need to succeed in the 21st Century.', image:'https://images.pexels.com/photos/2305192/pexels-photo-2305192.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'},
  {id:1, title:'Education Support',  details:'The Education Support program provides schools, teachers and students with the tools and support they need to succeed in the 21st Century.', image:'https://images.pexels.com/photos/2305192/pexels-photo-2305192.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'}
]

 class depCards extends Component {
    state={
        itemsPosts:[],
        
      }

    render() {
        const productsList=details.map((item)=>{
          const catInfo='Home'+'#$%'+item.catName+'#$%'+item.subCatName+'#$%'+item.id+'#$%'+item.primaryImage
            return(
              
           <div className={styles.homeProListCont} key={item.id}>
             <NavLink to={{pathname:'/productdetails',state:{productInfo:catInfo, theProductItem:item}}} style={{textDecoration:'none'}}>
             <div className={styles.homeImgDiv}>
          <img className={styles.homeProImg} src={item.image} alt="Hurumart" />
          </div>
            <p className={styles.homeProdTitle}>{item.title}</p>
            <p className={styles.homeProdPrice}>{item.details}</p>
            <p className={styles.homeProdLoc}>DONATE</p>
            </NavLink>
           </div>
           )
        })
        return (
            <div className={styles.homeProCont}>
                <div className={styles.proListCont}>
                {productsList}
                </div>
               
            </div>
        )
    }
}


export default depCards
