import React from 'react'
import style from './SnackBar.module.scss'
function SnackBar({message}) {
    return (
        <div className={style.cont}>
            <h3>{message}</h3>
        </div>
    )
}

export default SnackBar
