import React, { Component } from 'react'
import style from './HomeWelcome.module.scss';
import {NavLink} from 'react-router-dom'

 class HomeWelcome extends Component {
    render() {
        return (
            <div className={style.welcomeCont}>
                <div className={style.welcomeCont2}>
                 <p>Welcome to NUAfrica Pamoja Foundation</p>
                </div>
                 <div className={style.mainContainer}>
                <div className={style.leftSideBarA}>
                <div className={style.leftSideBar}>
                    <div className={style.leftSideBar1}>
                    <img className={style.img1}  src={'./photo15.jpg'}/>
                    <img  className={style.img2} src={'photo14.jpg'}/>
                    </div>
                    <div className={style.leftSideBar2}>
                    <img className={style.img3}  src={'photo16.jpg'}/>
                    <img className={style.img4}  src={'photo12.jpg'}/>
                    </div>
                {/*<img  src={'https://images.pexels.com/photos/4384289/pexels-photo-4384289.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'}/>*/}
                </div></div>
                <div className={style.rightSideBar}>
                    <div className={style.container}>
                 <h1>Welcome to NUAfrica Pamoja Foundation</h1>
                 <h3>Now uniting Africa together</h3>
                 <p className={style.enpowerP2}>NUAfrica Pamoja Foundation empowers and unites the various cultures activities that have United African across the world. Through the various forms of Arts, sports, fashion, music, film, photography and goverance and leadership we can learn a lot about unity, notable African Values, hard work, respect for senior members of the society, extended family system, religion, value for private property, language and many others. </p>
                 <NavLink to='/whatwedo' className={style.donateDiv}><p>Read More</p></NavLink>
                 
                </div>
                </div>
                </div>
            </div>
        )
    }
}

export default HomeWelcome
