import React, { Component } from 'react'
import style from './SanaaMem.module.scss';

class SanaaMembers extends Component {
    state={
         details:[
            {id:1, title:'Seroney Benjamin', position:'Founder & Director', subTitle:'Seroney is a seasoned singer, songwriter and filmmaker. He is passionate about performing arts, sports and talent development. He also has a keen interest in the role of the youth, Arts and sport in  good governance, leadership and economic development. Seroney holds a Bachelor of Laws (LLB) from Moi University and has vast experience in governance, judicial reforms, NGO management and Human Rights & Public participation.', image:'https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/team%2Fsero.jpg?alt=media&token=2fcfe621-a6a4-4ee7-b853-890d07c0f21e'},
            {id:2, title:'Peris Muthoni',position:'Co-founder & Director', subTitle:'Peris is a Chevening Alumna, Advocate of the High Court of Kenya and seasoned legal practitioner. She holds MA-Terrorism, International Crime & Global Security from Coventry University (United Kingdom), Bachelor of Laws (LLB) from Makerere University and Post Graduate Dip. from the Kenya School of Law. Her professional expertise and interests include; International Crime, Counter-Terrorism Financing and Illicit flows. Peris is passionate about governance, youth participation & development and leadership issues. ', image:'https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/cofounder.JPG?alt=media&token=c9a9876a-001a-41b6-8ba7-40bc5bbb6cc5'},
            {id:3, title:'Akim Beka',position:'Program Manager', subTitle:'Having 5+ years experience in Software Engineering and Systems Administration, Akim Beka serves as the senior technology leader leading NU-Africa Pamoja Foundation technology strategy, execution, and operations. The technology head is a champion for defining, scaling, and operating technological processes along with project management excellence.', image:'https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/clemo.jpg?alt=media&token=2b8662de-663a-46be-9fac-842ee0538f7b'},
            {id:4, title:'Loise Wambui',  position:'Program Manager',subTitle:'Loise Wambui is a Graphic designer and animator based in Nairobi, Kenya. She holds a Bachelors degree in Film Production and Animation from the Multimedia University of Kenya. She is a Programme Manager. Her passion and expertise is video production, animation, drawing, illustration drawing, wall art design and production. Her goal is to assist people find their touch with art, and identify exactly how they relate to it and to each of it is different forms. Art is inexhaustible, and with this, the future is art and art is the future. She believes this is why effort and resources must be invested in moulding and developing it.', image:'https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/volunteers%2Fvolunteer.jpeg?alt=media&token=e1c36b0a-0b98-43fb-81e0-b56bd8f945b1'},
                 ]
    }
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        const detailsList=this.state.details.map(item=>{
            return(
                <div className={style.causedivzoom} key={item.id}>
                <img src={item.image}/>
                <h1>{item.title}</h1>
                <h3>{item.position}</h3>
                <p>{item.subTitle}</p>
                </div>
            )
        })
        return (
            <div className={style.bodySection3}>
                <div className={style.causesDetCont}>
                    <h1>Meet Our Team</h1>
                    
                </div>
                <div className={style.causesItemsCont}>
                 {detailsList}
                </div>
            </div>
        )
    }
}

export default SanaaMembers
