import React, { Component } from 'react'
import styles from './Education.module.scss';
import firebase from 'firebase';
import {FaFacebookF,FaRunning,} from 'react-icons/fa';
import {MdClass,MdPeople,MdLocalPlay} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
let theImage='https://images.pexels.com/photos/3886279/pexels-photo-3886279.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
let details=[
  {id:1, title:'Education Support', path:'/education', details:'The Education Support program provides schools, teachers and students with the tools and support they need to succeed in the 21st Century.', 
  image:'https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/causes%2FNuap.jpg?alt=media&token=406e97c4-af06-409e-9931-a7d556af134b'},
  {id:2, title:'Social Entrepreneurship', path:'/social', details:'The world currently has the largest young population ever, The UN World Population estimates that in 2020 there will be 1.2 billion young people worldwide, with 21% of whom live in Africa. And these numbers will only keep growing by 2030.',
  image:'./photo17.jpg'},
  {id:3, title:'Sports For Development', path:'/sports', details:'Sports for development collaborates with governmental and non-governmental partner organizations on promoting development perspectives for youth through sporting activities within schools and their communities',
  image:'./photo20.jpg'},
  {id:5, title:'Nurturing Talent', path:'/talent', details:'Nu-Africa identifies and nurtures talent in perfoming arts, arts, sports, fashion, music, film and photography. Nurturing talent contributes to the Nu-Africa Pamoja Foundation vision to grow a pool of productive youth who will become the leaders of tomorrow.',
  image:'photo19.jpg'},
  {id:6, title:'Governance and Leadership', path:'/gorvenance', details:'Good governance and leadership is the pathway to sustainable development in Africa, and critical for the achievement of results. No community can develop beyond the level of its leadership. Underdevelopment and corruption can adduce to the problem of leadership and governance. Africa needs committed leaders who will govern with integrity and influence it human and natural resources toward the actualization of sustainable national development.',
  image:'./photo18.jpg'},
  ]
 class Sports extends Component {
     state={
         infoArray:[]
     }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.fetchInfo()
      }
      fetchInfo=async()=>{
        var array=[]
        var array2=[]
      try {
       const theRef = firebase.database().ref('/nuapdep/miziki');
       await theRef.once('value', (snapshot) => {
         snapshot.forEach((data) => {
             const info = data.val()
             console.log('info',info)
             array={
                info:info
             }
             array2.push(array)
         })
     })
       this.setState({
        infoArray:array2
        })
       console.log('array2', array2)
     } catch (error) {
       
     }
   }
    render() {
        const infoList=this.state.infoArray.map(item=>{
             return(
                <div className={styles.infoList}>
                <p>{item.info}</p>
                </div>
                    
             )
         })
         const newPostsList=details.slice(0,4).map(item=>{
            return(
                <NavLink className={styles.newPostCont}  to={{pathname:item.path, state:item}}> 
                {/*<div className={style.imgCont2} style={{backgroundImage:"url(" + theImage + ")"}}>*/}
                <div className={styles.imgCont2} style={{backgroundImage:"url(" + item.image + ")"}}>
                </div>
                <div className={styles.newPostCont2}>
                    <h2>{item.title}</h2>
                    <p>SEE MORE...</p>
                </div>
            </NavLink>
            )
        })
        return (
            <div className={styles.container}>
                 <div className={styles.container1} style={{backgroundImage:"url(" + './photo20.jpg' + ")"}}>
                <div className={styles.container1B}>
                    <div className={styles.container1Det}>
                <p className={styles.title}>SPORTS FOR DEVELOPMENT</p>
                <p className={styles.details}>Donate to Sports and Development</p>
                <p className={styles.details2}>Sports for development collaborates with governmental and non-governmental partner organizations on promoting development perspectives for youth through sporting activities within schools and their communities</p>
                <div className={styles.contDonateMain}>
                <NavLink to='/donate2' className={styles.contDonate}> 
                <p className={styles.contDonateP}>DONATE NOW</p>
                </NavLink>
                <NavLink to='/contacts' className={styles.contDonate1}> 
                <p className={styles.contDonateP}>ENROLL</p>
                </NavLink>
                </div>
                
                       </div> </div>
                </div>
                <div className={styles.container21}>
                <div className={styles.container2}>
                 <h2>HOW THE PROGRAMME WORKS</h2>
                 <p>According to the United Nations, 226 million youth aged 15-24 lived in Africa in 2015 representing nearly 20% of Africa's population, making up one fifth of the world's youth population. If one includes all people aged below 35, this number increases to a staggering three quarters of Africa's population.</p>
                 <p>The youth often only have limited access to qualitative, participative formal and non-formal education offers and development opportunities.</p>
                 <p>Sports for development collaborates with governmental and non-governmental partner organizations on promoting development perspectives for youth through sporting activities within schools and their communities.</p>
                 <p>Sport is an effective means to promote gender equality, peace, violence prevention, education, personal development or rights and mental health. Young people engaging in sports obtain vital life skills such as communication, collaboration and leadership skills that increase their confidence and community empowerment.</p>
                  <p>We work closely with schools, conflict prone areas, informal settlements to help them participate in sports activities within their communities as a way address social and economic problems. This is mainly centered on health, education, access to sport, personal development, integration of minorities and protection of children rights.</p>
                  <p>Sport organization can empower youths that are at risk of radicalization, through prevention and engagement of the youth in the various sporting activities.</p>
                  <p>Sports for Development provides concrete and practical leadership skills from some of the best leaders and experts in the field, in a truly collaborative spirit. This ensures that the lessons learned will guide these young leaders in their future endeavors.</p>
                  <p>We create safe spaces for every youth by constructing or renovating grassroots sports grounds and facilities its sustainable usage and maintenance. We further train teachers, coaches or social workers in offering quality sport based activities that promote peace, education, violence prevention, gender equality and mental health.</p>
                </div>
                <div className={styles.container2B}>
                <div  className={styles.contRight1}>
               <h1>OTHER CAUSES</h1>
                {newPostsList}
               </div>
                </div>
                </div>
                <div className={styles.container3A}>
                <div className={styles.container3}>
                <div className={styles.div1}>
                 <h3 className={styles.det1}>Upto 75%</h3>
                 <h5 className={styles.det3}>of mental problems are established by the age of 24</h5>
                   </div>
                <div className={styles.div2}>
                <h3 className={styles.det1}>Upto 30%</h3>
                 <h5 className={styles.det3}>of young women aged 15-19 Yrs experience violence by a partner</h5>
                </div>
                <div className={styles.div3}>
                <h3 className={styles.det1}>Upto 30%</h3>
                 <h5 className={styles.det3}>of young women are married before 18</h5>
                </div>
                </div>
                 <p className={styles.infoP}>info: undp.org, Walters EE. (2005)</p>
                </div>
                <div className={styles.container50}>
                <h2>HOW WE PROVIDE SPORTS FOR DEVELOPMENT</h2>
                <div className={styles.container5}>
                    <div className={styles.container6}>
                      <div className={styles.supportDiv}>
                          <MdClass className={styles.supportIc}/>
                          <div>
                            <h3>Sports Development Tools</h3>
                            <p>Workshops , Training and Tournaments</p>
                          </div>
                      </div>
                      <div className={styles.supportDiv}>
                          <FaRunning className={styles.supportIc}/>
                          <div>
                            <h3>NUAfrica Michezo Teams</h3>
                            <p>Sports gear and support</p>
                          </div>
                      </div>
                      
                    </div>
                    <div className={styles.container7}>
                 <h4>HOW PROVIDE SPORTS FOR DEVELOPMENT</h4>
                 <p>NUAfrica Pamoja Foundation aims to create its own football team to help gifted players gain the exposure in football. The team will be provided with the necessary tools to improve their football skills and community around them. </p>
          
                    </div>
                    </div>
                </div>
                <div className={styles.container8}>
                <h4>Have Questions About NU-Africa and How We Work?</h4>
                 <p>Donating is an important decision. So when you’re passionate about a cause and want to make a difference, we invite you to partner with us. NUAfrica is 100% committed to financial integrity and stewardship. If you have any questions about NUAfrica or exactly how your donation will be used, please don’t hesitate to contact us.</p>      
                </div>
                
            </div>
        )
    }
}

export default Sports
