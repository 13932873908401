import React, { Component } from 'react'
import style from './Cart.module.scss';
import ShopNav from './ShopNav'
//import Footer from '../Footer/Footer'
import {NavLink} from 'react-router-dom';
import { FaMinus,FaPlus,FaCaretDown, FaCaretUp,FaRegHeart,FaRegTrashAlt} from "react-icons/fa";
import {RiAddCircleLine} from "react-icons/md";
import { saveCartItems } from '../../redux/postActions';
import { connect } from 'react-redux';
import firebase from 'firebase';
import Footer from '../Footer/Footer2'
var propInfo=[]
var image="https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/HomeAds%2FAccessories%2Fbrace.jpg?alt=media&token=52346652-50b2-43c4-8923-7dc98e538ef0"

 var mainCartItems=[]
 class Cart extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
        quantity:1,
         unitPrice:2500,
         subTotal:0,
         total:0,
         vat:0,
         cartItems:[], 
         width:0,
         height:0,
         window800: window.matchMedia("(max-width: 800px)").matches,
         mobileWindow: window.matchMedia("(max-width: 600px)").matches
         
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        
    }
     componentDidMount(){
         //console.log('component did mount')
         window.addEventListener('resize', this.updateWindowDimensions);
         const handler = e => this.setState({mobileWindow: e.matches});
         window.matchMedia("(max-width: 600px)").addListener(handler);

         const handler2 = e => this.setState({window800: e.matches});
         window.matchMedia("(max-width: 600px)").addListener(handler2);
         this.getIncomingData()
        //console.log('iteeems2222', this.props.savedCartItems)
     }
     componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

     getIncomingData=async()=>{
         var element=0
         var array1=[]
         var array2=[]
        var itemsInfoDB = firebase.database().ref('/dirItems/');
        var catArray = firebase.database().ref('/Categories');
        catArray.once('value').then(function(snapshot) {
            var item =snapshot.val()
            console.log('snap', item.Accessories)
        })

         for(const element of this.props.savedCartItems) {
            console.log('element',element)
        await  itemsInfoDB.child(element).once('value').then(function(snapshot) {
               
                

                const id=snapshot.val().productId
                const title=snapshot.val().title
                const price=snapshot.val().price
                const image=snapshot.val().image
                const priceNo = Number(price.replace(/[^0-9]/g, ''));
                console.log('ndani firebase',snapshot.val().title)

                array1={
                    id:id, title:title, price:priceNo, quantity:1, total:priceNo, image:image
                }
                array2.push(array1)
                mainCartItems.push(array1)
           
             })
        }
       await this.setState({
            cartItems:array2
        })
       
     }
  
     addQuantity=async(id,quantity,unitPrice)=>{
        /*var quantity=this.state.quantity
        await this.setState({
          quantity:quantity+1
        })*/
     /*cartItems.forEach((element, index, array) => {
            if (element.id===id) {
                cartItems.quantity=5
            }
            cartItems2=element
        });*/
        
        /*cartItems2= this.state.cartItems.map(element => {
            
            return {
                ...element,
                quantity: element.quantity + 1
            };
        });
        this.setState({
            cartItems:cartItems2
        })
        //cartItems=cartItems2
        console.log(this.state.cartItems);*/
       var theQuantity=quantity+1
       var itemTotal=theQuantity*unitPrice
       var theArray=[]
        var objIndex = this.state.cartItems.findIndex((obj => obj.id == id));
        this.state.cartItems[objIndex].quantity=theQuantity
        this.state.cartItems[objIndex].total=itemTotal
        theArray=this.state.cartItems
       await this.setState({
            cartItems:theArray
        })
        
     }
     subtractQuantity=async(id,quantity,unitPrice)=>{
         //var quantity=quantity
         if (quantity===1) {
             return
         }
         this.setState({
           quantity:quantity-1
         })
         var theQuantity=quantity-1
         var itemTotal=theQuantity*unitPrice
         var theArray=[]
          var objIndex = this.state.cartItems.findIndex((obj => obj.id == id));
          this.state.cartItems[objIndex].quantity=theQuantity
          this.state.cartItems[objIndex].total=itemTotal
          theArray=this.state.cartItems
         await this.setState({
              cartItems:theArray
          })
     }
     deleteFromArray=async(id)=>{
        const filteredArray = this.state.cartItems.filter((item) => item.id !== id);
        await this.setState({
            cartItems:filteredArray
        })
    }
    addToFavourites=async(id)=>{
       console.log(id, ' Added to favourites')
    }
    removeCartItem=async(event, id)=>{
        event.preventDefault();
        event.stopPropagation();
          //alert(id)
      var cartItems=this.props.savedCartItems;
      await cartItems.push(id)
      cartItems=[...new Set(cartItems)];
      this.props.saveCartItems(cartItems)
      }
    render() {
        console.log('iteeems', this.props.savedCartItems)
        var cartItemsCount=this.props.savedCartItems.length
        var cartItemsTxt=''
        let iconsToShow=''
        let iconsToShow2=''
        let totalP=''
        let subTotalP=''
        let deleteToShow=''
        let saveToShow=''
        if (cartItemsCount===1) {
            cartItemsTxt= "Cart "+"| "+cartItemsCount+" Item"
        } else if(cartItemsCount>1) {
            cartItemsTxt= "Cart "+"| "+cartItemsCount+" Items"
        }
        var theUnitPrice=this.state.unitPrice.toLocaleString()
        var mySum = this.state.cartItems.map(element => element.total).reduce((a, b) => a + b, 0);
        var theVAT=mySum*0.16
        var theTotal=mySum+theVAT
        theTotal=theTotal.toLocaleString()
        theVAT=theVAT.toLocaleString()
        mySum=mySum.toLocaleString()

        const itemsList=this.state.cartItems.map((item)=>{
            var theSubTotal2=item.price*item.quantity
            theSubTotal2=theSubTotal2.toLocaleString()
            var theUnitPrice2=item.price
            theUnitPrice2=theUnitPrice2.toLocaleString()
            var subTot3=item.total.toLocaleString()

           /* if (this.state.window800) {
                deleteToShow='REMOVE'
            }else{
                deleteToShow='DELETE'
            }*/
            deleteToShow='REMOVE'
            if (this.state.mobileWindow) {
            saveToShow='SAVE  |'
            totalP='PRICE'
            subTotalP='TOTAL'
            iconsToShow2=false
            //deleteToShow='REMOVE'
            }else{
             saveToShow='FAVOURITE'
             totalP='UNIT PRICE'
             subTotalP='SUBTOTAL'
             iconsToShow2=true
             //deleteToShow='DELETE ITEM'
             
            }
            return(
                <div className={style.itemsCont0}>
                <div  className={style.itemsCont}>
                <div className={style.itemsCont1}>
                 <div className={style.imgDiv}>
                     <img  src={item.image} alt="NU-Africa"></img>
                 </div>
                 <div className={style.proDetDiv}>
                  <p className={style.proTitleP}>{item.title}</p>
                  <p className={style.proTitleP2}>Product Details</p>
                   {iconsToShow2?<div className={style.moveIconsDiv}>
                <div className={style.likeDiv} onClick={()=>this.addToFavourites(item.id)}><FaRegHeart className={style.moveIcon1}/><p>{saveToShow}</p></div>
                <div className={style.deleteDiv} onClick={()=>this.deleteFromArray(item.id)}> <FaRegTrashAlt className={style.moveIcon2}/><p>{deleteToShow}</p></div>
               
            </div> :null}
                 </div>
                </div>
                <div className={style.itemsCont2} id={style.itemsCont2}>
               <p className={style.qutP}>{item.quantity}</p>
                <div className={style.quantityIcons}>
                    <FaCaretUp className={style.quantityIconsSub} onClick={()=>this.addQuantity(item.id,item.quantity,item.price)}/>
                    <FaCaretDown className={style.quantityIconsAdd} onClick={()=>this.subtractQuantity(item.id,item.quantity,item.price)}/>
                </div>
                </div>
                <div className={style.itemsCont2}>
               <p>KSh {theUnitPrice2}</p>
                </div>
                <div className={style.itemsCont2}>{/*theSubTotal2 */}
                <p className={style.subTot}>KSh {subTot3}</p>
                </div>
                </div>
                <div  className={style.extraDiv}>
                <div className={style.moveIconsDiv}>
                <div className={style.likeDiv} onClick={()=>this.addToFavourites(item.id)}><FaRegHeart className={style.moveIcon1}/><p>{saveToShow}</p></div>
              <div className={style.deleteDiv} onClick={()=>this.deleteFromArray(item.id)}> <FaRegTrashAlt className={style.moveIcon2}/><p>{deleteToShow}</p></div>
               
             </div>
                <div className={style.extraDivItems}>
                <FaMinus className={style.minus} onClick={()=>this.subtractQuantity(item.id,item.quantity,item.price)}/>
                <h2>{item.quantity}</h2>
                <FaPlus className={style.plus} onClick={()=>this.addQuantity(item.id,item.quantity,item.price)}/>
                </div>
                </div>
               </div>

            )
        })
        return (
            <div className={style.mainContainer}>
                <ShopNav/>
                <div className={style.container}>
                    <h2 className={style.title}>{cartItemsTxt}</h2>
                    <div className={style.cont1}>
                     <div className={style.cont2}>
                      <h1>ITEM</h1>
                      <h2 className={style.QuaP}>QUANTITY</h2>
                       <h2>{totalP}</h2>
                       <h2>{subTotalP}</h2>
                     </div>
                     <div className={style.cont3}>
                     
                      {itemsList}

                     </div>
                     <div className={style.calcDiv}>
                         <div className={style.calcDiv1}>
                             <div className={style.calcDiv2}>
                             <p className={style.subTotP}>Subtotal:</p>
                             <p className={style.vatP}>VAT:</p>
                             <p className={style.totalP}>Total:</p>
                             </div>
                             <div className={style.calcDiv3}>
                            <p className={style.subTotP}>Ksh {mySum}</p>
                            <p className={style.vatP}>Ksh {theVAT}</p>
                            <p className={style.totalP}>Ksh {theTotal}</p>
                             </div>

                         </div>
                    
                     </div>
                    </div>
                  <div className={style.checkOutCont}>
                 <div className={style.checkOutCont1}>
                 <h5>CONTINUE SHOPPING</h5>
                 </div>
                 <NavLink to={{pathname:'/checkout',state:{itemsArray:this.state.cartItems, subTotal:mySum,theVAT:theVAT,theTotal:theTotal}}} className={style.checkOutCont2}>
                 <h5>PROCEED TO CHECK OUT</h5>
                 </NavLink>
                  </div>

                </div>
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        savedCartItems: state.savedCartItems,
     
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        saveCartItems:(savedCartItems)=>dispatch(saveCartItems(savedCartItems)),
     
    }
  }
  
  export default connect(mapStateToProps,mapDispatchToProps) (Cart) 

