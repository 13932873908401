import React, { Component } from 'react'
import styles from './DepDetails.module.scss';
import firebase from 'firebase';
import {FaFacebookF,FaRunning,} from 'react-icons/fa';
import {MdClass,MdPeople,MdLocalPlay} from 'react-icons/md';
let details=[
    {id:1, title:'Education Support', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur', 
    image:'https://images.unsplash.com/photo-1529390079861-591de354faf5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80'},
    {id:2, title:'Economic Enpowerment', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/1181355/pexels-photo-1181355.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'},
    {id:3, title:'Sports Promotion', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/3886055/pexels-photo-3886055.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'},
    {id:5, title:'Nurturing Talent', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/1432238/pexels-photo-1432238.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'},
    {id:6, title:'Governance and Leadership', details:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
    image:'https://images.pexels.com/photos/5083399/pexels-photo-5083399.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'},
    ]
 class Education extends Component {
     state={
         infoArray:[]
     }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.fetchInfo()
      }
      fetchInfo=async()=>{
        var array=[]
        var array2=[]
      try {
       const theRef = firebase.database().ref('/nuapdep/miziki');
       await theRef.once('value', (snapshot) => {
         snapshot.forEach((data) => {
             const info = data.val()
             console.log('info',info)
             array={
                info:info
             }
             array2.push(array)
         })
     })
       this.setState({
        infoArray:array2
        })
       console.log('array2', array2)
     } catch (error) {
       
     }
   }
    render() {
        const infoList=this.state.infoArray.map(item=>{
             return(
                <div className={styles.infoList}>
                <p>{item.info}</p>
                </div>
                    
             )
         })
         const newPostsList=details.slice(0,4).map(item=>{
            return(
                <div className={styles.newPostCont} onClick={()=>this.changePost(item)}> 
                {/*<div className={style.imgCont2} style={{backgroundImage:"url(" + theImage + ")"}}>*/}
                <div className={styles.imgCont2} style={{backgroundImage:"url(" + item.image + ")"}}>
                </div>
                <div className={styles.newPostCont2}>
                    <h2>{item.title}</h2>
                    <p>SEE MORE...</p>
                </div>
            </div>
            )
        })
        return (
            <div className={styles.container}>
                <div className={styles.container1}>
                    <div className={styles.container1Det}> 
                <p className={styles.title}>EDUCATION SUPPORT</p>
                <p className={styles.details}>Donate to a Child's Education</p>
                <p className={styles.details2}>The Education Support program provides schools, teachers and students with the tools and support they need to succeed in the 21st Century.</p>
                <div className={styles.contDonateMain}>
                <div className={styles.contDonate}>
                       <p>DONATE NOW</p>
                       </div>
                     { /* <div className={styles.contactUs}>
                       <p>Contact Us</p>
                       </div>*/}
                </div>
                
                       </div>
                </div>
                <div className={styles.container21}>
                <div className={styles.container2}>
                 <h2>HOW THE PROGRAMME WORKS</h2>
                 <p>NU-Africa Pamoja foundation relies on the generosity of donors and companies who partner with us to establish NUAfrica Clubs within school that provide technical education supports to the schools. The programme provides uniforms, sanitary pads, textbooks, bags, sports gear, art and music kits, music instruments and equipment.</p>
                 <p>The programme provides support in talent development, psychosocial and emotional support to combat mental issues. The clubs ensure promotion of gender equality, governance and leadership with well set out lessons in Art, preforming arts, sports promotion and development, fashion music, film and photography.</p>
                 <p>The programme offers improvement in the quality of learning in general both in primary and secondary education which require massive reforms in most African countries. This is through a school programme that encourages the youth to create innovative solutions and opportunities within their communities. The programme further gives supports and follow up throughout the school programme. </p>
                 <p>In many parts of Africa, strained resources prevent sufficient investment in schools and learning activities. The relative weight placed on the education system and education policy priorities, largely depend on a country’s cultural, political and geographic context, which varies considerably across the continent. There is need to reflect that the reality that transformation will take time, and most youths will need to generate their own productive opportunities, especially in the informal sector.</p>
                </div>
                <div className={styles.container2B}>
                <div  className={styles.contRight1}>
               <h1>OTHER CAUSES</h1>
                {newPostsList}
               </div>
                </div>
                </div>
                <div className={styles.container3}>
               {/* <div className={styles.div1}>
                 <h3 className={styles.det1}>Only 49%</h3>
                 <h4 className={styles.det2}>OF SECONDARY SCHOOL CHILDREN</h4>
                 <h5 className={styles.det3}>actually attend school</h5>
                   </div>
                <div className={styles.div2}>
                <h3 className={styles.det1}>Only 4.5M</h3>
                 <h4 className={styles.det2}>CHILDREN IN SUB-SAHARAN</h4>
                 <h5 className={styles.det3}>are unable to attend school</h5>
                </div>
                <div className={styles.div3}>
                <h3 className={styles.det1}>52%</h3>
                 <h4 className={styles.det2}>OF SECONDARY SCHOOL CHILDREN</h4>
                 <h5 className={styles.det3}>actually attend school</h5>
                </div>*/}
                </div>
                <div className={styles.container50}>
                <h2>HOW WE HELP PROVIDE EDUCATION SUPPORT</h2>
                <div className={styles.container5}>
                    <div className={styles.container6}>
                      <div className={styles.supportDiv}>
                          <MdClass className={styles.supportIc}/>
                          <div>
                            <h3>Classes and Supplies</h3>
                            <p>Textbooks, postsecondary courses, test and lab supplies</p>
                          </div>
                      </div>
                      <div className={styles.supportDiv}>
                          <FaRunning className={styles.supportIc}/>
                          <div>
                            <h3>Recreational Support</h3>
                            <p>Sports gear and equipment Music, film and photography classes</p>
                          </div>
                      </div>
                      <div className={styles.supportDiv}>
                          <MdLocalPlay className={styles.supportIc}/>
                          <div>
                            <h3>Psychosocial support</h3>
                            <p>Promotion of gender equality, mental health support and governance and leadership</p>
                          </div>
                      </div>
                    </div>
                    <div className={styles.container7}>
                 <h4>HOW WE HELP PROVIDE EDUCATION SUPPORT</h4>
                 <p>NU-Africa Pamoja foundation helps children stay in school and acquire valuable skills that empower them to be contributing individuals in their communities. They may receive uniforms, sanitary pads, textbooks, bags, sports gear, art and music kits, music instruments and equipment.</p>
                 <p>They may also receive training in talent development, girl child empowerment, psychosocial and emotional support, governance and leadership. This includes children with special needs. </p>
                 <p>Your donation helps to provide the essential resources for children to go beyond their primary and secondary education. Contribute today to keep children in school so they can grow academically and develop their talents for a successful future.</p>        
                    </div>
                    </div>
                </div>
                <div className={styles.container8}>
                <h4>Have Questions About NU-Africa and How We Work?</h4>
                 <p>Donating is an important decision. So when you’re passionate about a cause and want to make a difference, we invite you to partner with us. NUAfrica is 100% committed to financial integrity and stewardship. If you have any questions about NUAfrica or exactly how your donation will be used, please don’t hesitate to contact us.</p>      
                </div>
                
            </div>
        )
    }
}

export default Education
