import React, { Component } from 'react'
import style from './LogIn.module.scss';
import Whirligig from 'react-whirligig'
const imageOne ='https://images.unsplash.com/photo-1573164574230-db1d5e960238?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80'
const imageTwo = 'https://images.unsplash.com/photo-1526178613552-2b45c6c302f0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80'
const imageThree= 'https://images.unsplash.com/photo-1573855619003-97b4799dcd8b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80'
const items=[
    {name:'1'},
    {name:'2'},
    {name:'3'},
    {name:'4'},
    {name:'5'}
]


let whirligig
class LogIn extends Component {
 
    

  componentWillMount() {
  
}
 next = () =>{
    whirligig.next()
 }
 prev  = () =>{
    whirligig.prev ()
 }
  render() {
    return (
        <div className={style.container}>
        <button onClick={this.prev}>Prev</button>
        <Whirligig
          visibleSlides={3}
          gutter="1em"
          ref={(_whirligigInstance) => { whirligig = _whirligigInstance}}
        >
          <img src="http://www.fillmurray.com/400/300" />
          <img src="http://www.fillmurray.com/300/400" />
          <img src="http://www.fillmurray.com/400/200" />
          <img src="http://www.fillmurray.com/200/400" />
          <img src="http://www.fillmurray.com/500/300" />
        </Whirligig>
        <button onClick={this.next}>Next</button>
      </div>
    )
} }
export default LogIn