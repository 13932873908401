import React, { Component } from 'react'
import styles from './VolunteerReel.module.scss';
import firebase from 'firebase';
import {NavLink} from 'react-router-dom'

 class VolunteerReel extends Component {
     state={
         infoArray:[]
     }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.fetchInfo()
      }
      fetchInfo=async()=>{
        var array=[]
        var array2=[]
      try {
       const theRef = firebase.database().ref('/nuapdep/sanaa');
       await theRef.once('value', (snapshot) => {
         snapshot.forEach((data) => {
             const info = data.val()
             console.log('info',info)
             array={
                info:info
             }
             array2.push(array)
         })
     })
       this.setState({
        infoArray:array2
        })
       console.log('array2', array2)
     } catch (error) {
       
     }
   }
    render() {
 
        return (
            <div className={styles.container}>
                <div className={styles.container1}>
                <div className={styles.container1Det}>
                <h4 className={styles.title}>GET INVOLVED TO BE PART OF THE SOLUTION</h4>
                <p className={styles.details2}>Together we can transform Communties by inspiring and empowering the youth to be the best versions of themselves. Donate today for education support, social entrepreneurship, sports for development, nurturing Talent and Governance and leadership.</p>
                <NavLink to='/donate2' className={styles.contDonate} style={{textDecoration:'none'}} >Donate</NavLink>
                </div>
                </div>
              
                
            </div>
        )
    }
}

export default VolunteerReel
