import React, { Component } from 'react'
import style from './VolunteerWelcome.module.scss';
import { NavLink } from 'react-router-dom'

class VolunteerWelcome extends Component {
    render() {
        return (
            <div className={style.welcomeCont}>
                <div className={style.mainContainer}>
                    <div className={style.leftSideBar}>
                        <img src={'./photo32.jpg'} />
                    </div>
                    <div className={style.rightSideBar}>
                        <div className={style.container}>
                            <h1>VOLUNTEER WITH US</h1>
                            <p className={style.enpowerP2}>NUAfrica Pamoja Foundation seeks to continually grow the creative industry in Kenya and Africa at large, specifically in Film, Sports, Music, Fashion and Performing Arts. This however, hardly possible without the valuable input of our volunteers in various departments. Volunteers are welcome to register and work with us in any of these departments, depending on their area of expertise. If you are a creative, seeking to expand your horizons, get challenged, while at the same time give back to the community with your knowledge and expertise, NUA is the place to be.</p>
                            <NavLink to='/volform' className={style.donateDiv}><p>Be a volunteer</p></NavLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default VolunteerWelcome
