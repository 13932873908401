import React, { Component } from 'react'
import styles from './Education.module.scss';
import firebase from 'firebase';
import {FaFacebookF,FaRunning,} from 'react-icons/fa';
import {MdClass,MdPeople,MdLocalPlay} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
let theImage='https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/causes%2FNuap.jpg?alt=media&token=406e97c4-af06-409e-9931-a7d556af134b'
let details=[
  {id:1, title:'Education Support', path:'/education', details:'The Education Support program provides schools, teachers and students with the tools and support they need to succeed in the 21st Century.', 
  image:'https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/causes%2FNuap.jpg?alt=media&token=406e97c4-af06-409e-9931-a7d556af134b'},
  {id:2, title:'Social Entrepreneurship', path:'/social', details:'The world currently has the largest young population ever, The UN World Population estimates that in 2020 there will be 1.2 billion young people worldwide, with 21% of whom live in Africa. And these numbers will only keep growing by 2030.',
  image:'./photo17.jpg'},
  {id:3, title:'Sports For Development', path:'/sports', details:'Sports for development collaborates with governmental and non-governmental partner organizations on promoting development perspectives for youth through sporting activities within schools and their communities',
  image:'./photo20.jpg'},
  {id:5, title:'Nurturing Talent', path:'/talent', details:'Nu-Africa identifies and nurtures talent in perfoming arts, arts, sports, fashion, music, film and photography. Nurturing talent contributes to the Nu-Africa Pamoja Foundation vision to grow a pool of productive youth who will become the leaders of tomorrow.',
  image:'photo19.jpg'},
  {id:6, title:'Governance and Leadership', path:'/gorvenance', details:'Good governance and leadership is the pathway to sustainable development in Africa, and critical for the achievement of results. No community can develop beyond the level of its leadership. Underdevelopment and corruption can adduce to the problem of leadership and governance. Africa needs committed leaders who will govern with integrity and influence it human and natural resources toward the actualization of sustainable national development.',
  image:'./photo18.jpg'},
  ]
 class DepDetails extends Component {
     state={
         infoArray:[]
     }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.fetchInfo()
      }
      fetchInfo=async()=>{
        var array=[]
        var array2=[]
      try {
       const theRef = firebase.database().ref('/nuapdep/miziki');
       await theRef.once('value', (snapshot) => {
         snapshot.forEach((data) => {
             const info = data.val()
             console.log('info',info)
             array={
                info:info
             }
             array2.push(array)
         })
     })
       this.setState({
        infoArray:array2
        })
       console.log('array2', array2)
     } catch (error) {
       
     }
   }
    render() {
        const infoList=this.state.infoArray.map(item=>{
             return(
                <div className={styles.infoList}>
                <p>{item.info}</p>
                </div>
                    
             )
         })
         const newPostsList=details.slice(0,4).map(item=>{
            return(
                <NavLink className={styles.newPostCont}  to={{pathname:item.path, state:item}}> 
                {/*<div className={style.imgCont2} style={{backgroundImage:"url(" + theImage + ")"}}>*/}
                <div className={styles.imgCont2} style={{backgroundImage:"url(" + item.image + ")"}}>
                </div>
                <div className={styles.newPostCont2}>
                    <h2>{item.title}</h2>
                    <p>SEE MORE...</p>
                </div>
            </NavLink>
            )
        })
        return (
            <div className={styles.container}>
               <div className={styles.statusBar}></div>
                <div className={styles.container1} style={{backgroundImage:"url(" + theImage + ")"}}>
                <div className={styles.container1B}>
                    <div className={styles.container1Det}>
                <p className={styles.title}>EDUCATION SUPPORT</p>
                <p className={styles.details}>Donate to a Child's Education</p>
                <p className={styles.details2}>The Education Support program provides schools, teachers and students with the tools and support they need to succeed in the 21st Century.</p>
                <div className={styles.contDonateMain}>
                <NavLink to='/donate2' className={styles.contDonate}> 
                <p className={styles.contDonateP}>DONATE NOW</p>
                </NavLink>
                <NavLink to='/contacts' className={styles.contDonate1}> 
                <p className={styles.contDonateP}>ENROLL</p>
                </NavLink>
                </div>
                
                       </div> </div>
                </div>
                <div className={styles.container21}>
                <div className={styles.container2}>
                 <h2>HOW THE PROGRAMME WORKS</h2>
                 <p>NU-Africa Pamoja foundation relies on the generosity of donors and companies who partner with us to establish NUAfrica Clubs within school that provide technical education support to the schools. The programme provides uniforms, sanitary pads, textbooks, bags, sports gear, art and music kits, music instruments and equipment.</p>
                 <p>The programme provides support in talent development, psychosocial and emotional support to combat mental health issues. The clubs ensure promotion of gender equality, governance and leadership with well set out lessons in Art, preforming arts, sports promotion and development, fashion music, film and photography.</p>
                 <p>The programme offers improvement in the quality of learning in general both in primary and secondary education which require massive reforms in most African countries. This is through a school programme that encourages the youth to create innovative solutions and opportunities within their communities. The programme further gives supports and follow up throughout the school programme. </p>
                 <p>In many parts of Africa, strained resources prevent sufficient investment in schools and learning activities. The relative weight placed on the education system and education policy priorities, largely depend on a country’s cultural, political and geographic context, which varies considerably across the continent. There is need to recognise that transformation will take time, and most youths will need to generate their own productive opportunities, especially in the informal sector.</p>
                </div>
                <div className={styles.container2B}>
                <div  className={styles.contRight1}>
               <h1>OTHER CAUSES</h1>
                {newPostsList}
               </div>
                </div>
                </div>
                <div className={styles.container3A}>
                <div className={styles.container3}>
                <div className={styles.div1}>
                 <h3 className={styles.det1}>Only 49%</h3>
                 <h4 className={styles.det2}>of secondary school children actually attend school</h4>
                   </div>
                <div className={styles.div2}>
                <h3 className={styles.det1}>Only 4.5M</h3>
                 <h4 className={styles.det2}>children in sub-saharan Africa are able to attend school</h4>
                </div>
                <div className={styles.div3}>
                <h3 className={styles.det1}>Only 52%</h3>
                 <h4 className={styles.det2}>of children worldwide not attending school are female</h4>
                </div>
               
                </div>
                <div className={styles.div3B}>
                  <p>sources : </p>
                  <p>www.childinfo.org</p>
                  <p>www.crin.org</p>
                  <p>www.who.int</p>
                  <p>www.unaids.org</p>
                  <p>www.unicef.org</p>
                </div>
                 
                 </div>
                <div className={styles.container50}>
                <h2>HOW WE HELP PROVIDE EDUCATION SUPPORT</h2>
                <div className={styles.container5}>
                    <div className={styles.container6}>
                      <div className={styles.supportDiv}>
                          <MdClass className={styles.supportIc}/>
                          <div>
                            <h3>Classes and Supplies</h3>
                            <p>Textbooks, postsecondary courses, test and lab supplies</p>
                          </div>
                      </div>
                      <div className={styles.supportDiv}>
                          <FaRunning className={styles.supportIc}/>
                          <div>
                            <h3>Recreational Support</h3>
                            <p>Sports gear, equipment Music, film and photography classes</p>
                          </div>
                      </div>
                      <div className={styles.supportDiv}>
                          <MdLocalPlay className={styles.supportIc}/>
                          <div>
                            <h3>Psychosocial support</h3>
                            <p>Promotion of gender equality, mental health support and governance and leadership</p>
                          </div>
                      </div>
                    </div>
                    <div className={styles.container7}>
                 <h4>HOW WE HELP PROVIDE EDUCATION SUPPORT</h4>
                 <p>NU-Africa Pamoja foundation helps children stay in school and acquire valuable skills that empower them to be contributing individuals in their communities. They may receive uniforms, sanitary pads, textbooks, bags, sports gear, art and music kits, music instruments and equipment.</p>
                 <p>They may also receive training in talent development, girl child empowerment, psychosocial and emotional support, governance and leadership. This includes children with special needs. </p>
                 <p>Your donation helps to provide the essential resources for children to go beyond their primary and secondary education. Contribute today to keep children in school so they can grow academically and develop their talents for a successful future.</p>        
                    </div>
                    </div>
                </div>
                <div className={styles.container8}>
                <h4>Have Questions About NU-Africa and How We Work?</h4>
                 <p>Donating is an important decision. So when you’re passionate about a cause and want to make a difference, we invite you to partner with us. NUAfrica is 100% committed to financial integrity and stewardship. If you have any questions about NUAfrica or exactly how your donation will be used, please don’t hesitate to contact us.</p>      
                </div>
                
            </div>
        )
    }
}

export default DepDetails
