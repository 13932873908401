import React, { Component } from 'react'
import style from './Dashboard.module.scss';

class Dashboard extends Component {
    render() {
        return (
            <div className={style.container}>

               { /*<div className={style.mainCont}>
                <div className={style.container1}>
               
               </div>
               <div className={style.container2}>
               
            </div>
                </div>*/}
                 <div className={style.triangle}>
               
               </div>
               <div className={style.triangle2}>
               
               </div>
               
            </div>
        )
    }
}

export default Dashboard
