import React, { Component } from 'react'
import './NavBar.scss';
import { FaFacebookF,FaTwitter,FaInstagram,FaBars,FaSignInAlt } from 'react-icons/fa';
import { RiPauseMiniLine} from 'react-icons/ri';
import {MdClear} from "react-icons/md";
import {NavLink,withRouter} from 'react-router-dom'

class NavBar extends Component {
 state={
    showOptionsMenu: false,showDeps:false
  
 }
 onScroll = () => { 
    const isTop=window.scrollY>100;
    const nav=document.getElementById('nav');
    if(isTop){
        nav.classList.add("scrolled");
    }else{
        nav.classList.remove("scrolled");
    }
}
 componentDidMount(){
    window.addEventListener("scroll", this.onScroll, false);
     
}
componentWillUnmount(){
    window.removeEventListener("scroll", this.onScroll, false);
   // window.removeAllListeners("scroll");
}
   
hideMenu = () => {
        this.setState({
            showOptionsMenu: false
        })

        // alert(this.state.showOptionsMenu)
    }
    showMenu = () => {
        this.setState({
            showOptionsMenu: true
        })

         //alert(this.state.showOptionsMenu)
    }
    goToLink = (event,link) => {
       this.props.history.push(link)
    }
    goToLink2 = (event,link) => {
        this.setState({ showOptionsMenu:false})
        this.props.history.push(link)
     }
   
    hideDeps= async() => {
     var stat= await this.state.showDeps
     this.setState({showDeps:!stat,})
    /* if (stat===true) {
        
     } else {
         
     }*/
    }
    render() {
       
        return (
            <div>
            <div className='nav' id='nav'>
                 <div className='nav2'>
                <div className='centerNenuDiv'>
                <NavLink to='/' className='NavLogo'><img src='./NU.png'></img></NavLink>
                {/*<h1 style={{color:'#fff', fontSize:17, marginLeft:50, cursor:"pointer"}}><span style={{color:'#FF0000'}}>NU</span>-Africa Foundation</h1>*/}
                   <ul className='navBarUl'>
                   <li> <NavLink to='/whoweare' className='NavLi'>WHO ARE WE</NavLink></li>
                   <li> <NavLink to='/whatwedo' className='NavLi'>WHAT WE DO</NavLink></li>
                   <li id='depLi'><NavLink to='/department' className='NavLi'> DEPARTMENTS</NavLink>
                   {/*<a>
                    <ul id='ulDep'>
                        <li onClick={(event)=>this.goToLink(event,'/sanaa')}>Sanaa</li>
                        <li onClick={(event)=>this.goToLink(event,'/michezo')}>Michezo</li>
                        <li onClick={(event)=>this.goToLink(event,'/mishono')}>Mishono</li>
                        <li onClick={(event)=>this.goToLink(event,'/miziki')}>Miziki</li>
                        <li onClick={(event)=>this.goToLink(event,'/filamu')}>Filamu na picha</li>
                        <li onClick={(event)=>this.goToLink(event,'/power')}>Power</li>
                        
                    </ul></a>*/}
                   </li>
                   {/*<li> <NavLink to='/supportus' className='NavLi'>GET INVOLVED</NavLink></li>*/}
                   <li> <NavLink to='/volunteer' className='NavLi'>GET INVOLVED</NavLink></li>
                   <li> <NavLink to='/media' className='NavLi'>LATEST</NavLink></li>
                   <li ><NavLink to='/shop' className='shopLi'>SHOP</NavLink></li>
                     {/* <li> <NavLink to='/sanaa' className='NavLi'>Sanaa</NavLink></li>
                      <li> <NavLink to='/michezo' className='NavLi'>Michezo</NavLink></li>
                      <li> <NavLink to='/mishono' className='NavLi'>Mishono</NavLink></li>
                      <li> <NavLink to='/miziki' className='NavLi'>Miziki</NavLink></li>
                      <li> <NavLink to='/filamu' className='NavLi'>Filamu</NavLink></li>
                      <li> <NavLink to='/power' className='NavLi'>Power</NavLink></li>
                       <li ><NavLink to='/shop' className='shopLi'>Shop</NavLink></li>
                       <li className='logInCont'>
                <FaSignInAlt className='signInIcon'/>
                <NavLink to='/register' className='NavLogIn'><p>LOGIN</p></NavLink></li>*/}
                   </ul>
                </div>
                <div className='openCloseIcons'>
                    {this.state.showOptionsMenu?<MdClear onClick={this.hideMenu} size={45} className='navCloseIcon'/>:
                    <FaBars onClick={this.showMenu} size={30} className='navOpenIcon'/>
                    }
                </div>
                </div>

                {this.state.showOptionsMenu? 
                <div className='menu2Div'>
                <ul className='navBarUl2'>
                      <li onClick={this.hideMenu} > <NavLink  to='/whoweare' className='NavLi2'>WHO ARE WE</NavLink></li>
                      <li onClick={this.hideMenu}> <NavLink to='/whatwedo' className='NavLi2'>WHAT WE DO</NavLink></li>
                      <li onClick={this.hideDeps}> <NavLink to='/department' className='NavLi2'>DEPARTMENTS</NavLink></li>
                      {/*hideDeps =>deleted
                      this.state.showDeps?<a>
                    <ul id='ulDep2'>
                        <li onClick={(event)=>this.goToLink2(event,'/sanaa')}>Sanaa</li>
                        <li onClick={(event)=>this.goToLink2(event,'/michezo')}>Michezo</li>
                        <li onClick={(event)=>this.goToLink2(event,'/mishono')}>Mishono</li>
                        <li onClick={(event)=>this.goToLink2(event,'/miziki')}>Miziki</li>
                        <li onClick={(event)=>this.goToLink2(event,'/filamu')}>Filamu na picha</li>
                        <li onClick={(event)=>this.goToLink2(event,'/power')}>Power</li> 
                    </ul></a>:null*/}
                      {/*<li onClick={this.hideMenu}> <NavLink to='/supportus' className='NavLi2'>GET INVOLVED</NavLink></li>*/}
                      <li onClick={this.hideMenu}> <NavLink to='/volunteer' className='NavLi2'>GET INVOLVED</NavLink></li>
                      <li onClick={this.hideMenu}> <NavLink to='/media' className='NavLi2'>LATEST</NavLink></li>
                      <li onClick={this.hideMenu}> <NavLink to='/shop' className='NavLi2'>SHOP</NavLink></li>
                   </ul>
                </div>:null}
                
                {/*<div className='rightMenuDiv'>
                <p>Log In</p>
                <RiPauseMiniLine  size={20} color='#fff'/>
                <p>Register</p>
               
                </div>*/}
                {/*<FaFacebookF  size={20} className='navIcons'/>
                 <FaTwitter size={20} className='navIcons'/>
                 <FaInstagram size={20} className='navIcons'/>*/}
                  {/*this.state.showOptionsMenu? <ul className='navBarUl2'>
                      <li onClick={this.hideMenu}> <NavLink  to='/aboutUs' className='NavLi2'>WHO ARE WE</NavLink></li>
                      <li onClick={this.hideMenu}> <NavLink to='/whatwedo' className='NavLi2'>WHAT WE DO</NavLink></li>
                      <li onClick={this.hideMenu}> <NavLink to='/mishono' className='NavLi2'>DEPARTMENTS</NavLink></li>
                      <a>
                    <ul id='ulDep2'>
                        <li onClick={(event)=>this.goToLink(event,'/sanaa')}>Sanaa</li>
                        <li onClick={(event)=>this.goToLink(event,'/michezo')}>Michezo</li>
                        <li onClick={(event)=>this.goToLink(event,'/mishono')}>Mishono</li>
                        <li onClick={(event)=>this.goToLink(event,'/miziki')}>Miziki</li>
                        <li onClick={(event)=>this.goToLink(event,'/filamu')}>Filamu na picha</li>
                        <li onClick={(event)=>this.goToLink(event,'/power')}>Power</li> 
                    </ul></a>
                      <li onClick={this.hideMenu}> <NavLink to='/supportus' className='NavLi2'>GET INVOLVED</NavLink></li>
                      <li onClick={this.hideMenu}> <NavLink to='/volunteer' className='NavLi2'>DONATE NOW</NavLink></li>
                      <li onClick={this.hideMenu}> <NavLink to='/media' className='NavLi2'>LATEST</NavLink></li>
                   </ul>:null*/}

            </div>
            </div>
        )
    }
}

export default withRouter(NavBar)
