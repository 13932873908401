import React, { Component } from 'react'
import style from './memberForm.module.scss';
import {TiTick} from 'react-icons/ti';
import SnackBar  from '../Helper/SnackBar'
import firebase from 'firebase';
class VolForm extends Component {
    state={
        name:'',
        phone:'',
        email:'',
        age:null,
        gender:'',
        department:'',
        qualification:'',
        showSnackBar:false,
        snackMeso:''
    }
    inputChange(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({[event.target.id]:event.target.value});
      }
      handleDepChange(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({department: event.target.value});
      }
      handleQualChange(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({qualification: event.target.value});
      }
      handleChangeFinal(event) {
        event.preventDefault();
        event.stopPropagation();
        
      }
      chooseGender=(event, name)=>{
        event.preventDefault();
        event.stopPropagation();
        this.setState({gender: name});
      }
      sendMessage=async()=>{
        const theRef = firebase.database().ref('/notifications/contacts');
        var time = Date.now();
        if (this.state.name===''||this.state.phone===''||this.state.email===''||this.state.age===''||
        this.state.department===''|this.state.qualification==='') {
          this.setState({ showSnackBar: true,snackMeso:'All fields must be filled'})
          await setTimeout(
             () => this.setState({ showSnackBar: false }), 
             3000
           )
          return
        }else  if (this.state.name!=''&&this.state.phone!=''&&this.state.email!=''&&this.state.age!=''
        &&this.state.department!=''&&this.state.qualification!='') {
          this.setState({ showSnackBar: true,snackMeso:'Registration uploaded successfully'})
          var theMeso={
            name:this.state.name,
            phone:this.state.phone,
            email:this.state.email,
            age:this.state.age,
            gender:this.state.gender,
            department:this.state.department,
            qualification:this.state.qualification,
            subject:'Register',
            time:time,
            status:'send'
        }
          theRef.push(theMeso)
          await setTimeout(
             () => this.setState({ showSnackBar: false }), 
             3000
           )
        }
    }
    render() {
        let maleStyle=[], femaleStyle=[], nonBinary=[]
        let activeStyle={
            backgroundColor:'#eb3e32',
            color:'#fff',
            borderColor:'#eb3e32'
        }
        let inActiveStyle={
            backgroundColor:'#fff',
            color:'#fff',
            borderColor:'#000'
        }
         if (this.state.gender==='Male') {
            maleStyle=activeStyle
            femaleStyle=inActiveStyle
            nonBinary=inActiveStyle
        }else if(this.state.gender==='Female') {
            maleStyle=inActiveStyle
            femaleStyle=activeStyle
            nonBinary=inActiveStyle
        }
        else if(this.state.gender==='Non Binary') {
          maleStyle=inActiveStyle
          femaleStyle=inActiveStyle
          nonBinary=activeStyle
      }
        return (
          <>
           <div className={style.statusBar}></div>
            <div className={style.container}>
                <div className={style.container1}>
                  <h1 className={style.tit}>NUAfrica membership</h1>
                  <h2 className={style.subTit}>To become a member, fill in the form below. After verification, your request will undergo assessment from the Membership team, and you will receive feedback within five working days.</h2>
                 <div className={style.inputsDiv}>
                  <div className={style.inputsDiv1}>
                  <input type='text' id='name' placeholder={'Name*'} className={style.name} onChange={(event)=>this.inputChange(event)}></input>
                  <input type='number' id='phone' placeholder={'Phone*'} className={style.phone} onChange={(event)=>this.inputChange(event)}></input>
                  </div>
                  <div className={style.inputsDiv2}>
                  <input type='text' id='email' placeholder={'Email*'} className={style.email} onChange={(event)=>this.inputChange(event)}></input>
                  <input type='number' id='age' placeholder={'Age*'} className={style.age} onChange={(event)=>this.inputChange(event)}></input>
                  </div>
                  </div> 
               <h3 className={style.gH}>Gender</h3>
               <div className={style.genderDiv}><div style={maleStyle} className={style.genderDiv1} onClick={(event)=>this.chooseGender(event,'Male')}><TiTick color='#fff'/></div><h3>Male</h3>
               <div className={style.genderDiv2}  style={femaleStyle} onClick={(event)=>this.chooseGender(event,'Female')}><TiTick color='#fff'/></div><h3>Female</h3>
               <div className={style.genderDiv2}  style={nonBinary} onClick={(event)=>this.chooseGender(event,'Non Binary')}><TiTick color='#fff'/></div><h3>Non Binary</h3>
               </div>
                <div className={style.genderDiv}>
               <div className={style.selectorDiv}>
               <div className={style.contDiv}>
                <p className={style.condP}>Area of profession</p>
                <div className={style.selectCont}>
                <select  className={style.selectDiv} value={this.state.value} onChange={(event)=>this.handleDepChange(event)}>
                  <option  selected value="Click to select">Click to select</option>
                 
                  <option value="Film">Film</option>
                  <option value="Fashion">Fashion</option>
                  <option value="Performing Arts">Performing Arts</option>
                  <option value="Music">Music</option>
                  <option value="Sports">Sports</option>
                  <option value="Arts">Arts</option>
                  <option value="Photography">Photography</option>
                  <option value="Power (Governance &amp; Leardership)">Power (Governance &amp; Leardership)</option>
                  <option value="All">Others</option>
                </select>
                </div>
                </div>               
                <div className={style.contDiv}>
                <p className={style.condP}>Mode of Payment</p>
                <div className={style.selectCont}>
                <select  className={style.selectDiv} value={this.state.value} onChange={(event)=>this.handleQualChange(event)}>
                  <option  selected value="Click to select">Click to select</option>
                  <option value="Mpesa">Mpesa</option>
                  <option value="Paypal">Paypal</option>
                  <option value="Card">Debit/Credit Card</option>
                  <option value="Cheque">Cheque</option>
                </select>
                </div>
                </div>
                </div>
                </div>
                <div className={style.selectFile}>
                <div className={style.dropFilesDiv}>
                 <h4>Click below to add photos</h4>
                 <div className={style.addImagesDiv}>
                 <input
               id="input"
               style={{display:'none'}}
               type="file"
             accept="image/*"
          //capture="camera"
          multiple
          onChange={(event)=>this.handleChangeFinal(event)}
        />
        <label htmlFor='input' >Add Photos</label> 
                 </div>
                  </div>
                  </div>
                 <div>
                 <div>
                   <p className={style.DetP01}>Payment Details</p>
                   <p className={style.DetP1A}>M-Pesa details</p>
                   <p className={style.DetP1}>Mpesa till No: 5539205</p>
                   <p className={style.DetP1A}>Bank Details</p>
                   <p className={style.DetP1}>Bank: DTB</p>
                   <p className={style.DetP1}>Branch: TRM</p>
                   <p className={style.DetP1}>Account Name: NU AFRICA PAMOJA</p>
                   <p className={style.DetP1}>Account No: 0660625002</p>
                   </div>
                   <div className={style.paydiv}>
                   <img  className={style.theImage} src={'./vis3.png'} alt="NU-Africa"></img>
                   <img  className={style.theImage} src={'./mas2.png'} alt="NU-Africa"></img>
                   <img  className={style.theImage} src={'./pay4.png'} alt="NU-Africa"></img>
                   <img  className={style.theImage} src={'./mpesa.png'} alt="NU-Africa"></img>
                   </div>
                 </div>
               
                  <div className={style.uploadDiv} onClick={()=>this.sendMessage()}>
                <p >UPLOAD</p>
            </div>
            </div>
           
            </div>
            {this.state.showSnackBar?<SnackBar message={this.state.snackMeso}/>:null}
            </>
        )
    }
}

export default VolForm
