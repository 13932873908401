import React, { Component } from 'react'
import styles from './Donate.module.scss';
import {IoMdCheckmark} from "react-icons/io";
let donations=[
    {id:2, amount:10},
    {id:3, amount:20},
    {id:4, amount:50},
    {id:5, amount:100},
    {id:6, amount:200}
]

export class Donate extends Component {
    state={
        paymentType:'One time',
        exchange:'',
        donationAmount:'',
        donAmount:'',
        paymentMethod:'VISA',
        currency:'KES '
    }
    paymentType=(type)=>{
      this.setState({paymentType:type})
    }
    handleExchangeChange(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({exchange: event.target.value});
      }
      handleCurrency(event) {
        event.preventDefault();
        event.stopPropagation();
        console.log('currency', event.target.value)
        this.setState({currency: event.target.value});
      }
      handlePriceChange(event) {
          //console.log
        event.preventDefault();
        event.stopPropagation();
        var priceInfo=event.target.value
        this.setState({donationAmount:priceInfo});
      }
      paymentAmount=(amount)=>{
       this.setState({donAmount:amount,donationAmount:''})
      }
      donat2=()=>{
        this.setState({donAmount:''})
       }
       paymentMethod=(method)=>{
        this.setState({paymentMethod:method})
       }
      
    render() {
        let payStyle1=[]
        let payStyle2=[]
        let donStyle=[]
        let showRecurring=''
        if (this.state.paymentType==='One time') {
            showRecurring=''
            payStyle1={
                backgroundColor:'#eb3e32'
            }
            payStyle2={
                backgroundColor:'#eee'
            }
        } else if (this.state.paymentType==='Recurring'){
            showRecurring=<div className={styles.contDiv}>
            <p className={styles.condP}>Donation Type</p>
            <div className={styles.selectCont}>
            <select  className={styles.selectDiv} value={this.state.value} onChange={(event)=>this.handleExchangeChange(event)}>
              <option  selected value="Educate Children">Weekly</option>
              <option value="Nu-Africa Michezo">Daily</option>
              <option value="Nu-Africa Michezo">Monthly</option>
              <option value="Nu-Africa Mishono">Annualy</option>
            </select>
            </div>
            </div>
            payStyle1={
                backgroundColor:'#eee'
            }
            payStyle2={
                backgroundColor:'#eb3e32'
            }
        }
        const donationsList=donations.map(item=>{
            let amount = 0
            if (this.state.currency==='KES ') {
                amount=(item.amount*100).toLocaleString()
            } else {
                amount=this.state.currency+item.amount
            }
            if (item.amount===this.state.donAmount) {
                donStyle={
                    backgroundColor:'#eb3e32'
                }
            } else {
                donStyle={
                    backgroundColor:'#eee'
                }
            }
            return(
                <div className={styles.donList}>
                <div className={styles.donListSelector} style={donStyle} onClick={()=>this.paymentAmount(item.amount)}>
                         <IoMdCheckmark className={styles.donListIc}/> 
                         </div>
                         <p>{amount}</p>
                </div>
                   
            )
        })
        return (
            <div className={styles.container}>
                <div className={styles.container1}>
               <div className={styles.container2}>
               <div className={styles.contHead}>
                   <p>Donation (One Time / Recurring)</p>
               </div>
               <div className={styles.payments}>
                   <div className={styles.imgCont} id={styles.imgCont1} onClick={()=>this.paymentMethod('VISA')}>
                   <img  className={styles.theImage} src={'./vis3.png'} alt="NU-Africa"></img>
                   </div>
                 <div className={styles.imgCont} id={styles.imgCont2} onClick={()=>this.paymentMethod('MASTERCARD')}>
                 <img  className={styles.theImage} src={'./mas2.png'} alt="NU-Africa"></img>
                 </div>
                <div className={styles.imgCont} id={styles.imgCont3} onClick={()=>this.paymentMethod('AMERICAN EXPRESS')}>
                <img  className={styles.theImage} src={'./american.png'} alt="NU-Africa"></img>
                </div>
                <div className={styles.imgCont} id={styles.imgCont4} onClick={()=>this.paymentMethod('PAYPAL')}>
                <img  className={styles.theImage} src={'./pay4.png'} alt="NU-Africa"></img>
                </div>
                <div className={styles.imgCont}  id={styles.imgCont5} onClick={()=>this.paymentMethod('M-PESA')}>
                   <img  className={styles.theImage} src={'./mpesa.png'} alt="NU-Africa"></img>
                   </div>
               </div>
               <p className={styles.payP}>Payment Method</p>
               <div className={styles.methCont}>
               <div className={styles.methSelector} onClick={()=>this.paymentType('One time')}>
                        <IoMdCheckmark style={{color:'#fff', fontSize:15}}/> 
                        </div>
               <p>{this.state.paymentMethod}</p>
               </div>
               <p className={styles.payP}>Payment Type</p>
               <div className={styles.typeDiv}>
               <div className={styles.typeCont}>
               <div className={styles.selectorDiv2} style={payStyle1} onClick={()=>this.paymentType('One time')}>
                        <IoMdCheckmark style={{color:'#fff', fontSize:15}}/> 
                        </div>
                        <p>One Time</p>
               </div>
               <div className={styles.typeCont}>
               <div className={styles.selectorDiv2} style={payStyle2} onClick={()=>this.paymentType('Recurring')}>
                        <IoMdCheckmark style={{color:'#fff', fontSize:15}}/> 
                        </div>
                        <p>Recurring</p>
               </div>  </div>
               {showRecurring}
               
               <div className={styles.contDiv}>
                <p className={styles.condP}>I want to donate for</p>
                <div className={styles.selectCont}>
                <select  className={styles.selectDiv} value={this.state.value} onChange={(event)=>this.handleExchangeChange(event)}>
                  <option  selected value="Education Support">Education Support</option>
                  <option value="Social Entrepreneurship">Social Entrepreneurship</option>
                  <option value="Sports For Development">Sports For Development</option>
                  <option value="Nurturing Talent">Nurturing Talent</option>
                  <option value="Gorvenance and Leadership">Gorvenance and Leadership</option>
                </select>
                </div>
                </div>
                <div className={styles.contDiv}>
                <p className={styles.condP}>Payment Type</p>
                <div className={styles.selectCont}>
                <select  className={styles.selectDiv} value={this.state.value} onChange={(event)=>this.handleCurrency(event)}>
                  <option  selected value="KES ">Kshs - Kenya Shillings</option>
                  <option value="$ ">USD - U.S Dollars</option>
                  <option value="£ ">GBP - British Pounds</option>
                </select>
                </div>
                </div>
                <div className={styles.howMuchDiv}>
                <p>How much do you want to donate?</p>
                <div className={styles.donDiv}>
                    {donationsList}
                </div>
                <input type='number' id={'title'} placeholder={'Enter Amount'} value={this.state.donationAmount} onChange={(event) => this.handlePriceChange(event)} onClick={()=>this.donat2()}/>
                </div>
                <h3 className={styles.donateCont}>DONATE NOW</h3>
                
               </div>
               </div>
            </div>
        )
    }
}

export default Donate
