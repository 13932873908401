
import React, { Component } from 'react'
import './App.css';
import NavBar from './components/Header/NavBar';
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';

import ShopNav from './components/HomeScreens/ShopNav';
import CartFinish from './components/HomeScreens/CartFinish';
import CartPay from './components/HomeScreens/CartPay';


import Sanaa from './components/HomeScreens/Sanaa';
import Michezo from './components/HomeScreens/Michezo';
import Mishono from './components/HomeScreens/Mishono';
import Miziki from './components/HomeScreens/Miziki';
import Filamu from './components/HomeScreens/Filamu';
import Power from './components/HomeScreens/Power';
import Shop from './components/HomeScreens/Shop2';
//import Shop from './components/HomeScreens/Shop';
import LogIn from './components/Registration/LogIn';
import Register from './components/Registration/Register';
import Categories from './components/HomeScreens/Categories';
import LogInRegister from './components/HomeScreens/LogInRegister';
import MyAccount from './components/HomeScreens/MyAccount';
import Cart from './components/HomeScreens/Cart';
import CheckOut from './components/HomeScreens/CheckOut';
import Search from './components/HomeScreens/Search';
import Search2 from './components/HomeScreens/Search2';
import Search3 from './components/HomeScreens/Search3';
import Causes from './components/Home/Causes';
import DepDetails from './components/HomeScreens/DepDetails';
import Donate from './components/causes/Donate';
import Donate2 from './components/causes/Donate2';
import SupportUs from './components/Home/SupportUs';
import Volunteer from './components/Home/Volunteer';
import VolForm from './components/Home/VolForm';
import Dashboard from './components/Dashboard/Dashboard';
import Sell from './components/Dashboard/Sell';

import Education from './components/causes/Education';
import Sports from './components/causes/Sports';
import Gorvenance from './components/causes/Gorvenance';
import Social from './components/causes/Social';
import Talent from './components/causes/Talent';
import AboutUs from './components/MoreScreens/AboutUs';
import HomeCauses from './components/Home/HomeCauses';
import Contacts from './components/MoreScreens/Contacts';
import Privacy from './components/MoreScreens/Privacy';//Categories
import Terms from './components/MoreScreens/Terms';
import ProductDetails from './components/Pages/ProductDetails';
import Department from './components/Pages/Department';
import WhoWeAre from './components/Pages/WhoWeAre';
import EditAccount from './components/HomeScreens/MyAccountScreens/EditAccount';
import MemberForm from './components/Home/MemberForm';
import Whatwedo from './components/Home/Whatwedo'; 
import Media from './components/Home/Media';
import { BrowserRouter, Route,Switch } from 'react-router-dom'




const Layout = ({ children }) => (
  <div>
    <NavBar />
      {children}
    <Footer />
  </div>
);
const Layout2 = ({ children }) => (
  <div>
    <ShopNav />
      {children}
    <Footer />
  </div>
);

class App extends Component {
  state={cookieStat:false}
  componentDidMount=()=>{
  
    const cookieStat = localStorage.getItem('cookieStatus'); 
    console.log('comp did mount',cookieStat)
    if (!cookieStat||cookieStat==='false'||cookieStat==='null') {
      console.log('cook stat 1',cookieStat)
      this.setState({cookieStat:true})
    }else if(cookieStat==='true'){
      console.log('cook stat 2',cookieStat)
      this.setState({cookieStat:false})
    }
  }
  acceptCookie=()=>{
    localStorage.setItem("cookieStatus", true);
    this.setState({cookieStat:false})
  }
  render() {
   // var theCookStatus=localStorage.getItem('cookieStatus');
    //console.log('comp did mount')
    return (
    <>
      {<BrowserRouter>
       <Switch>
       <Route exact path='/dashboard' component={Dashboard}/>
       //<Route exact path='/shop' component={Shop}/>
       <Route exact path='/categories' component={Categories}/>
       //<Route exact path='/productdetails' component={ProductDetails}/>
       <Route exact path='/loginregister' component={LogInRegister}/>
       <Route exact path='/myaccount' component={MyAccount}/>
       <Route exact path='/cart' component={Cart}/>
       <Route exact path='/checkout' component={CheckOut}/>
       <Route exact path='/cartfinish' component={CartFinish}/>
       <Route exact path='/editaccount' component={EditAccount}/>
       <Route exact path='/search' component={Search}/>
       <Route exact path='/search2' component={Search2}/>
       <Route exact path='/search3' component={Search3}/>
       <Route exact path='/cartpay' component={CartPay}/>
      <Layout>
        <Route exact path='/' component={Home}/>
        <Route exact path='/shop' component={Shop}/>
        <Route exact path='/productdetails' component={ProductDetails}/>
        <Route exact path='/sanaa' component={Sanaa}/>
        <Route exact path='/michezo' component={Michezo}/>
        <Route exact path='/mishono' component={Mishono}/>
        <Route exact path='/miziki' component={Miziki}/>
        <Route exact path='/filamu' component={Filamu}/>
        <Route exact path='/power' component={Power}/>
        <Route exact path='/logIn' component={LogIn}/>
        <Route exact path='/register' component={Register}/>
        <Route exact path='/aboutUs' component={AboutUs}/>
        <Route exact path='/contacts' component={Contacts}/>
        <Route exact path='/privacy' component={Privacy}/>
        <Route exact path='/terms' component={Terms}/>
        <Route exact path='/causes' component={Causes}/>
        <Route exact path='/department' component={Department}/>
        <Route exact path='/homecauses' component={HomeCauses}/>

        <Route exact path='/depdetails' component={DepDetails}/>

        <Route exact path='/education' component={Education}/>
        <Route exact path='/sports' component={Sports}/>
        <Route exact path='/gorvenance' component={Gorvenance}/>
        <Route exact path='/social' component={Social}/>
        <Route exact path='/donate' component={Donate}/>
        <Route exact path='/donate2' component={Donate2}/>
        <Route exact path='/talent' component={Talent}/>
        
        <Route exact path='/supportus' component={SupportUs}/>
        <Route exact path='/volunteer' component={Volunteer}/>
        <Route exact path='/volform' component={VolForm}/>
        <Route exact path='/memberForm' component={MemberForm}/>

        <Route exact path='/whatwedo' component={Whatwedo}/>
        <Route exact path='/media' component={Media}/>
        <Route exact path='/sell' component={Sell}/>
        <Route exact path='/whoweare' component={WhoWeAre}/>

        

        
      </Layout>
      
      </Switch>
     
      </BrowserRouter>}
      {this.state.cookieStat?<div  className="appCont1" style={{width:'100%',height:'auto',position:'fixed',bottom:0,zIndex:9999}}>
        <div style={{flex:1}}>
          <p id="appP1" >Your choice regarding cookies on this site</p>
          <p id="appP2">We have placed cookies on your device to help make this website better. You can use this tool to change your cookie settings.</p>
          <p id="appP3">Otherwise, we'll assume you're OK to continue.</p>
        </div>
        <div  id="appCont2">
         <p id="accP" onClick={()=>this.acceptCookie()}>ACCEPT</p>
         <p id="manP"  onClick={()=>this.acceptCookie()}>MANAGE</p>
        </div>
      </div>:null}
   </>
    )
  }
}

export default App
