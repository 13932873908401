import React, { Component } from 'react'
import HomeReel from './Reel'
import HomeWelcome from './HomeWelcome'
import HomeCauses from './HomeCauses'
import GetInvolved from './GetInvolved'
import HomeDonors from './HomeDonors'
import Members from '../HomeScreens/SanaaMembers';
import Committee from './Committee'
import style from './Home.module.scss';
import axios from 'axios'
var ourPartners=[
  {id:1,image:'./photo21.jpeg'},
  {id:2,image:'./photo23.jpg'},
  {id:3,image:'./photo24.png'},]
 class Home extends Component {
   state={
     users:[]
   }
   componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const ourPartner=ourPartners.map((item)=>{
      return(
        <div className={style.listCont} key={item.id}>
        <img className={style.listImg} src={item.image}/>
        </div>
      )
    })
    return (
      <div className={style.container}>
         <HomeReel/>
         <HomeWelcome/>
         <HomeCauses/>
         {/*<div className={style.GDiv}>
                  <h3 className={style.GH3}><span className={style.servP2A} style={{color:'#fff'}}>SUPPORT OUR </span><span className={style.servP2B}>CAUSE</span></h3>
                  <h3 className={style.GH4}>Join our community and help empower women around the globe</h3>
                  <p className={style.donateP} onClick={()=>this.notify('Coming Soon')}>DONATE</p>
                </div>*/}
                <div className={style.theCont}>
                <p className={style.servP1}>Our Team</p>
                <p className={style.servP2}><span className={style.servP2A}>Meet Our</span><span className={style.servP2B}> Team Members</span></p>
                <Committee/>
                </div>
        {/*<div className={style.teamDiv}>
        <Members/>
        </div>*/}
        <GetInvolved/>
          <div className={style.GDiv4}>
                  <div className={style.causesDetCont}>
                  <h3>Our Partners</h3>
                  <p>Companies which have believed in our vision</p>
                  </div>
                  <div className={style.partDiv}>
                   {ourPartner}
                  </div>
                </div>

      </div>
    )
  }
}
export default Home

/*const photos=[
    {id:1, title:'NUAfrica SANAA NA MICHORO ', to:"/sanaa", title2:'Africa Home of Arts', subTitle:'Uniting Africa through Arts and Performing Arts', image:'https://images.pexels.com/photos/374009/pexels-photo-374009.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'},
    {id:2, title:'NUAfrica MICHEZO', to:"/michezo", title2:'All About Sports',  subTitle:'Enpowering Africa through Sports', image:'https://images.pexels.com/photos/3886055/pexels-photo-3886055.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'},
    {id:3, title:'NUAfrica MISHONO',to:"/mishono",  title2:'Fashion African Way',  subTitle:'Uniting Africa through Fashion', image:'https://images.pexels.com/photos/1038043/pexels-photo-1038043.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260'},
    {id:4, title:'NUAfrica MIZIKI',to:"/miziki", title2:'Care About People',  subTitle:'Enpowering Africa through Music', image:'https://images.pexels.com/photos/2479312/pexels-photo-2479312.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'},
    {id:5, title:'NUAfrica FILAMU NA PICHA', to:"/filamu", title2:'Care About People',  subTitle:'Uniting Africa through Film & Photography', image:'https://images.pexels.com/photos/1903308/pexels-photo-1903308.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'},
    {id:6, title:'NUAfrica POWER', to:"/power",  title2:'Care About People',  subTitle:'Enpowering Africans through Governance and Leadership', image:'https://images.pexels.com/photos/5083399/pexels-photo-5083399.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'}]*/