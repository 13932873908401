import React, { Component } from 'react'
import style from './LogInRegister.module.scss';
import ShopNav from './ShopNav'
import Footer from '../Footer/Footer'

class LogInRegister extends Component {
    render() {
        return (
            <div>
                <ShopNav/>
            <div className={style.container}>
               <div className={style.container1}>
               <div className={style.imgDiv}>
                   <img  className={style.theImage} src={'/log-in.png'} alt="NU-Africa"></img>
                  </div>
               <div className={style.contLogIn}>
                <h3>REGISTER</h3>
                <h6>Enter your Registration details below</h6>
                <div className={style.contLogInDet}>
                    <p>Username</p>
                    <input placeholder='Enter Username' className={style.input}/>
                    <p>E-mail</p>
                    <input placeholder='Enter E-mail' className={style.input}/>
                    <p>Password</p>
                    <input placeholder='Enter Password' className={style.input}/>
                    <p>Repeat Password</p>
                    <input placeholder='Repeat Password' className={style.input}/>
                    <div className={style.divLogIn}>
                     <h3>REGISTER</h3>
                    </div>
                    <h6>Already have an Account?<break style={{color:'#FF7C59'}}>  Log In</break></h6>
                </div>
                </div>


                <div className={style.contLogInReg}>
                <h3>LOG IN</h3>
                <h6>Enter your Log In details below</h6>
                <div className={style.contLogInDet}>
                    <p>E-mail</p>
                    <input placeholder='Enter E-mail' className={style.input}/>
                    <p>Password</p>
                    <input placeholder='Enter Password' className={style.input}/>
                     <h5>Forgot Password?</h5>
                    <div className={style.divLogIn}>
                     <h3>LOG IN</h3>
                    </div>
                    <h6>Don't have an Account?<break className={style.reg}>  Register</break></h6>
                </div>
                </div>
              </div>
              </div>
              <Footer/>
            </div>
        )
    }
}

export default LogInRegister
