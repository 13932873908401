import React, { Component } from 'react'
import style from './SavedItems.module.scss';
import firebase from 'firebase';
import { FaStar} from "react-icons/fa";
import {NavLink} from 'react-router-dom'

class SavedItems extends Component {
    state={
        catItems:[]}
        componentDidMount(){
            this.fetchCategories()
    
        }
    fetchCategories=async()=>{
        try{
       var theRef = firebase.database().ref('/dirItems').limitToFirst(5);
          var array1=[]
          var array2=[]
         await theRef.once('value', (snapshot) => {
     
            snapshot.forEach((data) => {
                const image = data.val().image
                const price = data.val().price
                const title = data.val().title
                const productId= data.val().productId
                const no = data.key
                array1={
                  image: image, price: price, no:no,title:title,productId:productId
                }
                 
                array2.push(array1)
            })
           
           this.setState({
            catItems:array2,
           })
          })
          console.log('items', this.state.catItems)
        } catch (error) {
          
        }
      }
    render() {
        let theItems=this.state.catItems
        const itemsList=theItems.map(item=>{
            return(
                <NavLink to='/productdetails'  className={style.myItemsListCont}><div key={item.no} >
                <div className={style.imageDiv}>
                 <img  className={style.theImage} src={item.image} alt="NU-Africa"></img>
                 </div>
                 <div className={style.clothListDetCont}>
                  <p>{item.title}</p>
                  <h4>{item.price}</h4>
                  <div className={style.addToCart}>
                      <h6>ADD TO CART</h6>
                  </div>
                  <div className={style.ratingDiv}>
                  <FaStar className={style.ratingIcon}/><FaStar className={style.ratingIcon}/><FaStar className={style.ratingIcon}/><FaStar className={style.ratingIcon}/>
                  <FaStar className={style.ratingIcon}/>
                  </div>
            </div>
                </div></NavLink>
            )
        })
        return (
            <div className={style.container}>
               <h3 className={style.headingTitle}>Saved Items</h3>
                <div className={style.container2}>
                {itemsList}
                </div>
            </div>
        )
    }
}

export default SavedItems
