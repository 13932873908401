import React, { Component } from 'react'
import style from './EditAccount.module.scss';
import ShopNav from '../ShopNav'
import Footer from '../../Footer/Footer'

 class EditAccount extends Component {
    render() {
        return (
            <div className={style.mainContainer}>
 <ShopNav/>
            <div className={style.container}>
               
                <div className={style.containerA}>
                    <h2>Edit Account</h2>
                    <div className={style.container1}>
                    <div className={style.container2}>
                        <p>Username</p>
                        <input placeholder='John Doe' className={style.input}/>
                        </div>
                        <div className={style.container2B}>
                        <p>Primary Phone No</p>
                        <input placeholder='Phone No' className={style.input}/>
                        </div>
                    </div>
                    <div className={style.container1B}>
                    <div className={style.container2}>
                        <p>Additional Phone No</p>
                        <input placeholder='Phone No' className={style.input}/>
                        </div>
                        <div className={style.container2B}>
                        <p>Email</p>
                        <input placeholder='johndoe@gmail.com' className={style.input}/>
                        </div>
                    </div>
                    <div className={style.container1C}>
                    <p>Address</p>
                    <input placeholder='Address' className={style.adrressInput}/>
                    </div>
                    <div className={style.container1B}>
                    <div className={style.container2}>
                        <p>County</p>
                        <input placeholder='Nairobi' className={style.input} type='text'/>
                        </div>
                        <div className={style.container2B}>
                        <p>Area</p>
                        <input placeholder='Roysambu Kasarani' className={style.input}/>
                        </div>
                    </div>
                    <div className={style.saveCont}>
                        <h4>SAVE</h4>
                    </div>
                </div>
               
            </div> <Footer/>
            </div>
        )
    }
}

export default EditAccount
