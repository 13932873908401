import React, { Component } from 'react'
import style from './ProductsDetails.module.scss';
import { RiShareForward2Line,RiAddLine,RiSubtractLine } from "react-icons/ri";
import { MdKeyboardArrowRight,MdClose,MdEmail } from "react-icons/md"
import { FiHeart } from "react-icons/fi"
import { FaFacebookF,FaTwitter,FaInstagram,FaBars,FaRegHeart,FaHeart,FaStar,FaPhoneAlt } from 'react-icons/fa';
import firebase from 'firebase';
import { BsChatSquareQuote } from "react-icons/bs";
import {IoLogoWhatsapp} from 'react-icons/io';
//import ReactImageMagnify from 'react-image-magnify';
import ReactImageZoom from 'react-image-zoom';


class ProductDetails extends Component {
    state={
        showModal:false,
        arrImg:[],
        theItem:[],
        theDesc:[],
        openReview:false,
        openDescription:true,
        reviewsArray:[],
        clicks:0,
        sellerRating:5,
        openSnack:false,
        primaryImage:'',
        images:[
           {id:1, image:"https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/tshirts7.jpg?alt=media&token=1db12981-602a-4ce3-9c94-f78168b1108e"},
           {id:2, image:"https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/africanprintshirts.jpg?alt=media&token=acb351fe-cc8a-483d-9c09-3d3d42872eb4"},
           {id:3, image:"https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/african%20dresses.png?alt=media&token=b0fdfe69-f36d-4683-82c3-90870a597f14"},
           {id:4, image:"https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/african%20hoodie.png?alt=media&token=e4f2738d-9ceb-4171-9e02-24549cfca3d3"},
           {id:5, image:"https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/african%20shoes.png?alt=media&token=229019a3-9838-4f96-a294-39c07c1eac1b"},
           {id:6, image:"https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/tshirts7.jpg?alt=media&token=1db12981-602a-4ce3-9c94-f78168b1108e"},
           {id:7, image:"https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/africanprintshirts.jpg?alt=media&token=acb351fe-cc8a-483d-9c09-3d3d42872eb4"},
           {id:8, image:"https://firebasestorage.googleapis.com/v0/b/nuapfoundation.appspot.com/o/african%20dresses.png?alt=media&token=b0fdfe69-f36d-4683-82c3-90870a597f14"},
           ],
           features:[
            {id:1, details:"Wooven Fabric"},
            {id:2, details:"Can be customized to individual preference"},
            {id:3, details:"Unisex and also available for kids"},
            {id:4, details:"Mixed with cotton for extra comfort"},
            {id:5, details:"Hand and machine washable"},
            {id:6, details:"Buy 3 and get a 25% discount"},
            ]
   }
   showPrimaryImage=(image)=>{
    this.setState({
        primaryImage:image
      
     })
  }
  componentDidMount(){
    console.log('niko hapaaaaaaaaaaaaaaa the stateeeeeeee',this.props.location.state) 
    this.fetchProduct()
   window.scrollTo(0, 0)
  }
  fetchProduct=async()=>{
      var imgArr=[],i=0
    try {
      const theRef = firebase.database().ref('/theProducts/'+this.props.location.state);
     await theRef.once('value', (snapshot) => {
          var theImages=snapshot.val().images
          var theDesc=snapshot.val().desc
          theDesc=theDesc.split('+')
          this.setState({primaryImage:snapshot.val().image,theItem:snapshot.val(),theDesc:theDesc})

         console.log('snapshot 500',theImages,'5555555',this.state.theItem)
         for (const key in theImages) {
            console.log('ruuuuuuuuuuuuuuuuuu',theImages[key]); 
            imgArr.push(theImages[key])
            console.log('the arrrrrrrr',imgArr)
            i++
            if(i===imgArr.length){
                this.setState({arrImg:imgArr})
                console.log('the arrrrrrrr imggg',this.state.arrImg)
            }
        }
         
         /*this.setState({theItem:snapshot.val().images})
         (snapshot.val().images).forEach((data) => {
             console.log('data55555555',data)
         })*/
     
     })
    } catch (error) {
      
    }
    }
  openDescription(){
  this.setState({
      openDescription:true,
      openReview:false
  })
  }
  openReview(){
    this.setState({
        openDescription:false,
        openReview:true
    }) 
    this.fetchReviews()   
}
fetchReviews = async () => {
    const sellerId='78htHe5WzphBCdKglGiWcSU8mfE2'
    var messagesArray=[]
    var messagesArray2 = []
    console.log('sellerId', sellerId)

    try {
        var reviewDb = firebase.database().ref('/Reviews/SellerReviews/'+sellerId);
        await reviewDb.once('value', (snapshot) => {
            snapshot.forEach((data) => {
              const senderId = data.val().senderId
              const message = data.val().message
              const rate = data.val().rate
              const rating = data.val().rating
              const time = data.val().time

              messagesArray={
                  message: message, rate:rate, senderId: senderId, rating:rating, time:time
              }
              messagesArray2.push(messagesArray)
              })
              this.setState({
                reviewsArray:messagesArray2
             })
           
            })
        console.log('ratingsReview', this.state.theMessagesArray)
    } catch (error) {

    }
}
  incrementItem = () => {
    this.setState({ clicks: this.state.clicks + 1 });
  }
  decreaseItem = () => {
      if(this.state.clicks===0){
          return
      }
    this.setState({ clicks: this.state.clicks - 1 });
  }
  openSnack=(condition)=>{
     this.setState({
        openSnack:condition
     })
  }
  stopShift=(event)=>{
    event.stopPropagation();
    event.preventDefault()
   }
   showModal=(result)=>{
    this.setState({showModal:result})
}
doNothing=(event)=>{
    event.preventDefault()
    event.stopPropagation()
 }
    render() {
        let reviewList=''
        let theSellerRating=''
        let descStyle=''
        let revStyle=''
        const starsToShow=<FaStar className={style.ratingIcon}/>
      


        const imagesList=this.state.arrImg.map((item)=>{
            return(
             <div className={style.otherProductImages}>
               <img onClick={()=>{this.showPrimaryImage(item)}} className={style.theSecImages} src={item} alt="Nu-Africa"></img>
             </div>
       
            )
         })
         const descList=this.state.theDesc?.map((item)=>{
            return(
            <p className={style.featLi}>{item}</p>
       
            )
         })
         const imagesList2=this.state.arrImg.map((item)=>{
            return(
             <div className={style.otherProductImages2}>
               <img onClick={()=>{this.showPrimaryImage(item)}} className={style.theSecImages} src={item} alt="Nu-Africa"></img>
             </div>
       
            )
         })
         const featuresList=this.state.features.map((item)=>{
            return(
            <p className={style.featLi}>{item.details}</p>
       
            )
         })



const theRatingReview=this.state.reviewsArray
if(theRatingReview && theRatingReview.length){
    console.log('something to show')
     reviewList=theRatingReview.map((item)=>{
        if(item.rating===5){theSellerRating=<div className={style.ratingInfoDiv}>
        {starsToShow}{starsToShow}{starsToShow}{starsToShow}{starsToShow}</div>}
    if(item.rating===4){theSellerRating=<div className={style.ratingInfoDiv}>
        {starsToShow}{starsToShow}{starsToShow}{starsToShow}</div>}
    if(item.rating===3){theSellerRating=<div className={style.ratingInfoDiv}>
        {starsToShow}{starsToShow}{starsToShow}</div>}
    if(item.rating===2){theSellerRating=<div className={style.ratingInfoDiv}>
       {starsToShow}{starsToShow}</div>}
    if(item.rating===1){theSellerRating=<div className={style.ratingInfoDiv}>
      {starsToShow}</div>}
         var timeInMillis=item.time
        var theTime = new Date(timeInMillis);
        return(
            <div className={style.reviewListCont}>
            {theSellerRating}
            <h4>{item.rate}</h4>
           <p>{item.message}</p>
           <p>{theTime.toLocaleDateString()}</p>
           </div>
        )
       
    })
}else{
    console.log('nothing to show')
    reviewList=<div className={style.noReviewCont}>
        <BsChatSquareQuote className={style.noReviewIcon}/>
       <p>This product has no reviews yet.</p>
    </div>
}
 if(this.state.openDescription===true){
    descStyle={
        borderColor:'#000',
        borderStyle:'solid',
        borderWidth:1,
       // marginLeft:10,
        marginBottom:10,
        backgroundColor:'#000',
        color:'#fff'
    }
    revStyle={
        borderColor:'#000',
        borderStyle:'solid',
        borderWidth:1,
        marginBottom:10
    }
 }else{
    descStyle={
      
        borderColor:'#000',
        borderStyle:'solid',
        borderWidth:1,
        marginBottom:10
    }
    revStyle={
        borderColor:'#000',
        borderStyle:'solid',
        borderWidth:1,
        marginBottom:10,
        backgroundColor:'#000',
        color:'#fff'
    }
 }
 const props = {height: 400,scale:0.3, zoomStyle:'opacity:1;background-color: white; border-color: whitesmoke; border-style: solid; border-width: 1px;  height: 450px;  width: 400px;  margin-top: -50px;', img:this.state.primaryImage,
 zoomLensStyle:'height:50px; width:50px;background-color: white;opacity:0.3;'};
        return (
            <div>
                 <div className={style.statusBar}></div>
            <div className={style.container}>
               
                {/*<div className={style.containerHead}>
                 <p className={style.backP}>BACK</p>
                 <p className={style.backP2}> {' |  PRODUCT DETAILS'}</p>
                </div>*/}
               
                <div className={style.containerA}>
                <div className={style.container1}>
                    <div className={style.container1A}>
                    <div className={style.thePrimImageDiv} onClick={()=>this.openSnack(true)}>
                  <img  className={style.thePrimImage} src={this.state.primaryImage} alt="Nu-africa"></img>
                   </div>
                    </div>
                    <div className={style.myImgCont}>
                    <div className={style.container1B1}>
                         {imagesList}
                        </div>
                    {/*imagesList2*/}
                    {/*<div className={style.container1B1}>
                       {imagesList}
                   </div>*/}
                   </div>

                 </div>
                <div className={style.container2}>
                   <p className={style.prdTitle}>{this.state.theItem['title']}</p>
                   <p className={style.prdCat}>{this.state.theItem['cat']}</p>
                   <h3 className={style.prdPrice}>KES {this.state.theItem['price']}</h3>
                   <p className={style.prdDet}>{this.state.theItem['desc']?.replace(/\+/g,'. ').replace(/\../g,'. ')}</p>
                
                  {/*<div  className={style.theCartDiv}>
                <div className={style.addQuantityDiv}>
                <RiSubtractLine className={style.subtractIcon} onClick={()=>this.decreaseItem()}/>
                <p>{this.state.clicks}</p>
                <RiAddLine className={style.addIcon} onClick={()=>this.incrementItem()}/>
                </div>
                  <div className={style.prdCart}>
                       <p>ADD TO CART</p>
                   </div>
                   <div className={style.cartHeart}>
                      <FaRegHeart/>
                   </div>
                  </div>
                 
                   <div className={style.prdWish}>
                   <h3>Delivery and Return</h3>
                   <h3>Ask a Question</h3>
                   </div>*/}
                   <div className={style.prdShare}>
                       <p><span>Vendor:</span>  NU-Africa</p>
                       <p><span>Availabilty:</span> In Stock</p>
                       <p><span>Categories:</span> {this.state.theItem['cat']}</p>
                       {/*<p><span>Product Id:</span> P01254LK7</p>*/}
                   </div>
                    <div className={style.orderDiv} onClick={()=>this.setState({showModal:true})}>ORDER NOW</div>
                   </div>

                  {/* <div className={style.contMagnify}>

                   </div>*/}
                </div>
                <div className={style.containerB}>
                    <div className={style.containerBHead}>
                    <h4 style={descStyle} onClick={()=>this.openDescription()}>Description</h4>
                    {/*<h4 style={revStyle} onClick={()=>this.openReview()}>Reviews</h4>*/}
                    </div>
                    <div>
                        {descList}
                    {/*this.state.openDescription?featuresList:null*/}
                    </div>
                  <div className={style.rev2}>
                  {this.state.openReview?reviewList:null}
                  </div>
                 
                </div>
                {this.state.openSnack?
               <div className={style.zoomedImgSnackDiv} onClick={()=>this.openSnack(false)}>
                  <div className={style.snackDiv1}  onClick={(event)=>this.stopShift(event)}>
                   <div className={style.snackDiv1A}>
                     <h2>Product Images</h2>
                     <MdClose className={style.snackClose}  onClick={()=>this.openSnack(false)}/>
                   </div>
                   <div className={style.snackDiv1B}>
                   <img  className={style.thePrimImage2} src={this.state.primaryImage} alt="Nu-africa"></img>
                   </div>
                   <div className={style.snackDiv1C}>
                   <div className={style.myImgCont2}>
                    <div className={style.container1B12}>
                        
                         {imagesList2}
                        </div>
                        
                   </div>
                   </div>
                  </div>
               </div>:null}
              
            </div>
            {this.state.showModal?<div className={style.modal} onClick={()=>this.showModal(false)}>
                <div className={style.modalCont} onClick={(event)=>this.doNothing(event)}>
                <h5>Purchase/Enquire</h5>
                <p className={style.book}>For purchasing or making enquiries of this product please reach us through the below contacts</p>
                <div className={style.contDiv}><FaPhoneAlt className='ulicon' size={14} color='#000'/><p>+254 705 059 188</p></div>
                <div className={style.contDiv}><MdEmail className='ulicon' size={16} color='#000'/><p>info@nuafricapamoja.org</p></div>
                <div className={style.contDiv}><IoLogoWhatsapp className='ulicon' size={16} color='#000'/><p>+254 705 059 188</p></div>
                <p id={style.close} onClick={()=>this.showModal(false)}>Close</p>
                </div>
                </div>:null}
            </div>
        )
    }
}

export default ProductDetails
