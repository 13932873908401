import React, { Component } from 'react';
import  './ShopNav.scss';
import {NavLink,withRouter} from 'react-router-dom';
import { MdHelpOutline } from "react-icons/md";
import { FaSearch,FaGripLinesVertical,FaWpforms,FaRegSave} from "react-icons/fa";
import { MdKeyboardArrowDown,MdAddShoppingCart,MdPersonOutline,MdHistory} from "react-icons/md";
//import { IoPersonOutline } from "react-icons/im";
import { saveItemAccount } from '../../redux/postActions';
import { connect } from 'react-redux';

 class ShopNav extends Component {
    constructor(props) {
        super(props)
        this.state={
            queryName:'',
            openAccDiv:false
        }
      }
 
    onScroll = () => { 
        const isTop=window.scrollY>100;
        const nav=document.getElementById('shopNav');
        if(isTop){
            nav.classList.add("shopScrolled");
        }else{
            nav.classList.remove("shopScrolled");
        }
    }
     componentDidMount(){
        window.addEventListener("scroll", this.onScroll, false);
         
    }
    componentWillUnmount(){
        window.removeEventListener("scroll", this.onScroll, false);
       // window.removeAllListeners("scroll");
    }
    searchItem=()=>{
     if (this.state.queryName<2) {
         alert('For better Optimization Search Query Must atleast be 2 characters')
         return
     }
    this.props.history.push({
        pathname: '/search2',
        state: { detail: this.state.queryName }
    })
    }
    searchChange=(event)=>{
      this.setState({
        queryName:event.target.value
      })
       }
       openTheAcDiv=async()=>{
        await this.setState({
            openAccDiv:true
        })
       }
       closeTheAcDiv=async()=>{
        await this.setState({
            openAccDiv:false
        })
       }
       stopShift=(event)=>{
        //event.stopPropagation();
       // event.preventDefault()
       }
       sendToAccount=async(theLink)=>{
        this.props.saveItemAccount(theLink)
        this.props.history.push('/myaccount')

       }
    render() {
        console.log('theLink', this.props.history)
        let cartItem=''
        let accDiv=''
        if (this.props.savedCartItems.length<1) {
            cartItem=null
            //console.log('nothing on cart')
        }else{
            //console.log('no', this.props.savedCartItems.length)
            cartItem= <div  className='cartDiv2'>
            <h2>{this.props.savedCartItems.length}</h2>
            </div>
        }
        return (
           
            <div className='shopNav' id='shopNav'>
                 <div className='shopNav2'>
         <NavLink to='/' className='NavLogo'><img src='./NU.png'></img></NavLink>
        {/*<h3 style={{color:'#fff', fontSize:30}}>NUSOKO</h3>*/}
         <div className='shopDiv'>
          <div className='shopSearchCont1'>
          <div className='shopSearch2'>
            <FaSearch style={{color:'#000', fontSize:20, marginRight:20, marginLeft:20}}/>
            <input placeholder='Type item to search' className='input'type='text' onChange={(event)=>this.searchChange(event)}/>
            </div>
            <div className='shopSearch3' onClick={()=>this.searchItem()}>
             <h3>SEARCH</h3>
            </div>
          </div>
          <div className='loginCont'>
              <NavLink to='/loginregister' className='NavLogIn'><p>Log In</p></NavLink>
              <FaGripLinesVertical className='signindivider' color='#FF7C59'/>
              <NavLink to='/myaccount' className='NavLogIn'><p>Register</p></NavLink>  
          </div>
          <div className='rightCont'>
          <div to='/myaccount' className='helpDiv' onClick={()=>{this.openTheAcDiv()}}>
             {/* <MdHelpOutline style={{color:'#fff', fontSize:30,marginRight:10}}/>*/}
             <p>Account</p>
             <MdKeyboardArrowDown className='accountIc'/>
              </div>
              <NavLink to='/search3' className='searchDiv'>
             <FaSearch style={{color:'#fff', fontSize:20, marginRight:30}}/>
              </NavLink>
             { <div className='acProfile'>

               </div>}
              <NavLink to={{pathname:'/cart', state:this.props.savedCartItems}} className='cartDiv'>
                
                  {cartItem}
              <MdAddShoppingCart className='cartIc'/>
              <p>Cart</p>
              </NavLink>
          </div>
          </div>

            </div>
            <div className='shopSearchCont2A'>
            <div className='shopSearchCont2'>
            <div className='shopSearch2B'>
            <input placeholder='Type item to search' className='input2'type='text'/>
            </div>
            <NavLink to='/search' className='shopSearch3B'>
            <FaSearch style={{color:'#fff', fontSize:20, marginRight:20, marginLeft:20}}/>
             </NavLink>
            
          </div> 
          {/*<div className='divCart2'>
          {cartItem}
          <MdAddShoppingCart className='cart2' /></div>*/}
              </div> 
              <div className='newLogIn'>
              </div>
              {this.state.openAccDiv?
              <div className='accountCont' onClick={()=>this.closeTheAcDiv()}>
              <div className='accountDiv' onClick={(event)=>this.stopShift(event)}>
              <div  className='accMenu' onClick={()=>this.sendToAccount('profileInfo')}>
              <MdPersonOutline className='accIcons1'/>
              <p>Account</p>
              </div>
              <div  className='accMenu' onClick={()=>this.sendToAccount('delivered')}>
              <FaWpforms className='accIcons'/>
              <p>Orders</p>
              </div>
              <div className='accMenu' onClick={()=>this.sendToAccount('savedItems')}>
              <FaRegSave className='accIcons'/>
              <p>Saved Items</p>
              </div>
              <div  className='accMenu'  onClick={()=>this.sendToAccount('recentlyViewed')}>
              <MdHistory className='accIcons'/>
              <p>Recently Viewed</p>
              </div>
              <div to={{pathname:'/myaccount', state:'logOut'}} className='logOutNav'>
              <p>LOGOUT</p>
              </div>
              </div>
              </div>:null}
             
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        savedCartItems: state.savedCartItems,
     
    }
  }
  const mapDispatchToProps = dispatch => {
    return {
        saveItemAccount:(savedAcountItem)=>dispatch(saveItemAccount(savedAcountItem)),
     
    }
  }
  export default withRouter (connect(mapStateToProps,mapDispatchToProps) (ShopNav)) 

//export default withRouter (ShopNav)
