import React, { Component } from 'react'
import style from './Contacts.module.scss';
import SnackBar  from './../Helper/SnackBar'
import {MdLocationOn,MdPhoneInTalk,MdEmail} from "react-icons/md";
import { FaFacebookF,FaTwitter,FaInstagram} from "react-icons/fa";
import firebase from 'firebase';
//let place="https://goo.gl/maps/77YweoXp6ZZ1Y4Ph8"
class Contact extends Component {
    state={
        name:'',
        phone:'',
        message:'',
        showSnackBar:false,
        value:'Others',
        snackMeso:'All fields must be filled'
    }
    componentDidMount(){
        window.scrollTo(0, 0)
    }
    inputChange(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({[event.target.id]:event.target.value});
      }
      sendMessage=async()=>{
        var time = Date.now();
        const theRef = firebase.database().ref('/notifications/contacts');
        //const theRef2 = firebase.database().ref('/notifications/reachus');
          console.log('name', this.state.name)
          console.log('phone', this.state.phone)
          console.log('message', this.state.message)
          if (this.state.name===''||this.state.phone===''||this.state.message===''||this.state.value==='') {
            this.setState({ showSnackBar: true,snackMeso:'All fields must be filled' })
            await setTimeout(
               () => this.setState({ showSnackBar: false }), 
               3000
             )
            return
          }else  if (this.state.name!=''&&this.state.phone!=''&&this.state.message!=''&&this.state.value!='') {
            this.setState({ showSnackBar: true,snackMeso:'Message uploaded successfully'})
            var theMeso={
              name:this.state.name,
              phone:this.state.phone,
              message:this.state.message,
              topic:this.state.value,
              subject:'Get in touch',
              time:time,
              status:'send'
          }
            theRef.push(theMeso)
            await setTimeout(
               () => this.setState({ showSnackBar: false }), 
               3000
             )
          }
      }
      handleExchangeChange(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({value: event.target.value});
      }
    render() {
        return (
            <div>
              <div className={style.statusBar}></div>
            <div className={style.container}>
                <div className={style.container2}>
                <div className={style.cont1}>
                    <div  className={style.cont1A}>
                    <div  className={style.contAdress}>
                        <MdLocationOn className={style.adIcon}/>
                    </div>
                    <h4>ADDRESS</h4>
                    <p>4th Floor Kirethi Apartments</p>
                    <p>Kahawa Wendani, Nairobi, Kenya</p>
                    </div>
                    <div  className={style.cont1B}>
                    <div  className={style.contAdress}>
                        <MdEmail className={style.adIcon}/>
                    </div>
                    <h4>EMAIL</h4>
                    <p>info@nuafricapamoja.org</p>
                    <p>support@nuafricapamoja.org</p>
                    </div>
                    <div  className={style.cont1C}>
                    <div  className={style.contAdress}>
                        <MdPhoneInTalk className={style.adIcon}/>
                    </div>
                    <h4>CALL US</h4>
                    <p>+254 794 301186</p>
                    
                    </div>
                    <div  className={style.cont1D}>
                    <h4>CONTACT US</h4>
                    <p>Contact us for help</p>
                    <p>on social media</p>
                    <div>
                     <FaFacebookF  className={style.sIcon}/>
                     <FaTwitter className={style.sIcon}/>
                     <FaInstagram className={style.sIcon}/>
                    </div>
                    </div>
                   
                
                </div>
                <div className={style.cont2}>
                  <h3>GET IN TOUCH</h3>
                  <p>Enter your details below</p>
                  <p style={{fontSize:12,fontWeight:700}}>Choose topic</p>
                  <div className={style.selectCont}>
            <select  className={style.selectDiv} value={this.state.value} onChange={(event)=>this.handleExchangeChange(event)}>
              <option selected value="Others">Others</option>
              <option value="Enroll School">Enroll School</option>
              <option value="Enroll Team">Enroll Team</option>
              <option value="Enroll Talent">Enroll Talent</option>
              <option value="Enroll Team">Enroll for Training</option>
              <option value="Become a Partner">Become a Partner</option>
              <option value="Cheques &amp; Bank Transfers">Cheques &amp; Bank Transfers</option>
            </select>
            
            </div>
                  <input type='text' id='name' placeholder={'Name*'} className={style.name} onChange={(event)=>this.inputChange(event)}></input>
                  <input type='number' id='phone' placeholder={'Phone*'} className={style.phone} onChange={(event)=>this.inputChange(event)}></input>
                  <textarea className={style.messageInput} type='text' id='message' placeholder= {'Message*'} className={style.message} onChange={(event)=>this.inputChange(event)}></textarea>
                  <div className={style.sendMesoDiv} onClick={()=>this.sendMessage()}>
                      <h4>Send Message</h4>
                  </div>
                </div>
                </div>
            </div>
            {this.state.showSnackBar?<SnackBar message={this.state.snackMeso}/>:null}
            </div>
        )
    }
}

export default Contact
//https://github.com/BekaTechnologies/nuafrica.git

/*A seasoned and creative software engineer, Beka has beem expanding what is possible with code in order to make life
life more efficient. He has been in the software engineering field for over three years, delivering new
and innovative solutions for a myrad of dofferent companies ranging from tiny startups to major corporations with
massive information technology and programming needs.*/
