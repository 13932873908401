import React, { Component } from 'react'
import style from './Categories.module.scss';//
import {MdKeyboardArrowLeft,MdArrowDropDown, MdSortByAlpha} from "react-icons/md";
import firebase from 'firebase';
import { GiTransportationRings,GiLoincloth,GiGemNecklace,GiBelt,GiRolledCloth,GiClothJar} from "react-icons/gi"
import { SiIconjar } from "react-icons/si";
import { FaStar,FaPaintBrush,FaRegHeart,FaHotjar,FaShoppingBag,FaBolt,FaFutbol,FaPaintRoller} from "react-icons/fa";
import {NavLink} from 'react-router-dom'
import ShopNav from './ShopNav'
import Footer from '../Footer/Footer2'
import { saveCartItems } from '../../redux/postActions';
import { connect } from 'react-redux';
let nomare = <MdKeyboardArrowLeft/>
class Categories extends Component {
    constructor(props) {
        super(props)
            this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    state = {
        catItems:[],
        catItems2:[],
        catArray:[],
        sorting:"New Arrivals",
        showSortDiv:false,
        sortCategory:false,
        refName:'',
        number:null,
        maxValue:false,
        theValue:null,
        mobileWindow: window.matchMedia("(max-width: 384px)").matches
    }
    componentDidMount(){
        this.fetchCategories()
        this.fetchCats()
        window.addEventListener('resize', this.updateWindowDimensions);
        const handler2 = e => this.setState({mobileWindow: e.matches});
        window.matchMedia("(max-width: 384px)").addListener(handler2);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }
    fetchCategories=async()=>{
        var theRef=''
        try {
            if (this.state.sortCategory===false) {
                theRef = firebase.database().ref('/dirItems').limitToFirst(5);
            }else{
                theRef = firebase.database().ref("/Categories").child(this.state.refName).limitToFirst(5);
            }
         
          var array1=[]
          var array2=[]
         await theRef.once('value', (snapshot) => {
     
            snapshot.forEach((data) => {
                const image = data.val().image
                const price = data.val().price
                const priceNo = Number(price.replace(/[^0-9]/g, ''));
                const title = data.val().title
                const productId= data.val().productId
                const no = data.key
                //console.log('ndani ya cat')
                array1={
                  image: image, price: price, priceNo:priceNo, no:no,title:title,productId:productId
                }
                 
                array2.push(array1)
                //const res = array2.filter(({ productId>2 }) => checked).map(({ value }) => value);
            })
           
           this.setState({
            catItems:array2,
            catItems2:array2
           })
          })
          console.log('items', this.state.catItems)
        } catch (error) {
          
        }
      }
      fetchCats=async()=>{
        var catArray=[]
        var catArray2=[]
     try {
       const theRef = firebase.database().ref('/theCategories');
       await theRef.once('value', (snapshot) => {
         snapshot.forEach((data) => {
             const id = data.key
             const name = data.val().name
             const image = data.val().image
             const color = data.val().color
             const name2=name.replaceAll('-', ' ')
             console.log('theKey',id)
             catArray={
               name: name, name2:name2, image:image, id:id, color:color
             }
             catArray2.push(catArray)
         })
     })
       this.setState({
         catArray:catArray2
        })
      // console.log('title1', title1)
     } catch (error) {
       
     }
   }
   sortBy=(name)=>{
    
      var newArray=''
       if (name==='New Arrivals') {
        //newArray=this.state.catItems2
        this.setState({
            catItems:this.state.catItems2
           })
       }else if(name==='Price - High to Low'){
        var sortedArray = this.state.catItems.slice(0);
        sortedArray.sort(function(a,b) {
        return b.priceNo - a.priceNo;
       });
       //newArray=this.state.sortedArray
       this.setState({
        catItems:sortedArray
       })
       }else if(name==='Price - Low to High'){
        var sortedArray = this.state.catItems.slice(0);
        sortedArray.sort(function(a,b) {
        return a.priceNo - b.priceNo;
       });
       //newArray=this.state.sortedArray
       this.setState({
        catItems:sortedArray
       })
       }
       this.setState({
        sorting:name,
        showSortDiv:false,
        //catItems:newArray
       })
   }
   showSortDiv=()=>{
    this.setState({
        showSortDiv:true
    })
}
sortCat=async(name)=>{
    //alert(name)
   await this.setState({
        refName:name,
        sortCategory:true
    })
    this.fetchCategories()
}
filterByPrice=async()=>{
    
    if (this.state.number>0) {
        this.setState({
            maxValue:true,
            theValue:this.state.number
        })
        //alert(this.state.number)
    }
}
handleChange=(event)=>{
    const re = /^[0-9\b]+$/;
      if (event.target.value === '' || re.test(event.target.value)) {
         this.setState({number: event.target.value})
      }

  }
  resetPrice=()=>{
    this.setState({
        maxValue:false,
        theValue:null,
        number:'',
    })
  }
  addCartItem=async(event, id)=>{
    event.preventDefault();
    event.stopPropagation();
      //alert(id)
  var cartItems=this.props.savedCartItems;
  await cartItems.push(id)
  cartItems=[...new Set(cartItems)];
  this.props.saveCartItems(cartItems)
  }
    render() {
        let iconToShow=''
        console.log('cart item ids ', this.props.savedCartItems)
        let theItem=this.state.catItems
        let showTheSortDiv=''
        if (this.state.showSortDiv===true) {
            showTheSortDiv=<div className={style.sortDiv2}>
            <h6 onClick={()=>this.sortBy('New Arrivals')}>New Arrivals</h6>
            <h6 onClick={()=>this.sortBy('Price - High to Low')}>Price: High to Low</h6>
            <h6 onClick={()=>this.sortBy('Price - Low to High')}>Price: Low to High</h6>   
         </div>
        }else{
            showTheSortDiv=null
        }
        const itemsList=theItem.map(item=>{
            if (this.state.maxValue===true) {
                if(item.priceNo<=this.state.theValue){
                    return(
                        <NavLink to='/productdetails'  className={style.myItemsListCont}><div key={item.no} >
                        <div className={style.imageDiv}>
                         <img  className={style.theImage} src={item.image} alt="NU-Africa"></img>
                         </div>
                         <div className={style.clothListDetCont}>
                          <p>{item.title}</p>
                          <h4>{item.price}</h4>
                          <div className={style.addToCart}>
                              <h6>ADD TO CART</h6>
                          </div>
                          <div className={style.ratingDiv}>
                          <FaStar className={style.ratingIcon}/><FaStar className={style.ratingIcon}/><FaStar className={style.ratingIcon}/><FaStar className={style.ratingIcon}/>
                          <FaStar className={style.ratingIcon}/>
                          </div>
                    </div>
                        </div></NavLink>
                    )  
                }
            }else{
                return(
                    <NavLink to='/productdetails'  className={style.myItemsListCont}><div key={item.no} >
                    <div className={style.imageDiv}>
                     <img  className={style.theImage} src={item.image} alt="NU-Africa"></img>
                     </div>
                     <div className={style.clothListDetCont}>
                      <p>{item.title}</p>
                      <h4>{item.price}</h4>
                      <div className={style.addToCart} onClick={(event)=>this.addCartItem(event,item.productId)}>
                          <h6>ADD TO CART</h6>
                      </div>
                      <div className={style.ratingDiv}>
                      <FaStar className={style.ratingIcon}/><FaStar className={style.ratingIcon}/><FaStar className={style.ratingIcon}/><FaStar className={style.ratingIcon}/>
                      <FaStar className={style.ratingIcon}/>
                      </div>
                </div>
                    </div></NavLink>
                )
            }
            /*return(
                <NavLink to='/productdetails'  className={style.myItemsListCont}><div key={item.no} >
                <div className={style.imageDiv}>
                 <img  className={style.theImage} src={item.image} alt="NU-Africa"></img>
                 </div>
                 <div className={style.clothListDetCont}>
                  <p>{item.title}</p>
                  <h4>{item.price}</h4>
                  <div className={style.addToCart}>
                      <h6>ADD TO CART</h6>
                  </div>
                  <div className={style.ratingDiv}>
                  <FaStar className={style.ratingIcon}/><FaStar className={style.ratingIcon}/><FaStar className={style.ratingIcon}/><FaStar className={style.ratingIcon}/>
                  <FaStar className={style.ratingIcon}/>
                  </div>
            </div>
                </div></NavLink>
            )*/
        })
        const categoryList=this.state.catArray.map(item=>{
            let catStyle=[]
            let divStyle=[]
            if (this.state.mobileWindow) {
                catStyle={
                  color:'#fff',
                  marginTop:0,
                  fontSize:20
                }
                divStyle={
                    backgroundColor:item.color,
                    borderRadius:50
                }
                if (item.name==='Clothing'){iconToShow=<GiLoincloth style={catStyle}/>}
                if (item.name==='Bags'){iconToShow=<FaShoppingBag style={catStyle}/>}
                if (item.name==='Accessories'){iconToShow=<GiBelt style={catStyle}/>}
                if (item.name==='Art-and-Paintings'){iconToShow=<FaPaintRoller style={catStyle}/>}
                if (item.name==='Kikoys'){iconToShow=<GiRolledCloth style={catStyle}/>}
                if (item.name==='Jewellery'){iconToShow=<GiGemNecklace style={catStyle}/>}
                if (item.name==='Curio-Collection'){iconToShow=<SiIconjar style={catStyle}/>}
                if (item.name==='Sports'){iconToShow=<FaFutbol style={catStyle}/>}
                //alert('at 384 px')
            }else{
                catStyle={
                    backgroundColor:'#fff'
                  }
                  divStyle={
                    //backgroundColor:'#fff'
                }
                  iconToShow= <img  className={style.theImage2}  src={item.image} alt="NU-Africa"></img>
            }
            return(
                <NavLink to='/categories'  className={style.catListCont}>
                     <div className={style.imageDiv2} style={divStyle}>
                     {iconToShow}
                  </div>
                    <p>{item.name2}</p>
                </NavLink>
            )
        })
       
        return (
            <div>
           <ShopNav/>
            <div className={style.mainCont} >
               
                <div className={style.headCont}>
                 <p className={style.backP}>BACK</p>
                 <p className={style.backP2}> {' |  CATEGORY'}</p>
                   {/* <div className={style.headCont1}>
                    <MdKeyboardArrowLeft className={style.backArrow}/>
                    <p className={style.shopName}>BACK</p>
                    </div>

                
                 <MdKeyboardArrowLeft className={style.backArrow}/>
                 <p className={style.shopName2}>CATEGORY DETAILS</p>*/}
                </div>
               
                <div className={style.container} >
                <div className={style.container1}>
                 <div  className={style.contRight1}>
                 <div  className={style.contRightNav}>
                 <p>CATEGORY</p>
                 </div>
                  <div  className={style.contRightItems}>
                      {categoryList}
                  </div>
                 </div>
                 <p className={style.catTitle2}>PRODUCTS</p>
                 <div  className={style.contRight2}>
                    <h4>FILTER BY PRICE</h4>
                    <p>MIN PRICE</p>
                    <div className={style.minPriceCont}>
                      <p  className={style.inputPriceP}>Min Price - 10 Ksh</p>
                    </div>
                    <p>MAX PRICE</p>
                    <div className={style.maxPriceCont}>
                    
                    <input className={style.inputPrice}

              type="text"
              placeholder='Enter Max Price'
              value={this.state.number} 

              onChange={this.handleChange} />
                    </div>
                    <div className={style.filterDiv}>
                    <div className={style.filterPrice} onClick={()=>this.filterByPrice()}>
                      <h3>APPLY FILTER</h3>
                    </div>
                    <div className={style.resetPrice} onClick={()=>this.resetPrice()}>
                      <h3>RESET</h3>
                    </div>
                    </div>
                 </div>
                 <div  className={style.contRight3}>
                 <h4>SORT</h4>
                 <div className={style.sortDiv}>
                     <div className={style.sortDiv1} onClick={()=>this.showSortDiv()}>
                     <h6>Sort By: {this.state.sorting}</h6>
                     <MdArrowDropDown className={style.sortIcon}/>
                     </div>
                     {showTheSortDiv}
                     
                 </div>
                 </div>
                </div>
                <div className={style.container2}>
                {itemsList}
                </div>
                 </div>
            </div>
            <Footer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        savedCartItems: state.savedCartItems,
     
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        saveCartItems:(savedCartItems)=>dispatch(saveCartItems(savedCartItems)),
     
    }
  }
  
  export default connect(mapStateToProps,mapDispatchToProps) (Categories) 
