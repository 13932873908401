import React, { Component } from 'react'
import styles from './Michezo.module.scss';
import Members from './SanaaMembers';
import firebase from 'firebase';
import {NavLink} from 'react-router-dom'

 class Michezo extends Component {
     state={
         infoArray:[]
     }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.fetchInfo()
      }
      fetchInfo=async()=>{
        var array=[]
        var array2=[]
      try {
       const theRef = firebase.database().ref('/nuapdep/michezo');
       await theRef.once('value', (snapshot) => {
         snapshot.forEach((data) => {
             const info = data.val()
             console.log('info',info)
             array={
                info:info
             }
             array2.push(array)
         })
     })
       this.setState({
        infoArray:array2
        })
       console.log('array2', array2)
     } catch (error) {
       
     }
   }
    render() {
        const infoList=this.state.infoArray.map(item=>{
             return(
                <div className={styles.infoList}>
                <p>{item.info}</p>
                </div>
                    
             )
         })
        return (
            <div className={styles.container}>
                <div className={styles.container1}>
                    <div className={styles.container1Det}>
                <p className={styles.title}>NU AFRICA MICHEZO</p>
                <p className={styles.details}>Now uniting Africa through Sports development</p>
                <div style={{display:"flex"}}>
                <div className={styles.contDonate}>
                      <NavLink to='/supportus' className={styles.contDonateP} ><p>How to Help</p></NavLink>
                      
                       </div>
                       <div className={styles.contactUs}>
                       <NavLink to='/contacts' className={styles.contDonateP} ><p>Contact Us</p></NavLink>
                       </div>
                </div>
                
                       </div>
                </div>
                <div className={styles.container2}>
                 <h2>ABOUT NUAFRICA MICHEZO</h2>
                  {infoList}
                 <h3>Football doesn't build character; it reveals character... Marv levy </h3>
                 <h3>Never give up, never give in, and when the upper hand is ours, may we have the ability to handle the win with the dignity that we absorbed the loss …. Doug Williams</h3>
                </div>
                <div className={styles.container3}>
                <p id={styles.jI}>Join the organization’s growing team and inspire the youth we work with.</p>
                <NavLink to='/supportus' style={{textDecoration:'none'}}><p id={styles.gI}>GET INVOLVED</p></NavLink>
                </div>
                
            </div>
        )
    }
}

export default Michezo
