import React, { Component } from 'react'
import style from './FundWelcome.module.scss';
import {NavLink} from 'react-router-dom'

 class FundWelcome extends Component {
    render() {
        return (
            <div className={style.welcomeCont}>
                 <div className={style.mainContainer}>
                <div className={style.leftSideBar}>
                <img  src={'./photo31.jpg'}/>
                </div>
                <div className={style.rightSideBar}>
                    <div className={style.container}>
                 <h1>PARTNER WITH US</h1>
                 <p className={style.enpowerP2}>There are lots of ways that you can help NUAfrica Pamoja Foundation- directly and indirectly. One way is to make a donation, which will go straight to helping our field operations around Africa. You can be a volunteer or a partner within our organisation on various programmes and projects. Other ways to support our work include holding awareness and fundraising events in your communities,keeping up to date on our activities in our calendar, and joining in an online dialogue on social networking sites about our work and those we help.</p>
                 <p>Volunteers are welcome to register and work with us in any of these departments, depending on their area of expertise.</p>
                 <NavLink to='/contacts' className={style.donateDiv}><p>Be a Partner</p></NavLink>
                 
                </div>
                </div>
                </div>
            </div>
        )
    }
}

export default FundWelcome
